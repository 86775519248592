/**
 * @file paddlehub 通用图片 demo 折叠面板，自动展开
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useMemo, useState} from 'react';
import {CollapseProps} from 'antd/es/collapse';
import {Collapse} from 'antd';

const PCollapse: React.FC<CollapseProps> = props => {
    const [activeKeyState, setActiveKeyState] = useState<CollapseProps['activeKey']>(() => {
        if (props.defaultActiveKey !== undefined) {
            return props.defaultActiveKey;
        }
        return [];
    });

    const [theKeys, findTheKeys] = useMemo(() => {
        const theKeys: (string | number)[] = [];
        React.Children.map(props.children, (child) => {
            if (React.isValidElement(child) && child.key !== null) {
                theKeys.push(child.key);
            }
        });
        // 搜索用的 theKey 列表，当需要查找的目标是 theKey 最后一个元素的时候，在 findTheKeys 中能马上找到第一个元素
        const findTheKeys = [...theKeys, ...theKeys];
        return [theKeys, findTheKeys];
    }, [props.children]);

    const onChange = useCallback<(key: string | string[]) => void>((keys) => {
        // 如果用户折叠所有panel，则展开下一个panel让界面不会空着
        setActiveKeyState((oldKeys) => {
            if (Array.isArray(oldKeys) && Array.isArray(keys)) {
                if (keys.length === 0 && oldKeys.length > 0) {
                    const theKeysIndex = findTheKeys.indexOf(oldKeys[0]);
                    if (theKeysIndex > -1) {
                        return findTheKeys[theKeysIndex + 1];
                    }
                    else {
                        return [theKeys[0]];
                    }
                }
            }
            if (!Array.isArray(oldKeys) && !Array.isArray(keys)) {
                if (oldKeys !== undefined && keys === undefined) {
                    const theKeysIndex = findTheKeys.indexOf(oldKeys);
                    if (theKeysIndex > -1) {
                        return findTheKeys[theKeysIndex + 1];
                    }
                    else {
                        return theKeys[0];
                    }
                }
                return keys;
            }
            return keys;
        });
    }, [setActiveKeyState, theKeys, findTheKeys]);

    return (
        <Collapse
            activeKey={activeKeyState}
            onChange={onChange}
            {...props}
        />
    );
};

export default PCollapse;
