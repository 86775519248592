/**
 * @file paddle hub 搜索框表单
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC, useCallback, useMemo } from 'react';
import { AutoComplete, Col, Form, FormProps, Input, InputProps, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { ISearchFormProps } from './types';
import Sorter from '../../../../components/sorter/Sorter';

const SearchForm: FC<ISearchFormProps> = (props) => {
    const {
        form,
        onFinish,
        onNameSearch,
        searchOptions
    } = props;



    const handleOnChangeDebounce = useMemo(() => {
        return onFinish
            ? debounce(onFinish, 500)
            : () => { };
    }, [onFinish]);


    const handleOnValuesChange = useCallback<Exclude<FormProps['onValuesChange'], undefined>>(
        (changeValues) => {
            // 如果是用户输入搜索信息，不触发列表刷新
            if (changeValues && changeValues.name === undefined) {
                let newChangeValue = changeValues;
                // 如果排序发生变化，则取消其他排序选项。即排序选项同时只能选中一个
                if (changeValues.hot || changeValues.utime || changeValues.recom) {
                    newChangeValue = {
                        hot: undefined,
                        utime: undefined,
                        recom: undefined,
                        ...changeValues
                    }
                };
                handleOnChangeDebounce(newChangeValue);
            }
        },
        [handleOnChangeDebounce]
    )

    const handleOnPressEnter = useCallback<Exclude<InputProps['onPressEnter'], undefined>>(
        (event) => {
            const value = (event.target as HTMLInputElement).value;
            if (onFinish) {
                onFinish({ name: value });
            }
        },
        [onFinish]
    );

    const handleOnSelect = useCallback((value: string) => {
        onFinish && onFinish({ name: value });
    }, [onFinish]);


    const handleOnBlur = useCallback(() => {
        onFinish && onFinish({});
    }, [onFinish]);

    return (
        <Form
            form={form}
            onValuesChange={handleOnValuesChange}
        >
            <Row className="paddle-hub-page-search-input-wrap">
                <Col span={12}>
                    <Form.Item
                        noStyle
                        name="name"
                    >
                        <AutoComplete
                            className="paddle-hub-page-search-auto-complete"
                            onSearch={onNameSearch}
                            options={searchOptions}
                            onSelect={handleOnSelect}
                            onBlur={handleOnBlur}
                        >
                            <Input
                                className="paddle-hub-page-search-input"
                                placeholder="搜索PaddleHub的所有模型"
                                allowClear
                                onPressEnter={handleOnPressEnter}
                                suffix={<SearchOutlined />}
                            />
                        </AutoComplete>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item dependencies={['utime']} noStyle>
                        {({ resetFields, setFieldsValue }) => (
                            <Form.Item
                                name="hot"
                                noStyle
                                initialValue="desc"
                            >
                                <Sorter
                                    className="paddle-hub-sorter"
                                    options={[
                                        { label: '热门排序', value: '' },
                                        { label: '热门排序', value: 'asc' },
                                        { label: '热门排序', value: 'desc' }
                                    ]}
                                    onChange={() => {
                                        resetFields(['utime']);
                                        setFieldsValue({ utime: '' });
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                    <Form.Item dependencies={['hot']} noStyle>
                        {({ resetFields, setFieldsValue }) => (
                            <Form.Item name="utime" noStyle>
                                <Sorter
                                    className="paddle-hub-sorter"
                                    options={[
                                        { label: '最新上线', value: '' },
                                        { label: '最新上线', value: 'asc' },
                                        { label: '最新上线', value: 'desc' }
                                    ]}
                                    onChange={() => {
                                        resetFields(['hot']);
                                        setFieldsValue({ hot: '' });
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                    {/* <Form.Item dependencies={['hot', 'utime']} noStyle>
                        {({ resetFields }) => (
                            <Form.Item name="recom" noStyle>
                                <Sorter
                                    className="paddle-hub-sorter"
                                    options={[
                                        { label: '推荐指数', value: '' },
                                        { label: '推荐指数', value: 'asc' },
                                        { label: '推荐指数', value: 'desc' }
                                    ]}
                                    onChange={() => resetFields(['hot', 'utime'])}
                                />
                            </Form.Item>
                        )}
                    </Form.Item> */}
                </Col>
            </Row>
        </Form>
    );
};

export default SearchForm;
