/**
 * @file 请求方法基础，适用于paddle文档的搜索文档接口
 * @author FengGuang(fengguang01@baidu.com)
 */

import {message} from 'antd';
import request from 'superagent';


const baseRequest = async (superagentObject: Promise<any>): Promise<request.Response> => {
    let res: request.Response;
    try {
        res = await superagentObject;
    }
    catch (err: any) {
        (window as any).DEBUG && message.error(err.message);
        throw err;
    }

    return res;
};

export default baseRequest;
