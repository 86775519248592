
/**
 * @file paddle hub 首页模型列表的hook
 * @author FengGuang(fengguang01@baidu.com)
 */
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { fetchGetMenuList2 } from '../../../../api/pages/paddleHubApi';
import { IPaddleHubMenuGroup, IPaddleHubMenuItem } from './types';
import { useLatest } from 'react-use';

const usePaddleHubMenu = (initMenuList: IPaddleHubMenuGroup[] = []) => {
    const [form] = useForm();


    // 初始化设置默认值
    const formRef = useLatest(form);
    useMemo(() => {
        const valueStr = new URLSearchParams(window.location.search.slice(1)).get('value') || '{}';
        const value = (() => {
            try {
                return JSON.parse(valueStr);
            }
            catch (err: any) {
                // ignore
            }
            return {};
        })();
        formRef.current.setFieldsValue(value);
    }, [formRef]);


    const [activeKey, setActiveKey] = useState<string[]>([]);
    const onActiveChange = useCallback((key: string | number | string[] | number[]) => {
        if (typeof (key) === 'number' || typeof (key) === 'string') {
            setActiveKey(['' + key]);
        }
        else if (Array.isArray(key)) {
            setActiveKey(key.map((i: string | number) => '' + i));
        }
    }, [setActiveKey]);

    const [menuList, setMenuList] = useState<IPaddleHubMenuGroup[]>(initMenuList);

    const getMenuList = useCallback(async () => {
        const res = await fetchGetMenuList2()
            .catch(() => {
                // ignore
            });

        if (res) {
            const data = res.body.data || [];
            const mL = Array.isArray(data) ? data : [];
            const mList: IPaddleHubMenuGroup[] = [];

            const getMLChild = (list: any[]): IPaddleHubMenuItem[] | undefined => {
                return Array.isArray(list)
                    ? list.map((i) => {
                        const c = getMLChild(i.item);
                        const childNum: number = typeof (i.doc_count) === 'number' ? i.doc_count : c?.length;
                        return {
                            title: i.chn,
                            label: `${i.chn}${childNum !== undefined ? `(${childNum})` : ''}`,
                            key: `${i.en}`,
                            value: `${i.en}`,
                            children: c
                        };
                    })
                    : undefined;
            };

            // 屏蔽某些菜单
            const filterItem = new Set(['scheme', 'transfer']);

            mL.forEach((item) => {
                if (filterItem.has(item.en)) {
                    return;
                }
                const theItem: IPaddleHubMenuGroup = {
                    title: item.chn,
                    value: item.en,
                    type: undefined,
                    list: getMLChild(item.item) || [],
                };
                const tmp = new Set(['0', '1']);
                theItem.type = (theItem.list.length === 2 || theItem.list.length === 1) && theItem.list.every(i => tmp.has(i.value))
                    ? 'switch'
                    : undefined;
                mList.push(theItem);
            });
            setMenuList(mList);

            if (mList.findIndex(i => i.value === 'transfer') > -1) {
                form.setFieldsValue({ transfer: true });
            }

            return mList;
        }
        return;
    }, [setMenuList, form]);

    const clearAll = useCallback(() => {
        form.resetFields();
        form.submit();
    }, [form]);

    return {
        form,
        menuList,
        setMenuList,
        activeKey,
        onActiveChange,

        clearAll,
        getMenuList
    }
}

export default usePaddleHubMenu;
