/**
 * @file paddlehub demo 词法分析结果展示
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useMemo, useState} from 'react';
import classNames from 'classnames';

import nameTableData from '../../lexTags.json';
import nameTable2Data from '../../lexTags2.json';
import {IAnyObject, IWordTypeItem} from '../../types';


const nameTable: IAnyObject = nameTableData;
const nameTable2: IAnyObject = nameTable2Data;

interface IWordResultRsItem {
    key: string;
    tag: string;
    word: string;
}

interface ITextResultProps {
    wordResultRs: IWordResultRsItem[];
    selectedWordResultRs: string;
    onWordResultRsChange: (key: string) => any;
    wordTypeList: IWordTypeItem[];
    selectedWordType: string;
    wordResultFilterByWordTypeList: IWordResultRsItem[];
    onWordTypeChange: (key: string) => any;
    onWordDetailPrevClick: (key: any) => any;
    onWordDetailNextClick: (key: any) => any;
}

const TextResult: React.FC<ITextResultProps> = props => {
    const {
        wordResultRs,
        wordTypeList,
        selectedWordType,
        selectedWordResultRs,
        wordResultFilterByWordTypeList,
        onWordResultRsChange,
        onWordTypeChange,
        onWordDetailPrevClick,
        onWordDetailNextClick
    } = props;

    const [hoverTipKey, setHoverTipKey] = useState<string>('');

    const selectedWordResultItem = useMemo(() => {
        return wordResultRs.find(item => item.key === selectedWordResultRs);
    }, [wordResultRs, selectedWordResultRs]);

    return (
        <div className="lexical-result-cover">
            <div className="lexical-result-left">
                <div className="lexical-result-left-title">分词词性</div>
                <div className="lexical-result-tips-wrap">
                    {wordResultRs.map(item => (
                        <div
                            key={item.key}
                            className={classNames(
                                'lexical-result-tips',
                                {
                                    'lexical-result-tips-show': item.key === selectedWordResultRs && item.tag !== selectedWordType,
                                    'lexical-result-person-type': item.tag === selectedWordType && item.tag === 'PER',
                                    'lexical-result-place-type': item.tag === selectedWordType && item.tag === 'LOC',
                                    'lexical-result-time-type': item.tag === selectedWordType && item.tag === 'TIME',
                                    'lexical-result-mechanism-name': item.tag === selectedWordType && item.tag === 'ORG'
                                }
                            )}
                            onMouseEnter={() => setHoverTipKey(item.key)}
                            onMouseLeave={() => setHoverTipKey('')}
                            onClick={() => onWordResultRsChange(item.key)}
                        >
                            <div className="lexical-result-tips-mes">{item.word}</div>
                            <div
                                className={classNames(
                                    'lexical-result-tips-des',
                                    {
                                        'lexical-result-tips-des-show':
                                            item.key === selectedWordResultRs || item.tag === selectedWordType
                                    }
                                )}
                            >
                                {item.tag}
                            </div>
                            {hoverTipKey === item.key
                            && (
                                <div className="lexical-result-tips-bubble">
                                    {nameTable[item.tag]}
                                    <span className="lexical-result-tips-triangle" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="lexical-result-right">
                <div>
                    <div className="mes-analyse">词汇详情</div>
                    <div className="mes-des-cover">
                        {wordResultFilterByWordTypeList.length > 1
                        && (
                            <div
                                className="mes-des-arrow-left"
                                onClick={onWordDetailPrevClick}
                            />
                        )}
                        {wordResultFilterByWordTypeList.length > 1
                        && (
                            <div
                                className="mes-des-arrow-right"
                                onClick={onWordDetailNextClick}
                            />
                        )}
                        <div className="mes-type">
                            词汇：
                            <span className="mes-type-in">
                                {selectedWordResultItem?.word}
                            </span>
                        </div>
                        <div className="mes-type mes-divide">
                            词性：
                            <span className="mes-type-in">
                                {nameTable[selectedWordResultItem?.tag ?? '']}
                            </span>
                        </div>
                        <div className="mes-type">
                            实体识别：
                            <span className="mes-type-in">
                                {nameTable2[selectedWordResultItem?.tag ?? '']}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mes-select-cover">
                    <div className="mes-select-title">专名识别</div>
                    <div className="mes-select-tips-wrap">
                        {wordTypeList.map(item => (
                            <div
                                key={item.key}
                                className={classNames(
                                    'mes-select-tips',
                                    {'lexical-result-self-style': item.key === selectedWordType}
                                )}
                                onClick={() => onWordTypeChange(item.key)}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TextResult;
