/**
 * @file paddlehub demo ai写作面板 对联
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback} from 'react';
import {IShowPaneProps} from './types';
import ShowPane from './ShowPane';

const CoupletShowPane: React.FC<IShowPaneProps> = (props) => {
    const validate = useCallback<Exclude<IShowPaneProps['validate'], undefined>>((inputValue) => {
        if (!inputValue) {
            return ' ';
        }
        if (inputValue.length > 20 || (/[\\pP‘’“”]/).test(inputValue) || (/[a-zA-Z0-9]/).test(inputValue)) {
            return '格式错误，输入不能带有标点符号以及非中文字符，上限20个字';
        }
    }, []);

    const getDataTransition = useCallback((data: any, value: string) => {
        const theR: string[] = [];
        if (typeof (data) === 'string') {
            theR.push(data);
        }
        else if (data && typeof (data.text) === 'string') {
            theR.push(data.text);
        }
        else {
            const r: any[] = Array.isArray(data) ? data : [];
            r.forEach((subR) => {
                if (Array.isArray(subR)) {
                    subR.forEach((subR2) => {
                        theR.push('' + typeof (subR2) === 'string' ? subR2 : (subR2?.text ?? ''));
                    });
                }
                else {
                    theR.push('' + typeof (subR) === 'string' ? subR : (subR?.text ?? ''));
                }
            });
        }
        return theR.map(i => `${value}\n${i}`);
    }, []);

    return (
        <ShowPane
            model="ernie_gen_couplet"
            inputPlaceholder="输入不能带有标点符号，上限20个字"
            validate={validate}
            resultTransition={(result) => {
                const r = result?.split(/\n/) ?? [];
                return (
                    <React.Fragment>
                        <p>上联：{r[0]}</p>
                        <p>下联：{r[1]}</p>
                    </React.Fragment>
                );
            }}
            getDataTransition={getDataTransition}
            {...props}
        />
    );
};

export default CoupletShowPane;
