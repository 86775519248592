/**
 * @file icon卡片组件
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Row} from 'antd';
import IconCard from './IconCard';
import {IIconCardItem} from '../../types';

interface IProps {
    cardList?: IIconCardItem[];
}

const IconCardGroup: React.FC<IProps> = props => {
    const cardList = props.cardList || [];
    if (!cardList || cardList.length === 0) {
        return null;
    }

    return (
        <Row justify="space-between" className="paddle-icon-card-wrap">
            {cardList.map(item => (
                <IconCard
                    key={`${item.title},${item.text}`}
                    iconUrl={item.iconUrl}
                    title={item.title}
                    text={item.text}
                />
            ))}
        </Row>
    );
};

export default IconCardGroup;
