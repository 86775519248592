 // @ts-nocheck

/**
 * @file 多选 cascader
 * antd 4.15.0 还没实现多选 cascader，但是已经在计划中。
 * 此处先使用 cascader 和 select 组合成多选 cascader。
 * 如果 antd 的多选 cascader 发布了，就替换掉
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Cascader, Checkbox, Select, TreeSelect } from 'antd';
import { CascaderValueType } from 'antd/lib/cascader';

import { useOptionsMap, usePropsOrState, useFormatOptions, useFlatOptions } from './util';
import { MultiCascaderOptionType, MultiCascaderProps } from './types';
import { useFormatVal } from './formatVal';


const {
    SHOW_ALL,
    SHOW_CHILD,
    SHOW_PARENT
} = TreeSelect;



const MultiCascader: FC<MultiCascaderProps> = (props) => {
    const {
        value: propsValue,
        options: propsOptions,
        onChange: propsOnChange,
        children,
        showCheckedStrategy
    } = props;

    // 转换格式的 option
    const options = useFormatOptions(propsOptions);

    // 铺平的 option
    const flatOptions = useFlatOptions(options);

    // option 的 map，方便根据 value 快速查找 option
    const optionsMap = useOptionsMap(options);

    // 格式化 value 形成正确的结构，展示为树形结构
    const {
        value,
        valueForTreeSet,
        halfValueSet,
        onChange,
        onCheckChange
    } = useFormatVal(propsValue, optionsMap, propsOnChange, showCheckedStrategy);


    const optionsWithCheckbox = useMemo<MultiCascaderOptionType[]>(() => {
        const optionLabelAddCheckbox = (options: MultiCascaderOptionType[], parent?: MultiCascaderOptionType) => {
            return options.map((item) => {
                const theLabel = item.label ?? item.value;
                let theItem: MultiCascaderOptionType = {
                    ...item
                };
                if (typeof (theLabel) === 'string') {
                    theItem.label = (
                        <Checkbox
                            style={{ width: '100%' }}
                            checked={theItem.value ? valueForTreeSet.has(theItem.value) : false}
                            indeterminate={theItem.value ? halfValueSet.has(theItem.value) : false}
                            onChange={(e) => onCheckChange(theItem.value, e.target.checked)}
                        >
                            {theLabel}
                        </Checkbox>
                    );
                }
                if (Array.isArray(theItem.children)) {
                    theItem.children = optionLabelAddCheckbox(theItem.children, theItem);
                }
                return theItem;
            });
        };

        return optionLabelAddCheckbox(options);
    }, [options, valueForTreeSet, halfValueSet, onCheckChange]);


    // 阻止窗口关闭；设置Cascader的value让它能维持展开子项的状态
    const [openValue, setOpenValue] = useState<CascaderValueType>([]);

    const isOnChangeRef = useRef(false);
    const stopVisibleChange = useCallback<NonNullable<typeof props.onChange>>((value) => {
        setOpenValue(value);
        isOnChangeRef.current = true;
        setTimeout(() => {
            isOnChangeRef.current = false;
        }, 0);
    }, [setOpenValue, isOnChangeRef])


    const [popupVisible, setPopupVisible] = usePropsOrState<boolean>(
        false,
        props.popupVisible,
    );
    const onPopupVisibleChange = props.onPopupVisibleChange;


    const handleOnPopupVisibleChange = useCallback((visible: boolean) => {
        if (!isOnChangeRef.current || visible) {
            onPopupVisibleChange ? onPopupVisibleChange(visible) : setPopupVisible(visible);
        }
    }, [setPopupVisible, onPopupVisibleChange]);


    return (
        <Cascader
            {...props}
            value={openValue}
            options={optionsWithCheckbox}
            onChange={stopVisibleChange}
            changeOnSelect
            popupVisible={popupVisible}
            onPopupVisibleChange={handleOnPopupVisibleChange}
        >
            {(() => {
                const searchChild = (
                    <Select
                        mode="multiple"
                        className={props.className}
                        value={value}
                        open={false}
                        bordered={props.bordered}
                        showArrow={props.showArrow}
                        showSearch={!!props.showSearch}
                        optionLabelProp={props.optionLabelProp}
                        options={flatOptions as any[]}
                        onChange={onChange}
                    />
                );
                if (typeof (children) === 'function') {
                    return children(searchChild);
                }
                if (React.isValidElement(children)) {
                    return children;
                }
                return searchChild;
            })()}
        </Cascader>
    );
};

export default MultiCascader;

export {
    SHOW_ALL,
    SHOW_CHILD,
    SHOW_PARENT
};
