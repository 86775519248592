/**
 * @file paddlehub demo 口罩识别Hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useMemo, useState} from 'react';
import {fetchGetMaskDetectResult} from './maskDetectDemoApi';
import pictureCompress, {getImgBase64} from '../../../../utils/picture-compressor';
import {IMaskDetectDemoProps} from './types';

const useMaskDetectHooks = (props?: IMaskDetectDemoProps) => {
    const [resultImg, setResultImg] = useState<string>('');
    const [resultJson, setResultJson] = useState<string>('');

    // img 可能是文件、url、base64
    const getMaskDetectResult = useCallback((img: File | string) => {
        (async () => {
            let fileString;
            if (typeof (img) === 'string') {
                fileString = img;
                
                
            }
            else if (img.size > 2 * 1024 * 1024) {
                const minImgRes = await pictureCompress({
                    img,
                    height: 524,
                    width: 840
                });
                fileString = minImgRes.img;
            }
            else {
                fileString = await getImgBase64(img);
            }
            if (!fileString) {
                return;
            }
            setResultImg(fileString);
            setResultJson('');
            const imgBase64type = (fileString.match(/data:[^;]*;base64,/))?.[0] ?? '';
            const isBase64 = !!fileString.match(/data:[^;]*;base64,/);
            const uploadBase64 = fileString.substr(imgBase64type.length);
            let res;
            try {
                res = await fetchGetMaskDetectResult({
                    image: isBase64 ? uploadBase64 : undefined,
                    image_url: isBase64 ? undefined : fileString
                });
            }
            catch (err: any) {
                // ignore
            }
            if (res) {
                const resImg = res.body.base64 || '';
                if (resImg) {
                    setResultImg(`${imgBase64type || 'data:image/jpeg;base64,'}${resImg}`);
                }
                const resString = JSON.stringify(
                    res.body,
                    (key, value) => {
                        if (key === 'base64') {
                            const v = '' + value;
                            return v.length > 50 ? `${v.substr(0, 50)}...` : v;
                        }
                        return value;
                    },
                    2
                );
                setResultJson(resString);
            }
        })();
    }, []);

    const [imgGallerySelected, setImgGallerySelected] = useState<string>('');

    const propsImgGallery = props?.imgGallery;
    const imgGallery = useMemo(() => {
        const list = propsImgGallery || [];
        const first = list[0]?.img ?? '';
        setImgGallerySelected(old => {
            if (!old) {
                getMaskDetectResult(first);
            }
            return !!old ? old : first;
        });
        return list;
    }, [setImgGallerySelected, propsImgGallery, getMaskDetectResult]);

    const onImgGalleryChange = useCallback((key: string) => {
        setImgGallerySelected(old => {
            if (old !== key) {
                getMaskDetectResult(key);
            }
            return old !== key ? key : old;
        });
    }, [setImgGallerySelected, getMaskDetectResult]);

    return {
        resultImg,
        resultJson,
        imgGallery,
        imgGallerySelected,

        onImgGalleryChange,
        getMaskDetectResult
    };
};

export default useMaskDetectHooks;
