/**
 * @file paddle hub 列表页 store
 * @author FengGuang(fengguang01@baidu.com)
 */
import { useCallback } from 'react';
import { useMount } from 'react-use';

import usePaddleHubMenu from './components/paddle-hub-menu/usePaddleHubMenu';
import usePaddleHubModelList from './components/paddle-hub-model-list/usePaddleHubModelList';

import useChartsList from './components/charts/useChartsList';
import useSearchForm from './components/search-form/useSearchForm';
import { getScrollTop } from '../../utils/get-scroll';


const usePaddleHub = () => {
    const searchFormHook = useSearchForm();
    const {
        form: searchForm
    } = searchFormHook;

    const paddleHubMenuHubHook = usePaddleHubMenu();
    const {
        form: menuForm,
        getMenuList,
    } = paddleHubMenuHubHook;

    const paddleHubModelListHook = usePaddleHubModelList();
    const {
        getModelList: modelListGetMdoelList
    } = paddleHubModelListHook;

    const chartsListHook = useChartsList();
    const { getChartLists } = chartsListHook;


    const getModelList = useCallback((changeParams?: { [key: string]: any }) => {
        const theParams: { [key: string]: any } = {
            ...searchForm.getFieldsValue(),
            ...menuForm.getFieldsValue(),
            ...changeParams
        };

        function isSortBy(name: string) {
            return ['asc', 'desc'].includes(name) ? name : undefined;
        }

        let sortBy = (isSortBy(theParams.hot) && 'hot')
            || (isSortBy(theParams.recom) && 'recom')
            || (isSortBy(theParams.utime) && 'utime');

        theParams.rule = isSortBy(theParams.hot)
            || isSortBy(theParams.recom)
            || isSortBy(theParams.utime);

        // 如果name是空字符串，则删掉。因为后端会把空字符串作为搜索条件
        if (theParams.name === '') {
            delete theParams.name;
        }

        delete theParams.hot;
        delete theParams.recom;
        delete theParams.utime;

        // 单独处理 transfer
        theParams.transfer = '1';

        modelListGetMdoelList(theParams, sortBy);
    }, [modelListGetMdoelList, menuForm, searchForm]);


    const onSearchFormChange = useCallback((changeValues: any) => {
        getModelList({ ...changeValues, page: 0 });
    }, [getModelList]);


    const onMenuSelectedKeysChange = useCallback((changeKeys: any) => {
        getModelList({ ...changeKeys, page: 0 });
    }, [getModelList]);

    const onPageChange = useCallback<typeof paddleHubModelListHook['onPageChange']>(
        (page, pageSize) => {
            getModelList({ page: page - 1, size: pageSize });
            if (getScrollTop() > 170) {
                window.scrollTo({ top: 170 });
            }
        },
        [getModelList]
    );


    useMount(async () => {
        getModelList();
        getChartLists();
        await getMenuList();
        getModelList();
    });

    return {
        onMenuSelectedKeysChange,
        onSearchFormChange,

        paddleHubModelListHook,
        searchFormHook,
        paddleHubMenuHubHook,
        chartsListHook,

        onPageChange
    };
}

export default usePaddleHub;
