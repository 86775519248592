/**
 * @file 模型库的请求方法
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';
import baseRequest from '../baseApi';

// 获取模型库左侧菜单
export const fetchGetMenuList = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/paddleorg/model_category')
    );

// 获取模型模型列表
export const fetchGetModelList = (params: Object): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/paddleorg/model_get')
            .query(params)
    );

// 获取模型详情
export interface IGetModel {
    requestUrl?: string;
}

export const fetchGetModelInfo = (params: IGetModel | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query(params)
            .query(window.location.search.slice(1))
    );

// 获取模型对应的 categoryId
export interface IGetModelCategoryId {
    requestUrl?: string;
}

export const fetchGetModeCategoryId = (params: IGetModelCategoryId | string) =>
    baseRequest(
        request.get('/platform/paddleorg/get_model_category_id')
            .query(params)
    );

