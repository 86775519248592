/**
 * @file paddle 特殊兴趣小组请求方法
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
import request from 'superagent';
import baseRequest from '../baseApi';

export interface IGetCommunityParam {
    requestUrl?: string;
}

export const fetGetchSpecialGroupInfo = (params: IGetCommunityParam | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query(params)
            .query(window.location.search.slice(1))
    );