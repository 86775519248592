/**
 * @file paddlehub demo 新冠肺炎医疗影像
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {IAiAnalysisProps} from './types';

const AiAnalysis: React.FC<IAiAnalysisProps> = props => {
    const {
        imgLength,
        tableData
    } = props;

    return (
        <div>
            对所上传的{imgLength}张CT影像的智能分析：<br />
            1.
            左肺有{tableData.lesion_num.lung_l}个疑似肺炎病灶，病灶总体积{tableData.lesion_volume.lung_l}mm³，左肺的病灶负担为{tableData.lesion_percent.lung_l}<br />
            2.
            右肺有{tableData.lesion_num.lung_r}个疑似肺炎病灶，病灶总体积{tableData.lesion_volume.lung_r}mm³，右肺的病灶负担为{tableData.lesion_percent.lung_r}<br />
            3.
            全肺总有{tableData.lesion_num.lung_all}个疑似肺炎病灶，病灶总体积{tableData.lesion_volume.lung_all}mm³，全肺的病灶负担为{tableData.lesion_percent.lung_all}<br />
        </div>
    );
};

export default AiAnalysis;
