/**
 * @file paddlehub demo 新冠肺炎医疗影像  病灶分析
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useState} from 'react';

const useLesionTableHooks = () => {
    const [tableData, _setTableData] = useState({
        lesion_num: {
            lung_l: '',
            lung_r: '',
            lung_all: ''
        },
        lesion_volume: {
            lung_l: '',
            lung_r: '',
            lung_all: ''
        },
        lesion_percent: {
            lung_l: '',
            lung_r: '',
            lung_all: ''
        }
    });

    const setTableData = useCallback((data: any) => {
        const lesionNum = data?.lesion_num ?? {};
        const lesionVolume = data?.lesion_volume ?? {};
        const lesionPercent = data?.lesion_percent ?? {};
        _setTableData({
            lesion_num: {
                lung_l: lesionNum.lung_l ?? '',
                lung_r: lesionNum.lung_r ?? '',
                lung_all: lesionNum.lung_all ?? ''
            },
            lesion_volume: {
                lung_l: lesionVolume.lung_l !== undefined
                    ? `${(lesionVolume.lung_l * 100).toFixed(2)}`
                    : '',
                lung_r: lesionVolume.lung_r !== undefined
                    ? `${(lesionVolume.lung_r * 100).toFixed(2)}`
                    : '',
                lung_all: lesionVolume.lung_all !== undefined
                    ? `${(lesionVolume.lung_all * 100).toFixed(2)}`
                    : ''
            },
            lesion_percent: {
                lung_l: lesionPercent.lung_l !== undefined
                    ? `${(lesionPercent.lung_l * 100).toFixed(2)}%`
                    : '',
                lung_r: lesionPercent.lung_r !== undefined
                    ? `${(lesionPercent.lung_r * 100).toFixed(2)}%`
                    : '',
                lung_all: lesionPercent.lung_all !== undefined
                    ? `${(lesionPercent.lung_all * 100).toFixed(2)}%`
                    : ''
            }
        });
    }, [_setTableData]);

    return {
        tableData,
        setTableData
    };
};

export default useLesionTableHooks;
