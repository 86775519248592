/**
 * @file paddlex 用户信息 textarea
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useMemo} from 'react';
import {Input} from 'antd';
import {TextAreaProps} from 'antd/es/input';


const PaddleTextarea: React.FC<TextAreaProps>
    = React.forwardRef<any, TextAreaProps>(
    (
        props,
        ref
    ) => {
        const {value, maxLength} = props;
        const valueLength = useMemo(() => {
            if (typeof (value) === 'number') {
                return value;
            }
            if (typeof (value) === 'string' || Array.isArray(value)) {
                return value.length;
            }
            return 0;
        }, [value]);

        return (
            <div className="paddle-textarea">
                <Input.TextArea {...props} ref={ref} />

                {typeof (maxLength) !== 'undefined'
                && (
                    <div className="paddle-textarea-length-wrap">
                        {valueLength}/{maxLength}
                    </div>
                )}
            </div>
        );
    });

export default PaddleTextarea;
