/**
 * @file PaddlePaddle 主站页脚
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import footerData from './footerData';
import baiduweixinqr from '../../images/paddlepaddleQr.jpg';
import wenxinNew from '../../images/weixinNew.jpg';

import qq from '../../images/qq.jpg';
import A from '../a/A';

const Footer: React.FC = React.memo(() => {
    return (
        <footer className="paddle-footer">
            <div className="paddle-footer-container">
                {footerData.map(group => (
                    <div key={group.groupName} className="paddle-footer-group">
                        <div className="paddle-footer-group-title">{group.groupName}</div>
                        <div className="paddle-footer-item-list">
                            {group.list.map(item => (
                                <div className="paddle-footer-item" key={`${group.groupName},${item.name}`}>
                                    {item.url ? (
                                        <a
                                            target={item.target}
                                            href={item.url}
                                            rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
                                        >
                                            {item.name}
                                        </a>
                                    ) : (
                                        item.name
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="paddle-footer-qr-wrap">
                    {/* <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr-context" style={{ backgroundImage: `url(${qq})` }}>
                            <img className="paddle-footer-qr-context-img" alt="paddle weixin qr" src={qq} />
                        </div>
                        <div className="paddle-footer-qr-title">
                            <div>飞桨官方技术交流群</div>
                            <div className="paddle-footer-qr-subtitle">(QQ群号:734519526)</div>
                        </div>
                    </div> */}
                    <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr-context" style={{ backgroundImage: `url(${baiduweixinqr})` }}>
                            <img className="paddle-footer-qr-context-img" alt="paddle weixin qr" src={baiduweixinqr} />
                        </div>
                        <div className="paddle-footer-qr-title">飞桨微信公众号</div>
                    </div>
                    <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr-context" style={{ backgroundImage: `url(${wenxinNew})` }}>
                            <img className="paddle-footer-qr-context-img" alt="paddle weixin qr" src={wenxinNew} />
                        </div>
                        <div className="paddle-footer-qr-title">飞桨企业俱乐部微信公众号</div>
                    </div>
                </div>
            </div>
            <div className="paddle-footer-bottom">
                ©Copyright 2020, PaddlePaddle developers.{' '}
                <A href="https://beian.miit.gov.cn" className="beian record-link-style" target>
                    备案号：京ICP证030173号-116
                </A>
                <a
                    className="footer-mark-number record-link-style"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001"
                    target="_blank"
                    rel="noreferrer"
                >
                    京公网备案11000002000001号
                </a>
            </div>
        </footer>
    );
});

export default Footer;
