/**
 * @file paddlehub demo ai写作面板 情话
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback} from 'react';
import {IShowPaneProps} from './types';
import ShowPane from './ShowPane';

const LoverPrattleShowPane: React.FC<IShowPaneProps> = (props) => {
    const validate = useCallback<Exclude<IShowPaneProps['validate'], undefined>>((inputValue) => {
        if (!inputValue) {
            return ' ';
        }
        if ((/[a-zA-Z0-9]/).test(inputValue) || inputValue.length > 30) {
            return '格式错误，请输入中文汉字，上限30个字以内';
        }
    }, []);

    const getDataTransition = useCallback((data: any, value: string) => {
        const theR: string[] = [];
        if (typeof (data) === 'string') {
            theR.push(data);
        }
        else if (data && typeof (data.text) === 'string') {
            theR.push(data.text);
        }
        else {
            const r: any[] = Array.isArray(data) ? data : [];
            r.forEach((subR) => {
                if (Array.isArray(subR)) {
                    subR.forEach((subR2) => {
                        theR.push('' + typeof (subR2) === 'string' ? subR2 : (subR2?.text ?? ''));
                    });
                }
                else {
                    theR.push('' + typeof (subR) === 'string' ? subR : (subR?.text ?? ''));
                }
            });
        }
        return theR.slice(0, 1);
    }, []);

    return (
        <ShowPane
            model="ernie_gen_lover_words"
            inputPlaceholder="请输入30个字以内"
            validate={validate}
            getDataTransition={getDataTransition}
            {...props}
        />
    );
};

export default LoverPrattleShowPane;
