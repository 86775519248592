/**
 * @file 文字卡片
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React from 'react';
import { Col } from 'antd';
import A from '../../../../components/a/A';
import VerticalEllipsis from '../../../../components/vertical-ellipsis/VerticalEllipsis';
import { IIconTextCardCardListItem } from './types';


interface IProps extends IIconTextCardCardListItem {
    children?: React.ReactNode;
    hasTag?: boolean;
    childRows?: number;
    onClick?: () => any;
}

const IconTextCard: React.FC<IProps> = props => {
    const {
        title,
        text,
        link,
        iconUrl,
        onClick,
        tagText,
        hasTag,
        childRows
    } = props;

    return (
        <Col span={childRows}>
            <A
                className="paddle-icon-text-card"
                href={link}
                onClick={onClick}
                target
            >
                {!!iconUrl
                    && (
                        <div className="paddle-icon-text-card-icon-wrap">
                            <i
                                className="paddle-icon-text-card-icon"
                                style={{ backgroundImage: iconUrl ? `url(${iconUrl})` : undefined }}
                            />
                        </div>
                    )}
                <div className="paddle-icon-text-card-content">
                    <div className="paddle-icon-text-card-title">
                        {title}
                    </div>
                    <div className="paddle-icon-text-card-text">
                        <VerticalEllipsis>
                            <span {...{ dangerouslySetInnerHTML: { __html: text } }} />
                        </VerticalEllipsis>
                    </div>
                    {hasTag
                        && (
                            <div className="paddle-icon-text-card-tag-group">
                                <div className="paddle-icon-text-card-tag">
                                    {tagText}
                                </div>
                            </div>
                        )
                    }
                </div>
            </A>
        </Col>
    );
};

export default IconTextCard;
