/**
 * @file 视频封面
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, VideoHTMLAttributes } from 'react';
import classNames from 'classnames';

// 是否支持 object-fit
export const supportObjectFit = (() => {
    if (!window.getComputedStyle) {
        return true;
    }
    else {
        const testNode = document.createElement('div');

        if ((() => {
            try {
                testNode.style.objectFit = 'cover';
            }
            catch (e) {
            }

            return testNode.style.objectFit !== '';
        })()) {
            return true;
        }
    }
    return false;
})();


interface IVideoCoverProps extends VideoHTMLAttributes<HTMLVideoElement> {
    forceUseObjectFit?: boolean;
    hoverAutoPlay?: boolean;
}

const VideoCover = React.forwardRef<HTMLVideoElement | null, IVideoCoverProps>((props, ref) => {
    const {
        forceUseObjectFit,
        className,
        children,
        src,
        ...restProps
    } = props;
    const videoRef = useRef<HTMLVideoElement>(null);

    const hoverAutoPlay = useMemo(() => {
        return props.hoverAutoPlay === undefined ? true : props.hoverAutoPlay;
    }, [props.hoverAutoPlay]);

    useEffect(() => {
        if (!videoRef.current || !videoRef.current.parentElement) {
            return;
        }
        const videoDom = videoRef.current;
        const parentDom = videoRef.current.parentElement;
        if ((!supportObjectFit || forceUseObjectFit)) {
            videoDom.addEventListener('canplay', () => {
                const videoHeight = videoDom.videoHeight;
                const videoWidth = videoDom.videoWidth;
                const parentHeight = parentDom.offsetHeight;
                const parentWidth = parentDom.offsetWidth;
                if (videoHeight && videoWidth && parentHeight && parentWidth) {
                    if (videoHeight / videoWidth > parentHeight / parentWidth) {
                        videoDom.style.width = '100%';
                        videoDom.style.height = 'auto';
                    }
                    else {
                        videoDom.style.width = 'auto';
                        videoDom.style.height = '100%';
                    }
                    videoDom.style.top = '50%';
                    videoDom.style.left = '50%';
                    videoDom.style.transform = 'translate(-50%,-50%)';
                }
            });
        }
        else {
            videoDom.style.width = '';
            videoDom.style.height = '';
            videoDom.style.top = '';
            videoDom.style.left = '';
            videoDom.style.transform = '';
        }
    }, [forceUseObjectFit]);

    useImperativeHandle<HTMLVideoElement | null, HTMLVideoElement | null>(
        ref,
        () => videoRef.current
    );

    const propsMouseEnter = props.onMouseEnter;
    const handleMouseEnter = useCallback((event) => {
        propsMouseEnter && propsMouseEnter(event);
        if (hoverAutoPlay && videoRef.current) {
            videoRef.current.play();
        }
    }, [hoverAutoPlay, propsMouseEnter]);

    const propsMouseLeave = props.onMouseLeave;
    const handleMouseLeave = useCallback((event) => {
        propsMouseLeave && propsMouseLeave(event);
        if (hoverAutoPlay && videoRef.current) {
            videoRef.current.pause();
        }
    }, [hoverAutoPlay, propsMouseLeave]);

    return (
        <video
            loop
            muted
            {...restProps}
            className={classNames(
                'paddle-video-cover',
                hoverAutoPlay ? 'paddle-video-cover-hover-autoplay' : undefined,
                className
            )}
            ref={videoRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <source key={src} src={src} />
            {children}
        </video>
    );
});

export default VideoCover;
