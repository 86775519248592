/**
 * @file 右侧导航条
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { Popover } from 'antd';
import A from '../a/A';
import { getCmsDataInfo } from '../../api/pages/paddleCmsGetDataApi';
interface IqrCode {
    qrName: string;
    imgUrl: string;
}

const RightNavigation: React.FC = React.memo(() => {
    const wrap = useMemo(() => {
        const wrap = document.createElement('div');
        document.body.appendChild(wrap);
        return wrap;
    }, []);

    const [showTop, setShowTop] = useState<boolean>(() => {
        const clientHeight = document.documentElement.clientHeight || 0;
        const scrollTop = document.documentElement.scrollTop || 0;
        return clientHeight > 0 && (clientHeight * 2) / 3 < scrollTop;
    });

    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateTop = throttle(() => {
            const clientHeight = document.documentElement.clientHeight;
            const scrollTop = document.documentElement.scrollTop;
            if (clientHeight > 0 && (clientHeight * 2) / 3 < scrollTop) {
                setShowTop(true);
            } else {
                setShowTop(false);
            }
        }, 100);

        window.addEventListener('scroll', updateTop);
        window.addEventListener('size', updateTop);
        return () => {
            window.removeEventListener('scroll', updateTop);
            window.removeEventListener('size', updateTop);
        };
    }, [setShowTop]);

    const onTopClick = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [qrCodeList, setQrCodeList] = useState<IqrCode[]>([]);
    useEffect(() => {
        (async () => {
            const res: any = await getCmsDataInfo({ requestUrl: '/qrCodeList' }).catch(err => {
                // ignore
            });
            let tempData = res?.body?.result?.pageData;
            if (tempData?.qrCodeList) {
                setQrCodeList(tempData.qrCodeList);
            }
        })();
    }, []);
    const [ismodels, setIsModels] = useState<boolean>(false);
    useEffect(() => {
        // console.log(window.location.pathname);
        if (window.location.pathname.includes('/models') || window.location.pathname.includes('/modelsDetail')) {
            setIsModels(true);
        }
    }, []);
    return createPortal(
        <div className="paddle-right-nav-root">
            <div className="paddle-right-nav" ref={navRef}>
                <A href={ismodels ? '/feedback?type=17' : '/feedback'} target="_blank" rel="noopener noreferrer">
                    <div className="paddle-right-nav-item paddle-right-nav-item-consultation paddle-right-nav-item-havecontent">
                        <div className="paddle-right-nav-item-background" />
                        <div className="paddle-right-nav-item-context">联系我们</div>
                    </div>
                </A>
                <Popover
                    placement="left"
                    trigger="hover"
                    overlayClassName="paddle-right-nav-item-qrcode-overlay"
                    getPopupContainer={() => navRef.current || document.body}
                    content={
                        <React.Fragment>
                            {qrCodeList &&
                                qrCodeList.map((item, index) => (
                                    <div key={index}>
                                        <div key={item.qrName} className="paddle-right-nav-item-qrcode-title">
                                            {item.qrName}
                                        </div>
                                        <img
                                            key={`${item.qrName}imgUrl`}
                                            className="paddle-right-nav-item-qrcode-qrcode"
                                            alt={item.qrName}
                                            src={item.imgUrl}
                                        />
                                    </div>
                                ))}
                            {/* <div className="paddle-right-nav-item-qrcode-title">{qrCodeList[0].qrName}</div>
                            <div className="paddle-right-nav-item-qrcode-qrcode paddle-right-nav-item-qrcode-qrcode-group" />
                            <div className="paddle-right-nav-item-qrcode-title">关注飞桨公众号</div>
                            <div className="paddle-right-nav-item-qrcode-qrcode paddle-right-nav-item-qrcode-qrcode-subscription" /> */}
                        </React.Fragment>
                    }
                >
                    <div className="paddle-right-nav-item paddle-right-nav-item-qrcode paddle-right-nav-item-havecontent">
                        <div className="paddle-right-nav-item-background" />
                        <div className="paddle-right-nav-item-context">官方社群</div>
                    </div>
                </Popover>
                <div
                    className={classNames('paddle-right-nav-item paddle-right-nav-item-top', {
                        'paddle-right-nav-item-top-show': showTop
                    })}
                    onClick={onTopClick}
                >
                    <div className="paddle-right-nav-item-background" />
                </div>
            </div>
        </div>,
        wrap
    );
});

export default RightNavigation;
