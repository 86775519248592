/**
 * @file 带图的卡片
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import {Col} from 'antd';

import A from '../a/A';
import VerticalEllipsis from '../vertical-ellipsis/VerticalEllipsis';

export interface IPictureCard {
    title: string;
    text: string;
    img: string;
    link?: string;
    target?: string;
    tagText?: string;
}

interface IProps extends IPictureCard {
    children?: React.ReactNode;
}

const PictureCard: React.FC<IProps> = props => {
    const {
        title,
        text,
        img,
        link,
        target,
        tagText
    } = props;

    return (
        <Col className="paddle-picture-card-grid" xs={8}>
            <div className="paddle-picture-card">
                <A
                    className="paddle-picture-card-full-link"
                    href={link}
                    target={target}
                    rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                >
                    <div
                        className="paddle-picture-card-img"
                        style={{backgroundImage: `url(${img})`}}
                    >
                        <img alt={title} src={img} />
                    </div>
                    <div className="paddle-picture-card-contain">
                        <div className="paddle-picture-card-title">
                            {title}
                        </div>
                        <div className="paddle-picture-card-text">
                            <VerticalEllipsis>
                                {text}
                            </VerticalEllipsis>
                        </div>
                        <div className="paddle-picture-card-tag-group">
                            <span className="paddle-picture-card-tag">
                                {tagText}
                            </span>
                        </div>
                    </div>
                </A>
            </div>
        </Col>
    );
};

export default PictureCard;
