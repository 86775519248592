import { Button, Upload } from 'antd';

// import { fetchGetPerformAudioChineseTransfer } from '../../../../../../api/pages/voiceDemoApi';
// import { getImgBase64, readFile } from '../../../../../../utils/picture-compressor';
import { Type } from '../../../../type';

import "./style.less";


const UploadAudio = ({uploadRequest}:{uploadRequest:Type["uploadRequest"]}) => {
    
    return (<>
        <div className="uploadAudio">
            <div className="uploadAudio_wrapper">
                <div className="uploadAudio_box">
                    <div className="uploadAudio_box_img"></div>
                </div>
                <div>
                    <Upload
                        className="uploadAudio_upload"
                        name=""
                        action=""
                        //    headers={`"Access-Token":${window.localStorage.getItem('token')}`}
                        showUploadList={false}
                        accept=".wav"
                        customRequest={uploadRequest}
                    >
                        <Button className="uploadAudio_upload_btn">上传音频</Button>
                    </Upload>
                </div>
                <div className="uploadAudio_hint">提示：1. 可上传本地音频或输入音频网络URL；2. 音频仅支持*.wav； 3. 请上传3MB以下的音频文件</div>
            </div>
        </div>
    </>
    )
};

export default UploadAudio;
