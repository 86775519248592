/**
 * @file paddlehub demo 口罩识别 api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';

interface IGetMaskDetectResultParams {
    image?: string;
    image_url?: string;
}

export const fetchGetMaskDetectResult = (
    params: IGetMaskDetectResultParams
): Promise<request.Response> => {
    return request
        .post(`/paddlehub-api/face_detection_mask/face_detector_mask`)
        .send(params);
};
