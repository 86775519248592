/**
 * @file paddlehub demo 新冠肺炎医疗影像 上传组件
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {Button, Upload} from 'antd';

import {IUploadBtnProps} from './types';

const UploadBtn: React.FC<IUploadBtnProps> = props => {
    const {loading} = props;
    return (
        <Upload
            {...props}
            accept=".zip"
            disabled={loading}
            showUploadList={false}
        >
            <Button
                type="primary"
                size="large"
                className="medical-img-upload-input"
            >
                {loading
                    ? <span>正在导入，大约2分钟 <i className="medical-img-upload-loading" /></span>
                    : <span>导入DICOM文件压缩包</span>
                }
            </Button>
        </Upload>
    );
};

export default UploadBtn;
