/**
 * @file 特殊A标签
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React from 'react';
import {Link} from 'react-router-dom';
import * as H from 'history';

export interface LinkAProps<S = H.LocationState>
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    to?: H.LocationDescriptor<S>;
    replace?: boolean;
    innerRef?: React.Ref<HTMLAnchorElement>;
}

const LinkA: React.FC<LinkAProps> = props => {
    const propsTo = props.to;
    if (!propsTo) {
        return (<span {...props} />);
    }

    if (typeof (propsTo) === 'string'
        && /^(https?:)?\/\/[^/]+\//.test(propsTo)) {
        return (<a {...props} href={propsTo}>{props.children}</a>);
    }

    return (
        // @ts-ignore
        <Link {...props} to={propsTo} />
    );
};

export default LinkA;
