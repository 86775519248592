
/**
 * @file paddle hub 搜索排行榜列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import { useCallback, useState, useMemo } from "react";
import { fetchGetTopList } from "../../../../api/pages/paddleHubApi";
import numberFormat from "../../../../components/number-format/number-format";

import { IChartItem, IChart } from "./types";

const useChartsList = () => {
    // 热门模型榜
    const [chartsAllList, setChartsAllList] = useState<IChart[]>(() => {
        return [];
    });
    const showChartsList = useMemo(() => {
        return chartsAllList.filter(i => !!i)
    }, [chartsAllList]);

    const getChartsList = useCallback(
        async (module = '', index: number, title: string, format?: (v: IChartItem) => IChartItem) => {
            const res = await fetchGetTopList({ filter: module })
                .catch(() => {
                    // ignore
                });
            if (res) {
                const data = res.body.data || {};
                let list: any[] = [];
                if (Array.isArray(data)) {
                    list = data;
                }
                else if (typeof (data) === 'object') {
                    const tmp = Object.values(data);
                    list = Array.isArray(tmp) ? tmp.flat() : list;
                }

                const result: IChartItem[] = [];
                list.forEach((item) => {
                    const newItem = {
                        name: item.key,
                        tag: item.doc_count,
                        url: item.url
                    };
                    result.push(format ? format(newItem) : newItem);
                });
                if (index !== undefined) {
                    setChartsAllList((old) => {
                        const newList = old.slice(0);
                        newList[index] = {
                            ...newList[index],
                            title,
                            list: result.slice(0, 5)
                        };
                        return newList;
                    });
                }
                return result;
            }
            return [];
        },
        [setChartsAllList]
    );

    const getChartLists = useCallback(() => {
        // 热门模型榜
        getChartsList(
            'module',
            0,
            '热门模型榜（上月模型排行榜）',
            (v) => ({
                ...v,
                tag: numberFormat(v.tag),
                url: v.url ?? `/hubdetail?name=${v.name}`
            })
        );
        // 最新上线
        getChartsList('update_time', 1, '最新上线');
        // 开发者贡献榜
        getChartsList(
            'contributor',
            2,
            '开发者贡献榜',
            (v) => ({
                ...v,
                tag: numberFormat(v.tag)//
            })
        );
    }, [getChartsList]);

    return {
        chartsAllList: showChartsList,
        setChartsAllList,
        getChartLists
    };
}

export default useChartsList;
