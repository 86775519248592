/**
 * @file sleep
 * @author FengGuang(fengguang01@baidu.com)
 */

const sleepPromise = (milliseconds: number = 0) => {
    return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), milliseconds);
    });
};

export default sleepPromise;
