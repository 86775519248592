/**
 * @file paddlehub demo 新冠肺炎医疗影像  病灶分析
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {ILesionTableProps} from './types';


const LesionTable: React.FC<ILesionTableProps> = props => {
    const tableData = props.tableData;

    return (
        <table className="medical-img-lesion-analysis-table">
            <thead>
            <tr>
                <th></th>
                <th>总病灶数<br />
                    (个)
                </th>
                <th>病灶总体积<br />
                    (mm³)
                </th>
                <th>病灶负担<br />
                    (%)
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>左肺</td>
                <td>{tableData.lesion_num.lung_l}</td>
                <td>{tableData.lesion_volume.lung_l}</td>
                <td>{tableData.lesion_percent.lung_l}</td>
            </tr>
            <tr>
                <td>右肺</td>
                <td>{tableData.lesion_num.lung_r}</td>
                <td>{tableData.lesion_volume.lung_r}</td>
                <td>{tableData.lesion_percent.lung_r}</td>
            </tr>
            <tr>
                <td>全肺</td>
                <td>{tableData.lesion_num.lung_all}</td>
                <td>{tableData.lesion_volume.lung_all}</td>
                <td>{tableData.lesion_percent.lung_all}</td>
            </tr>
            </tbody>
        </table>
    );
};

export default LesionTable;
