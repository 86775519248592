// import { useState } from 'react';
import { useMount } from 'react-use';
import { useState } from 'react';
import { fetGetchSpecialGroupInfo } from '../../api/pages/paddleSpecialGroupApi';

const usePopularDetail = () => {
    const [popularList, setPopularist] = useState([]);
    useMount(() => {
        (async () => {
            const res = await fetGetchSpecialGroupInfo({requestUrl: '/paddlespecialgroup'})
                .catch((err) => {
                    // ignore
                });
            if (res) {
                const data = res.body.result?.pageData?.popularPPSIG?.list || [];
                setPopularist(data);
            }
        })();
    });
    
    return  {
        popularList
    }
};

export default usePopularDetail;