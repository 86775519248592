/**
 * @file 顶部导航栏的常量
 * @author FengGuang(fengguang01@baidu.com)
 */

interface IDocInfo {
    lang: 'zh' | 'en';
    version: string; // emun: 1.0/1.1/1.2/1.3/...
};
export const isDocumentCanSearch: boolean = (() => {
    const docInfo: IDocInfo = {
        lang: 'zh',
        ...(window as any).docInfo
    };

    const versionList = [
        {name: '0.10.0', api: 'v2'},
        {name: '0.11.0', api: 'v2'},
        {name: '0.12.0', api: 'v2'},
        {name: '0.13.0', api: 'Fluid'},
        {name: '0.14.0', api: 'Fluid'},
        {name: '0.15.0', api: 'Fluid'},
        {name: '1.0', api: 'Fluid'},
    ];

    return versionList.findIndex(item => item.name === docInfo.version) === -1;
})();
