/**
 * @file 相关咨询
 * @author v_duzhengqiang
 */
import { RootObject, Content6 } from '../../types';
import './style.less';
const RelatedInformation = (props: RootObject["relatedInformation"]) => {
  const {
    title,
    content,
    skip
  } = props;
  return (
    <div className='p-x-ai-related-information'>
      <div className='p-x-ai-relevant-information-title'>
        {
          title ?? ''
        }
      </div>
      <div className='p-x-ai-relevant-information-content'>

        {
          content.map((value: Content6, index: number) => {
            return (
              <div
                key={index}
                onClick={() => value.skip.skipLink ? window.open(value.skip.skipLink) : undefined}
                className={value.skip.skipLink ? 'p-x-ai-relevant-information-content-module' : 'p-x-ai-relevant-information-content-module p-x-ai-relevant-information-content-module-disabled'}
              >
                <div className='content-module-title'>
                  {
                    value.title ?? ''
                  }
                </div>
                <div className='content-module-desc'>
                  {
                    value.desc ?? ''
                  }
                </div>
                <div
                  className={value.skip.skipLink ? 'content-module-details content-module-details-hover' : 'content-module-details content-module-details-disabled'}
                  onClick={() => value.skip.skipLink ? window.open(value.skip.skipLink) : undefined}
                >
                  {
                    value.skip.skipName ?? ''
                  }
                </div>
              </div>
            );
          })
        }

      </div>
      <div className='p-x-ai-relevant-information-consulting'>
        <div
          className={skip.skipLink ? 'consulting-jump consulting-jump-hover' : 'consulting-jump consulting-jump-disabled'}
          onClick={() => skip.skipLink ? window.open(skip.skipLink) : undefined}
        >
          <div className='consulting-jump-name'>{skip.skipName ?? ''}</div>
          <img className='consulting-arrow' src={skip.Img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default RelatedInformation;