/**
 * @file paddlehub demo 词法分析Hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLatest, useMount} from 'react-use';
import {Form} from 'antd';
import debounce from 'lodash/debounce';

import mockData from './data.json';
import nameTable2Data from './lexTags2.json';

import {fetchGetLexicalAnalysisResult} from './textDemoApi';
import {IDemoProps} from '../../types.d';
import {
    IWordTypeItem,
    IWordResultRsItem
} from './types.d';


const nameTable2: { [k: string]: string } = nameTable2Data;


const useWordResultHooks = () => {
    const [selectedWordResultRs, setSelectedWordResultRs] = useState<string>('');
    const [wordResultRs, setWordResultRs] = useState<IWordResultRsItem[]>([]);
    const [selectedWordType, setSelectedWordType] = useState<string>('');
    const [wordTypeList, setWordTypeList] = useState<IWordTypeItem[]>([]);

    const wordResultFilterByWordTypeList = useMemo(() => {
        if (!selectedWordType) {
            return [];
        }
        return wordResultRs.filter(item => item.tag === selectedWordType);
    }, [wordResultRs, selectedWordType]);

    // 更新词法结果，以及详情的 tag
    const updateWordResultRs = useCallback((value: any[]) => {
        const newRs = value.map((item, index) => ({...item, key: `${index}-${item.word}`}));
        setWordResultRs(newRs);
        setSelectedWordResultRs(newRs[0]?.key || '');

        // 根据列表过滤出特殊tag，并且按照文件中给定的顺序排序
        const typeList: IWordTypeItem[] = value
            .filter(item => nameTable2[item.tag])
            .map(item => ({
                key: item.tag,
                label: nameTable2[item.tag] || item.tag
            }));
        // 创建一个和 nameTable2 顺序一致的 map
        const tyleListMap = new Map<string, IWordTypeItem | undefined>(
            Object.keys(nameTable2).map(key => ([key, undefined]))
        );
        // 将对象set到map中，这样就能保留顺序又能过滤掉没有出现的tag
        typeList.forEach(item => {
            tyleListMap.set(item.key, item);
        });
        const resTypeList = Array.from(tyleListMap.values()).filter(i => i) as IWordTypeItem[];
        setWordTypeList(resTypeList);
        setSelectedWordType('');
    }, [setWordResultRs, setSelectedWordResultRs, setWordTypeList, setSelectedWordType]);

    const onWordResultRsChange = useCallback((value: string) => {
        setSelectedWordResultRs(value);
        setSelectedWordType('');
    }, [setSelectedWordType, setSelectedWordResultRs]);

    const onWordTypeChange = useCallback((value: string, wordResult: string = '') => {
        const firstKey = wordResultRs.find(item => item.tag === value)?.key || '';
        setSelectedWordResultRs(firstKey);
        setSelectedWordType(value);
    }, [setSelectedWordType, setSelectedWordResultRs, wordResultRs]);

    const onWordDetailPrevClick = useCallback(() => {
        if (!selectedWordResultRs || !selectedWordType) {
            return;
        }
        const selectedIndex = wordResultRs.findIndex(item => item.key === selectedWordResultRs);
        if (selectedIndex === -1) {
            return;
        }
        const prevList = wordResultRs.slice(0, selectedIndex).reverse();
        const target = prevList.find(item => item.tag === selectedWordType);
        if (target) {
            setSelectedWordResultRs(target.key);
        }
    }, [selectedWordResultRs, selectedWordType, wordResultRs, setSelectedWordResultRs]);

    const onWordDetailNextClick = useCallback(() => {
        if (!selectedWordResultRs || !selectedWordType) {
            return;
        }
        const selectedIndex = wordResultRs.findIndex(item => item.key === selectedWordResultRs);
        if (selectedIndex === -1) {
            return;
        }
        const nextList = wordResultRs.slice(selectedIndex + 1);
        const target = nextList.find(item => item.tag === selectedWordType);
        if (target) {
            setSelectedWordResultRs(target.key);
        }
    }, [selectedWordResultRs, selectedWordType, wordResultRs, setSelectedWordResultRs]);
    return {
        selectedWordResultRs,
        wordResultRs,
        selectedWordType,
        wordTypeList,

        wordResultFilterByWordTypeList,
        updateWordResultRs,
        onWordResultRsChange,
        onWordTypeChange,
        onWordDetailPrevClick,
        onWordDetailNextClick
    };
};


const useTextDemoHooks = (props: IDemoProps) => {
    const {
        modelList,
        frameVersion,
        devResource
    } = props;

    const [inputForm] = Form.useForm();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const isFetchingRef = useLatest(isFetching);


    const {
        selectedWordResultRs,
        wordResultRs,
        selectedWordType,
        wordTypeList,

        wordResultFilterByWordTypeList,
        updateWordResultRs,
        onWordResultRsChange,
        onWordTypeChange,
        onWordDetailPrevClick,
        onWordDetailNextClick
    } = useWordResultHooks();


    /**
     * 输入框相关方法
     */
    const {getFieldValue, setFieldsValue} = inputForm;
    const [errorText, setErrorText] = useState<string>('');
    // 设置选择模型默认值
    useEffect(() => {
        if (modelList.length === 0) {
            return;
        }
        const selectedModel = getFieldValue('selectedModel') || '';
        if (!selectedModel) {
            setFieldsValue({selectedModel: modelList[0].key});
        }
    }, [modelList, getFieldValue, setFieldsValue]);

    // 用户输入时触发请求
    const onSubmitText = useMemo(
        (() => (
            debounce(async () => {
                if (isFetchingRef.current) {
                    return;
                }
                const text = getFieldValue('text') || '';
                const selectedModel = getFieldValue('selectedModel') || '';
                if (text.trim().length === 0) {
                    return;
                }
                if (!selectedModel) {
                    return;
                }
                setIsFetching(true);
                setErrorText('');
                let res;
                try {
                    res = await fetchGetLexicalAnalysisResult(selectedModel, {text: [text]});
                }
                catch (err: any) {
                    // ignore
                    setErrorText('服务暂时不可用，建议您稍后体验');
                }
                setIsFetching(false);
                if (res && Array.isArray(res.body) && res.body.length > 0) {
                    const wordList = res.body[0].rs || [];
                    updateWordResultRs(wordList);
                }
                else {
                    setErrorText('服务暂时不可用，建议您稍后体验');
                }
            }, 200)
        )),
        [getFieldValue, isFetchingRef, updateWordResultRs]
    );

    // 随机示例
    const onRandomClick = useCallback(() => {
        const oldText = getFieldValue('text') || '';
        let index = Math.floor(Math.random() * mockData.datas.length);
        while (mockData.datas[index][0].source === oldText) {
            index = Math.floor(Math.random() * mockData.datas.length);
        }
        const item = mockData.datas[index];
        setFieldsValue({'text': item[0].source});
        updateWordResultRs(item[0].rs);
    }, [updateWordResultRs, getFieldValue, setFieldsValue]);
    // 初始化时生成随机示例
    useMount(onRandomClick);

    // 用户输入为空
    const text = getFieldValue('text') || '';
    const selectedModel = getFieldValue('selectedModel') || '';
    const isInputEmpty = useMemo(() => {
        return text.trim().length === 0 || !selectedModel;
    }, [text, selectedModel]);
    /**
     * 输入框相关方法
     */

    return {
        inputForm,
        modelList,
        frameVersion,
        devResource,
        wordResultRs,
        errorText,
        isInputEmpty,
        selectedWordResultRs,
        onWordResultRsChange,
        wordTypeList,
        selectedWordType,
        wordResultFilterByWordTypeList,
        onWordDetailPrevClick,
        onWordDetailNextClick,

        onWordTypeChange,
        onRandomClick,
        onSubmitText
    };
};

export default useTextDemoHooks;
