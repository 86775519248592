/**
 * @file paddlehub 通用图片 demo
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback} from 'react';
import {Button, Collapse, Upload, UploadProps} from 'antd';
import classNames from 'classnames';

import HeightlightBlock from './HeightlightBlock';
import PCollapse from './PCollapse';
import useMaskDetectHooks from './pictureDemoHooks';
import {IPictureDemoProps} from './types';


const PictureDemo: React.FC<IPictureDemoProps> = props => {
    const {
        resultImg,
        resultJson,
        resultTable,
        resultCollapseTable,
        imgGallery,
        imgGallerySelected,

        onImgGalleryChange,
        getPictureDemoResult
    } = useMaskDetectHooks(props);

    const uploadRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>(
        (options) => {
            console.log(options,"options");
            
            // antd 4.13.1 类型错误
            getPictureDemoResult(options.file as File);
        },
        [getPictureDemoResult]
    );
        
    return (
        <div className="picture-demo clear-both">
            <div className="picture-demo-right-background" />
            <div className="picture-demo-right">
                <div className="picture-demo-json">
                    <PCollapse
                        defaultActiveKey={['2']}
                        expandIconPosition="right"
                        bordered={false}
                        accordion={true}
                        className={classNames(
                            props.resultCollapsePanel
                                ? 'picture-demo-json-list-num-2'
                                : ''
                        )}
                    >
                        {props.resultCollapsePanel
                        && (
                            <Collapse.Panel
                                key="1"
                                header="识别"
                            >
                                <div className="picture-demo-json-body">
                                    <table className="picture-demo-json-table">
                                        <thead>
                                        <tr>
                                            <th>文字</th>
                                            <th>置信度</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {resultCollapseTable.map(item => (
                                            
                                            <tr key={`${item.name}${item.score}`}>
                                                <td>{item.name}</td>
                                                <td>{item.score}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Collapse.Panel>
                        )}
                        <Collapse.Panel
                            key="2"
                            header="Response"
                        >
                            <div className="picture-demo-json-body">
                                <HeightlightBlock
                                    resultJson={resultJson}
                                />
                            </div>
                        </Collapse.Panel>
                    </PCollapse>
                </div>
            </div>
            <div className="picture-demo-main">
                <div
                    className="picture-demo-img"
                    style={{backgroundImage: `url(${resultImg})`}}
                >
                    {resultTable.length > 0
                    && (
                        <div className="picture-demo-result">
                            <div className="picture-demo-result-title">识别结果</div>

                            <table>
                                <tbody>
                                {resultTable.map(row => (
                                    <tr>
                                        <td className="picture-demo-result-row1">{row.name}</td>
                                        <td className="picture-demo-result-row2">
                                            <div className="picture-demo-result-progress">
                                                <div
                                                    className="picture-demo-result-progress-inner"
                                                    style={{width: `${row.score * 100}%`}}
                                                />
                                            </div>
                                        </td>
                                        <td className="picture-demo-result-row3">{row.score}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="picture-demo-img-input clear-both">
                        <div className="picture-demo-img-input-info">
                            图片文件类型支持PNG、JPG、JPEG、BMP，图片大小不超过2M。
                        </div>

                        <Upload
                            customRequest={uploadRequest}
                            showUploadList={false}
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="picture-demo-img-input-upload"
                            >
                                试试你的图片?
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="picture-demo-img-gallery">
                    <ul className="picture-demo-img-gallery-item-wrap">
                        {imgGallery.map(item => (
                            <li
                                key={item.img}
                                className={classNames(
                                    'picture-demo-img-gallery-item',
                                    {
                                        'picture-demo-img-gallery-item-selected': item.img === imgGallerySelected
                                    }
                                )}
                                style={{backgroundImage: `url(${item.img})`}}
                                onClick={() => onImgGalleryChange(item.img)}
                            >
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PictureDemo;
