/**
 * @file paddlehub demo 新冠肺炎医疗影像
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {Col, Row} from 'antd';

import LungChart from './components/lung-chart/LungChart';
import Textarea from './components/textarea/Textarea';
import MainImg from './components/main-img/MainImg';
import UploadBtn from './components/upload-btn/UploadBtn';
import useMedicalImageHooks from './medicalImageHooks';

import {IMedicalImageProps} from './types';
import LesionTable from './components/lesion-table/LesionTable';
import AiAnalysis from './components/lesion-table/AiAnalysis';
import UserInstructionsModal from './components/medical-img-modal/UserInstructionsModal';


const MedicalImage: React.FC<IMedicalImageProps> = props => {
    const {
        imgHooks,
        uploadHooks,
        lesionTableHooks,
        lungChartHooks,
        userInstructionsModalHooks
    } = useMedicalImageHooks();

    return (
        <div className="medical-img clear-both">
            <div className="medical-img-menu-wrap clear-both">
                <ul className="medical-img-menu">
                    <li className="medical-img-menu-item">
                        病情诊断
                    </li>
                    <li className="medical-img-menu-item medical-img-menu-item-disabled">
                        随访
                    </li>
                </ul>
            </div>
            <div className="medical-img-body">
                <div className="medical-img-left">
                    <MainImg {...imgHooks} />
                    <div className="medical-img-upload">
                        <div className="medical-img-upload-tips">
                            上传分析：压缩包格式为zip，大小不超过100M
                        </div>
                        <UploadBtn {...uploadHooks} />
                    </div>
                </div>
                <div className="medical-img-right">
                    <div className="medical-img-right-title">
                        分析结果
                    </div>
                    <div className="medical-img-h2 medical-img-chart-title">
                        双肺密度分析(直方图)
                        <span className="medical-img-chart-text">hu值：-1000到100</span>
                    </div>
                    <div className="medical-img-chart-wrap">
                        <div className="medical-img-chart-body">
                            <LungChart {...lungChartHooks} />
                        </div>
                    </div>
                    <div>
                        <Row gutter={30}>
                            <Col xs={12}>
                                <div className="medical-img-h2">
                                    疑似病灶分析
                                </div>
                                <div className="medical-img-lesion-analysis">
                                    <LesionTable {...lesionTableHooks} />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="medical-img-h2">
                                    AI分析结果
                                    <span className="medical-img-h2-sub">内容仅供医生参考 不可独立作为诊疗结论</span>
                                </div>
                                <div className="medical-img-ai-analysis">
                                    <AiAnalysis
                                        imgLength={imgHooks.imgList.length}
                                        {...lesionTableHooks}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row className="medical-img-doctor-advice">
                        <Col className="medical-img-h2" xs={24}>
                            医生建议
                        </Col>
                        <Textarea
                            className="medical-img-doctor-advice-textarea"
                            maxLength={100}
                        />
                    </Row>
                </div>
            </div>
            <UserInstructionsModal
                {...userInstructionsModalHooks}
            />
        </div>
    );
};

export default MedicalImage;
