/**
 * @file 本地端下载
 * @author v_duzhengqiang
 */
import { Button } from 'antd';
import { RootObject, Content5, DescContent } from '../../types';
import './style.less';
const LocalDownload = (props: RootObject["localDownload"]) => {
  const {
    title,
    tips,
    content
  } = props;
  return (
    <div className='p-x-ai-local-download'id='local-download'>
      <div className='p-x-ai-local-download-title'>
        {
          title ?? ''
        }
      </div>
      <div className='p-x-ai-local-download-tips'>
        {
          tips ?? ''
        }
      </div>
      <div className='p-x-ai-local-download-modules'>

        {
          content.map((cV: Content5) => {
            return (
              <div className='p-x-ai-local-download-modules-module' key={cV.title}>
                <div className='p-x-ai-local-download-modules-module-header'>
                  <img src={cV.titleImg} alt="" className='p-x-ai-local-download-modules-module-header-img' />
                  <div className='p-x-ai-local-download-modules-module-header-title'>
                    {
                      cV.title ?? ''
                    }
                  </div>
                </div>
                {/* 内容 */}
                <div className='p-x-ai-local-download-modules-module-desc'>
                  {
                    cV.descContent.map((item: DescContent, index: number) => {
                      return (
                        <div className='p-x-ai-local-download-modules-module-desc-content' key={index}>
                          <div className='p-x-ai-local-download-modules-module-desc-content-title'>
                            {
                              item.title ?? ''
                            }
                          </div>
                          <div className='p-x-ai-local-download-modules-module-desc-content-txt'>
                            {
                              item.desc ?? ''
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>

                <div className='p-x-ai-local-download-modules-module-download'>
                  <Button
                    className='p-x-ai-local-download-modules-module-download-btn'
                    type="primary"
                    disabled={cV.skip.skipLink ? false : true}
                  >
                    <a href={cV.skip.skipLink ? cV.skip.skipLink : ''}>
                      {
                        cV.skip.skipName ?? ''
                      }
                    </a>
                  </Button>
                </div>
              </div>
            );
          })
        }




      </div>
    </div>
  )
}

export default LocalDownload;