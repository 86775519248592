/**
 * @file paddlehub demo 词法分析输入框
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {CSSProperties, DetailedHTMLProps, TextareaHTMLAttributes, useCallback, useMemo, useState} from 'react';

interface ITextareaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    wrapClassName?: string;
    wrapStyle?: CSSProperties;
}

const LexicalDemoInput: React.FC<ITextareaProps> = props => {
    const {
        maxLength,
        onChange
    } = props;
    const [stateValue, setStateValue] = useState<string>('');
    const value = props.value ?? stateValue;
    const setValue = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typeof (onChange) === 'function') {
            onChange(event);
        }
        setStateValue(event.target.value);
    }, [onChange, setStateValue]);

    const isShowMaxInput = useMemo(() => {
        if (typeof (value) === 'number') {
            return false;
        }
        if (typeof (value) === 'string') {
            return value.trim().length === 0;
        }
        return value.length === 0;
    }, [value]);

    return (
        <div className="lexical-demo-input">
            <div className="lexical-demo-input-textarea-wrap">
                <textarea
                    value={value}
                    spellCheck="false"
                    className="lexical-demo-input-textarea"
                    maxLength={maxLength}
                    onChange={setValue}
                />
                <div className="lexical-demo-input-length-text-wrap">
                    {isShowMaxInput
                    && (
                        <span className="lexical-demo-input-length-text">
                        体验版最多可以输入
                        <span className="lexical-demo-input-length-text-num">
                            {maxLength}
                        </span>
                        个字
                    </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LexicalDemoInput;
