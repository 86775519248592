/**
 * @file 学术生态 详情Hooks
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */

import { useState } from 'react';
import { useMount } from 'react-use';
import { getAcademicInfo } from '../../api/pages/academicEcologyApi';
import { IAcademicPaperInfo, IAcademicPaperDetailMapItem, IpaperDetailActivity } from './../academicEcology/type';

const useAcademicDetail = () => {
    const [acedemicPaperDetail, setAcademicPaperDetail] = useState<IAcademicPaperDetailMapItem>();
    
    useMount(() => {
        (async () => {
            const res = await getAcademicInfo({requestUrl: '/academicEcology'})
                .catch((err) => {
                    // ignore
                });             
            if (res) {
                const data = res.body.result?.pageData || {};
                const paperDetailHash: IAcademicPaperDetailMapItem  = {};
                if (data['academic-paper'] && data['academic-paper']['paperList']) {
                    const paperList = data['academic-paper']['paperList'];
                    const paperActivity: IpaperDetailActivity = paperList[0]['paperDetail']['paperActivity'];
                    paperList.forEach((item: IAcademicPaperInfo) => {
                        paperDetailHash[`${item.title}`] = {...item.paperDetail, paperActivity}
                    });
                    setAcademicPaperDetail(paperDetailHash);
                }
            }
        })();
    });
    return {
        acedemicPaperDetail
    }
}

export default useAcademicDetail;