/**
 * @file paddlehub 拖动hook
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useEffect, useRef} from 'react';
import {useLatest} from 'react-use';
import getOffset from '../../../../../utils/get-offset';


// 寻找父元素
const isParent = (element: HTMLElement, target: HTMLElement) => {
    let current = element.parentElement;
    while (current) {
        if (current === target) {
            return true;
        }
        current = current.parentElement;
    }
    return false;
};


type WithMouseEvnet<T = any> = T & { originMouseEvent: HTMLElementEventMap['mousemove']; }

interface IOnDragParams {
    vectorX: number;
    vectorY: number;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    startTargetX: number;
    startTargetY: number;
}

const defaultOnDragParams: IOnDragParams = {
    vectorX: 0,
    vectorY: 0,
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    startTargetX: 0,
    startTargetY: 0
};

interface IUseMouseDragProps {
    onDrag?: (params: WithMouseEvnet<IOnDragParams>) => void
}

const useMouseDrag = <T extends HTMLElement = HTMLElement, S extends HTMLElement = T>(props: IUseMouseDragProps = {}) => {
    const wrapperRef = useRef<T | null>(null);
    const draggableRef = useRef<S | null>(null);
    const isMouseDownRef = useRef<boolean>(false);
    const onDragParamsRef = useRef<IOnDragParams>({
        ...defaultOnDragParams
    });

    const {onDrag} = props;
    const onDragRef = useLatest(onDrag);

    useEffect(() => {
        const wrapperDom = wrapperRef.current ?? document.documentElement;
        if (wrapperDom) {
            const onMouseDown = (event: HTMLElementEventMap['mousedown']) => {
                const targetDom = event.target as HTMLElement | null;
                const draggableDom = draggableRef.current;
                if (targetDom && draggableDom && (targetDom === draggableDom || isParent(targetDom, draggableDom))) {
                    isMouseDownRef.current = true;
                    const theOffset = getOffset(wrapperDom);
                    onDragParamsRef.current = {
                        ...defaultOnDragParams,
                        vectorX: 0,
                        vectorY: 0,
                        startX: event.pageX,
                        startY: event.pageY,
                        endX: event.pageX,
                        endY: event.pageY,
                        startTargetX: theOffset.left,
                        startTargetY: theOffset.top
                    };
                    onDragRef.current && onDragRef.current({
                        ...onDragParamsRef.current,
                        originMouseEvent: event
                    });
                }
            };
            const onMouseMove = (event: HTMLElementEventMap['mousemove']) => {
                if (isMouseDownRef.current && event.buttons > 0) {
                    onDragParamsRef.current = {
                        ...onDragParamsRef.current,
                        vectorX: event.pageX - onDragParamsRef.current.startX,
                        vectorY: event.pageY - onDragParamsRef.current.startY,
                        endX: event.pageX,
                        endY: event.pageY
                    };
                    onDragRef.current && onDragRef.current({
                        ...onDragParamsRef.current,
                        originMouseEvent: event
                    });
                }
                else if (event.buttons === 0) {
                    isMouseDownRef.current = false;
                }
            };
            const onMouseUp = (event: HTMLElementEventMap['mouseup']) => {
                isMouseDownRef.current = false;
                if (isMouseDownRef.current && event.buttons > 0) {
                    onDragParamsRef.current = {
                        ...onDragParamsRef.current,
                        vectorX: event.pageX - onDragParamsRef.current.startX,
                        vectorY: event.pageY - onDragParamsRef.current.startY,
                        endX: event.pageX,
                        endY: event.pageY
                    };
                    onDragRef.current && onDragRef.current({
                        ...onDragParamsRef.current,
                        originMouseEvent: event
                    });
                }
            };
            const onMouseLeave = () => {
                isMouseDownRef.current = false;
            };
            wrapperDom.addEventListener('mousedown', onMouseDown);
            wrapperDom.addEventListener('mousemove', onMouseMove);
            wrapperDom.addEventListener('mouseup', onMouseUp);
            document.documentElement.addEventListener('mouseleave', onMouseLeave);
            return () => {
                wrapperDom.removeEventListener('mousedown', onMouseDown);
                wrapperDom.removeEventListener('mousemove', onMouseMove);
                wrapperDom.removeEventListener('mouseup', onMouseUp);
                document.documentElement.removeEventListener('mouseleave', onMouseLeave);
            };
        }
    }, [onDragRef]);

    const result: [typeof wrapperRef, typeof draggableRef] = [
        wrapperRef,
        draggableRef
    ];
    return result;
};

export default useMouseDrag;
