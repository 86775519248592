/**
 * @file 图片处理
 * @author FengGuang(fengguang01@baidu.com)
 */

/**
 * 将图片压缩为对应尺寸
 * @param {Object} options
 * @param {File | String} options.img 图片文件或者图片的url或者base64数据
 * @param {Number} options.width 目标图片的宽度
 * @param {Number} options.height 目标图片的高度
 * @param {Number} options.quality 生成目标图片质量
 * @param {String} options.fit 图片压缩填充模式默认 scale：按比例缩放，可选 fill：按使用目标尺寸
 * @param {String} options.type 图片压缩类型默认 jpg，可选 png
 * @param {Number} options.rotate 图片旋转，由于手机拍照的角度和我们使用的头像不一致，需要旋转 默认0 仅支持 90 180 -90
 * @returns {Promise} then {width,height,img}
 */

interface IPictureCompressOptions {
    img?: File | string;
    width?: number;
    height?: number;
    quality?: number;
    fit?: 'scale' | 'fill';
    type?: 'jpg' | 'jpeg' | 'png';
    rotate?: number;
}

interface IPoctureCompressReturn {
    width: number;
    height: number;
    img: string;
}

export default async function pictureCompress(
    options: IPictureCompressOptions
): Promise<IPoctureCompressReturn> {
    if (!options.img) {
        throw (new Error('need img'));
    }
    const width = options.width || 640;
    const height = options.height || 640;
    const type = options.type || 'jpg';
    const quality = options.quality || 0.92;
    const fit = options.fit || 'scale';
    const rotate = options.rotate || 0;
    if (width <= 0 || height <= 0) {
        throw new Error('dist width or height need > 0');
    }
    if (!/jpg|png|jpeg/.test(type)) {
        throw new Error('type need jpg or png!');
    }
    if (rotate !== 90 && rotate !== -90 && rotate !== 0 && rotate !== 180) {
        throw new Error('rotate mast be 0 90 -90 180!');
    }
    const changeWidthAndHeight = rotate === 90 || rotate === -90;

    let imgSrc = '';
    if (typeof (options.img) !== 'string') {
        const reader = await readFile(options.img);
        const result = reader.result;
        if (typeof (result) === 'string') {
            imgSrc = result;
        }
    }
    else {
        imgSrc = options.img;
    }

    const image = await loadStringToImage(imgSrc);
    const distSize = getDistSize({
        width: changeWidthAndHeight ? image.naturalHeight : image.naturalWidth,
        height: changeWidthAndHeight ? image.naturalWidth : image.naturalHeight
    }, {
        width: changeWidthAndHeight ? height : width,
        height: changeWidthAndHeight ? width : height
    }, fit);
    const imgData = compress(image, distSize.width, distSize.height, type, quality, rotate);
    return {
        width: distSize.width,
        height: distSize.height,
        img: imgData
    };
}

/**
 * 单纯读取图片，不压缩
 * @param {File | String} img 图片文件或者url或者base64
 * @returns {Promise} then string
 */
export async function getImgBase64(img: File | string): Promise<string> {
    if (typeof (img) === 'string') {
        const image = await loadStringToImage(img);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return '';
        }
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        return canvas.toDataURL('image/jpeg', 1);
    }
    const res = (await readFile(img)).result;
    if (typeof (res) === 'string') {
        return res;
    }
    return '';
}

/**
 * 读取文件
 * @param {File} file 图片文件对象
 * @returns {Promise} then FileReader
 */
export function readFile(
    file: File
): Promise<FileReader> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = function () {
            resolve(fileReader);
        };
        fileReader.onerror = function (err) {
            reject(err);
        };
        fileReader.readAsDataURL(file);
    });
}

/**
 * 载入文件
 * @param {string} img 图片base64或者Url
 * @returns {Promise} then HTMLImageElement
 */
export function loadStringToImage(
    img: string
): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.onload = function () {
            resolve(image);
        };
        image.onerror = function (err) {
            reject(err);
        };
        image.src = img;
    });
}

/**
 * 将图片转换为固定尺寸的
 * @param {Image} img 图片数据
 * @param {Number} width 转换之后的图片宽度
 * @param {Number} height 转换之后的图片高度
 * @param {String} type base64的图片类型 jpg png
 * @param {Number} quality 转换之后的图片质量
 * @param {Number} rotate 旋转角度
 */
function compress(
    img: CanvasImageSource,
    width: number,
    height: number,
    type: 'jpg' | 'jpeg' | 'png',
    quality: number,
    rotate: number
) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return '';
    }
    const types = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png'
    };
    canvas.width = width;
    canvas.height = height;
    if (rotate === 90) {
        ctx.translate(width, 0);
        ctx.rotate(90 * Math.PI / 180);
        ctx.drawImage(img, 0, 0, height, width);
    }
    else if (rotate === -90) {
        ctx.translate(0, height);
        ctx.rotate(-90 * Math.PI / 180);
        ctx.drawImage(img, 0, 0, height, width);
    }
    else if (rotate === 180) {
        ctx.translate(width, height);
        ctx.rotate(180 * Math.PI / 180);
        ctx.drawImage(img, 0, 0, width, height);
    }
    else {
        ctx.drawImage(img, 0, 0, width, height);
    }
    return canvas.toDataURL(types[type], quality);
}

/**
 * 选择源尺寸与目标尺寸比例中较小的那个，保证图片可以完全显示
 * 最大值不超过1，如果图片源尺寸小于目标尺寸，则不做处理，返回图片原尺寸
 * @param {Object} source 源图片的宽高
 * @param {Object} dist 目标图片的宽高
 */
interface ISize {
    width: number;
    height: number;
}

function getDistSize(
    source: ISize,
    dist: ISize,
    fit: 'scale' | 'fill'
) {
    if (fit === 'fill') return dist;
    let scale = Math.min(dist.width / source.width, dist.height / source.height, 1);
    return {
        width: Math.round(source.width * scale),
        height: Math.round(source.height * scale)
    };
}
