/**
 * @file markdown 容器
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import {ReactMarkdownProps} from 'react-markdown';
import classNames from 'classnames';

import CodeBlock from './CodeBlock';

interface IMarkdownContainerProps extends ReactMarkdownProps {
    className?: string;
    source?: string;
    onCodeCopyClick?: () => void;
}


// 覆盖默认的渲染结果，可覆盖选项参考源码
// https://github.com/rexxars/react-markdown/blob/master/src/renderers.js
const renderersOption = {
    code: CodeBlock
};

const MarkdownContainer: React.FC<IMarkdownContainerProps> = props => {
    const {source, className} = props;

    return (
         // @ts-ignore
        <ReactMarkdown
            source={source}
            escapeHtml={false}
            renderers={renderersOption}
            {...props}
            className={classNames('markdown-body', className)}
        />
    );
};

export default React.memo(MarkdownContainer);
