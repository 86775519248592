/* eslint-disable react-hooks/exhaustive-deps */
import { Form, message, UploadProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getImgBase64 } from '../../utils/picture-compressor';
import { getCmsDataInfo } from '../../api/pages/paddleCmsGetDataApi';
import {
    fetchGetPerformAudioChineseTransfer,
    fetchGetPerformAudioEnglishTransfer,
    fetchGetPerformChineseAudioTransfer,
    fetchGetPerformEnglishAudioTransfer
} from '../../api/pages/voiceDemoApi';
import {
    RootObject,
    BtnType,
    FunctionTextValueType,
    Type,
} from './type';
const VoiceDemo = () => {
    const [form] = Form.useForm();
    const [voiceData, setVoiceData] = useState<RootObject>({
        paddleVoiceHeader: {
            description: "",
            VoiceHeaderTitle: "",
            VoiceHeaderContent: "",
            VoiceHeaderJumpContent: [],
            VoiceHeadercontent: []
        },
        paddleVoiceUsedToIntroduce: {
            UsedToIntroduceTitle: "",
            UsedToIntroduceVersionList: [],
            description: ""
        }
    });
    const [audioData, setAudioData] = useState<Type["audioData"]>({
        data: "",
        data_download: "",
        cost: 1
    });

    const [textLoading, setTextLoading] = useState<Type["textLoading"]>(false);
    const [audioLoading, setAudioLoading] = useState<Type["audioLoading"]>(false);
    const [optionValue, setOptionvalue] = useState<string>("中文合成");
    const [btnUrlvalue, setBtnUrlvalue] = useState<string>("中文识别");
    const [btnType, setBtnType] = useState<BtnType["BtnType"]>(false);
    const [btnChinaType, setChinaType] = useState<Type["btnChinaType"]>(false);
    const [functionTextValue, setFunctionTextValue] = useState<FunctionTextValueType["FunctionTextValueType"]>("林荒大吼出声，即便十年挣扎，他也从未感到过如此无助。自己的身体一点点陷入岁月之门，却眼睁睁的看着君倾城一手持剑，雪白的身影决然凄厉。就这样孤身一人，于漫天风雪中，对阵数千武者。");
    const [hintValue, setHintValue] = useState<FunctionTextValueType["FunctionTextValueType"]>("");
    const [textDta, setTextDta] = useState<Type["textDta"]>({
        cost: 1,
        data: "",
        speech_url: ""
    });
    //截取a链接
    const interception = (aValue: string) => {
        if (aValue) {
            aValue.indexOf("?");
            return aValue.slice(0, aValue.indexOf("?"))
        };
    };
    //tabs的onchang
    const TabsOnChange = (activeKey: any) => {
        if (activeKey === "textToSpeech") {
            setOptionvalue("中文合成");
            // chooseChineBtn();

        } else if (activeKey === "speechRecognition") {
            setOptionvalue("中文识别");
        };

    };

    // 获取随机中文
    const getRandomChineseWord = (textAreaRef?: any) => {
        const resultChina: string[] = [
            "钱伟长想到上海来办学校是经过深思熟虑的。",
            "林荒大吼出声，即便十年挣扎，他也从未感到过如此无助。自己的身体一点点陷入岁月之门，却眼睁睁的看着君倾城一手持剑，雪白的身影决然凄厉。就这样孤身一人，于漫天风雪中，对阵数千武者。",
            "这种车票和保险单捆绑出售属于重复性购买。",
            "手表厂有五种好产品。",
            "我喜欢说相声。",
            "我们将继续成长，用行动回击那些只会说风凉话，不愿意和我们相向而行的害群之马。",
            "这件事情需要冷处理。"
        ];
        const resultEnglish: string[] = [
            "Life was like a box of chocolates, you never know what you're gonna get",
            "Do not, for one repulse, give up the purpose that you resolved to effort.",
            "To be or not to be, that’s a question.",
            "With great power there must come great responsibility",
            "Don’t argue with the people of strong determination, because they may change the fact!",
            "Love you three thousand times."
        ];
        let text: string;
        if (optionValue === "中文合成") {
            text = resultChina[Math.floor(Math.random() * 7)];
            textAreaRef.setFieldsValue({
                text
            });
            setFunctionTextValue(text);
        } else if (optionValue === "英文合成") {
            text = resultEnglish[Math.floor(Math.random() * 6)];
            textAreaRef.setFieldsValue({
                text
            });
            setFunctionTextValue(text);
        }
        return textAreaRef;
    };
    // 选择中文识别
    const chooseChineBtn = (e?: any, form?: any) => {
       if (e.target.textContent === "中文合成") {
            setOptionvalue(e.target.textContent);
            setAudioData({
                data: "",
                data_download: "",
                cost: 1
            })
            form.setFieldsValue({
                text: "我喜欢说相声"
            });
            setChinaType(false);
        } else if (e.target.textContent === "英文合成") {
            setOptionvalue(e.target.textContent);
            setAudioData({
                data: "",
                data_download: "",
                cost: 1
            })
            form.setFieldsValue({
                text: "To be or not to be, that’s a question."
            });
            setChinaType(true);
        };
    };
    

    //选择英文识别
    const chooseBtn = async (e: any, form: any) => {
        if (e.target.textContent === "中文识别") {
            setBtnUrlvalue(e.target.textContent);
            setTextDta({
                data: "",
                speech_url: "",
                cost: 1
            });
            form.setFieldsValue({
                inputValue: "https://paddlespeech.bj.bcebos.com/PaddleAudio/zh.wav"
            });
           
            setBtnType(false);
        } else if (e.target.textContent === "英文识别") {
            setBtnUrlvalue(e.target.textContent);
            setTextDta({
                data: "",
                speech_url: "",
                cost: 1
            });
            form.setFieldsValue({
                inputValue: "https://paddlespeech.bj.bcebos.com/PaddleAudio/en.wav"
            });
           
            setBtnType(true);
        } ;

    };
    // 获取cms数据
    const getVoiceData = async () => {
        try {

            let res = await getCmsDataInfo({ requestUrl: '/voiceDemo' });
            if (res.body.errorMsg === "success") {
                let data: RootObject = res.body.result.pageData;
                setVoiceData(data);
            }
        } catch (error) {
        };

    };
    // 中文转音频
    const performChineseAudioTransfer = async (text: string) => {
        try {
            setTextLoading(true);
            let res: any = await fetchGetPerformChineseAudioTransfer({
                text
            });

            if (res.body) {
                setAudioData({
                    ...res.body,
                    data_download: interception(res.body.data_download)
                });
                setTextLoading(false);
            }

        } catch (error) {
            message.error("请求超时");
            setTextLoading(false);
        };
    };
    // 英文转音频
    const performEnglishAudioTransfer = async (text: string) => {
        try {
            setTextLoading(true);
            let res: any = await fetchGetPerformEnglishAudioTransfer({
                text
            });
            if (res.body) {
                setAudioData({
                    ...res.body,
                    data_download: interception(res.body.data_download)
                });
                setTextLoading(false);
            };
        } catch (error) {
            message.error("请求超时");
            setTextLoading(false);
        };
    }
    // 获取音频转中文
    const performAudioChineseTransfer = async (link: string) => {
        try {
            setAudioLoading(true);
            let res: any = await fetchGetPerformAudioChineseTransfer({
                speech_url: link
            });
            if (res.body) {
                setTextDta(res.body)
                setAudioLoading(false);
            }
        } catch (error) {
            message.error("请求超时");
        };
    };
    // 获取音频转英文
    const performAudioEnglishTransfer = async (link: string) => {
        try {
            setAudioLoading(true);
            let res: any = await fetchGetPerformAudioEnglishTransfer({
                speech_url: link
            });
            if (res.body) {
                setTextDta(res.body);
                setAudioLoading(false);
            }
        } catch (error) {
            message.error("请求超时");
        };
    };
    useEffect(() => {
        getVoiceData();
        setAudioData({
            data: "",
            data_download: "",
            cost: 1
        });
        form.setFieldsValue({
            text: "我喜欢说相声"
        });
        form.setFieldsValue({
            inputValue: "https://paddlespeech.bj.bcebos.com/PaddleAudio/zh.wav"
        });
    }, [])
    //文本响应
    const textOnchange = (e?: any) => {
        let text = e.target.innerHTML;
        setFunctionTextValue(text);
    };

    // 开始合成
    const startSynthesis = (startBtn: any, form?: any) => {

        let text = form.getFieldValue("text")

        if (startBtn.current.textContent === "清除内容") {
            form.setFieldsValue({
                text: ""
            });
            setAudioData({
                data: "",
                data_download: "",
                cost: 1
            });
        } else {
            if (text) {
                if (optionValue === "中文合成") {
                    if (/^[^A-Za-z]*$/.test(text)) {
                        performChineseAudioTransfer(text);
                    } else {
                        setHintValue("TTS文本中含有英文内容，请删除后重试");
                    };

                } else if (optionValue === "英文合成") {
                    if (/^[^\u4e00-\u9fa5]+$/.test(text)) {
                        performEnglishAudioTransfer(text);
                    } else {
                        setHintValue("TTS文本中含有中文内容，请删除后重试");
                    };
                };
            } else {
                setHintValue("TTS文本不能为空");
            };

        };

    };
    //识别url
    const BtnSendUrl = (e: any, inputRef: any) => {
        if (e.target.innerText === "检测URL") {
            let link = inputRef.current.state.value;
            if (btnUrlvalue === "中文识别") {
                performAudioChineseTransfer(link)

            } else if (btnUrlvalue === "英文识别") {

                performAudioEnglishTransfer(link);

            };
        } else {
            setAudioLoading(false);
            setAudioData({
                data: "",
                data_download: "",
                cost: 1
            });
            setTextDta({
                data: "",
                speech_url: "",
                cost: 1
            });
        };
    };
    // 上传文件转base64
    let fileType = ["audio/wav"];
    const getMaskDetectResult = useCallback((file: any | string) => {
        (async () => {
            if (fileType.includes(file.type)) {
                // (fileType.includes(file.type) === true);
                let fileString: any;
                if (file.size <= 3 * 1024 * 1024) {
                    fileString = await getImgBase64(file);

                } else {
                    setHintValue("文件体积超过3MB，请重新上传");
                };
                const audioBase64type = (fileString.match(/data:[^;]*;base64,/))?.[0] ?? '';
                const isBase64 = !!fileString.match(/data:[^;]*;base64,/);
                const uploadBase64 = fileString.substr(audioBase64type.length);

                try {
                    if (btnUrlvalue === "中文识别") {
                        // alert(2)
                        setAudioLoading(true);
                        let res: any = await fetchGetPerformAudioChineseTransfer({
                            speech: isBase64 ? uploadBase64 : undefined
                        });

                        if (res.body) {
                            setTextDta(res.body)
                            setAudioLoading(false);
                        }

                    } else if (btnUrlvalue === "英文识别") {

                        // alert(1)
                        setAudioLoading(true);
                        let res: any = await fetchGetPerformAudioEnglishTransfer({
                            speech: isBase64 ? uploadBase64 : undefined
                        });
                        if (res.body) {
                            setTextDta(res.body)
                            setAudioLoading(false);
                        }
                    };
                }
                catch (err: any) {
                    // ignore
                };
            } else {
                setHintValue("文件格式错误，请重新上传");
            };

        })();
    }, [optionValue, fileType]);
    const uploadRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>(
        (options) => {
            getMaskDetectResult(options.file as File);
        },
        [getMaskDetectResult]
    );
    setTimeout(() => {
        setHintValue("");
    }, 2000);

    // console.log(optionValue, "optionValue");
    // console.log(btnType, "btnType");
    // console.log(textDta, "textDta");
    return ({
        voiceData,
        btnType,
        functionTextValue,
        chooseBtn,
        getRandomChineseWord,
        textOnchange,
        startSynthesis,
        hintValue,
        textLoading,
        textDta,
        audioData,
        BtnSendUrl,
        audioLoading,
        uploadRequest,
        form,
        TabsOnChange,
        chooseChineBtn,
        btnChinaType
    })
}

export default VoiceDemo;
