/**
 * @file PaddleHub 首页应用场景hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useRef, useState} from 'react';

const useVerticalTabsListHooks = () => {
    const [selectedTabKey, setSelectedTabKey] = useState<string>('');
    const isFirstLoad = useRef<boolean>(true);

    return {
        selectedTabKey,
        setSelectedTabKey,
        isFirstLoad,
        onSelectedTabKeyChange: setSelectedTabKey
    };
};

export default useVerticalTabsListHooks;
