/**
 * @file markdown 中的 code 的复制按钮
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useState} from 'react';
import {Tooltip} from 'antd';


interface IProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

// code 中的复制按钮，包括一个 tooltip
const CopyBtn: React.FC<IProps> = props => {
    const onClick = props.onClick;
    const [title, setTitle] = useState<string>('复制');

    const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        setTitle('复制成功');
        onClick && onClick(event);
    }, [onClick]);

    const handleOnVisibleChange = useCallback((visible: boolean): void => {
        if (!visible) {
            setTitle('复制');
        }
    }, [setTitle]);

    return (
        <Tooltip title={title} onVisibleChange={handleOnVisibleChange}>
            <i className="copy-btn" onClick={handleOnClick} />
        </Tooltip>
    );
};

export default React.memo(CopyBtn);
