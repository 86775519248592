/**
 * @file 功能演示
 * @author duzhengqiang
 */




import { Button, Input, Spin, Form } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import "./style.less";
import UploadAudio from "../uploadAudio/UploadAudio";
import AudioPlayback from "../audioPlayback/AudioPlayback";
// import { divide } from 'lodash';
import { Type } from '../../../../type';
// import useCopyBtnDom from '../../../../../install/components/doc-container/components/copy-btn-dom/CopyBtnDomHooks';
const { TextArea } = Input;
const hljs = import('highlight.js');
const TextToSpeech = (props: Type) => {

    const antIcon = <LoadingOutlined style={{ fontSize: 23.71 }} spin />;
    const startBtn = useRef<any>();
    const inputRef = useRef<any>();
    const codeDomRef: any = useRef(null);
    const [copied, setCopied] = useState<boolean>(false);
    // const [valueIndex, setIndex] = useState<number>(0);
    const onCopyClick = useCallback(async () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
        copy(codeDomRef.current.innerText)
    }, [setCopied]);
    useEffect(() => {
        if (codeDomRef.current) {
            hljs.then((hl) => {
                if (codeDomRef.current) {
                    hl.highlightBlock(codeDomRef.current);
                }
            });
        }
    }, []);
    const DetectionUrl = () => {
        return (<div className={props.audioLoading ? "detectionUrl_loading" : "detectionUrl"}>
            {props.textDta.data ?
                <AudioPlayback switch={props.switch} src={props.switch === "textToSpeech" ? props.audioData.data : props.textDta.speech_url} />
                :
                <Form
                    form={props.form}
                    className="detectionUrl_text_link_box"
                >
                    <Form.Item
                        name="inputValue"
                    >
                        <Input
                            ref={inputRef}
                            className="detectionUrl_text_link"
                            bordered={false}
                        // defaultValue={"https://paddlespeech.bj.bcebos.com/PaddleAudio/zh.wav"}
                        />
                    </Form.Item>
                </Form>
            }
            <div className={
                props.textDta.data ?
                    "btn_url_loading"
                    :
                    "btn_url"
            } onClick={(e) => props.BtnSendUrl(e, inputRef)}>
                {
                    props.textDta.data ? <div className="btn_url_img" ></div> : null
                }

                {
                    props.textDta.data ?
                        "重新识别"
                        :
                        "检测URL"
                }
            </div>
        </div>)
    };

    return (
        <div className={props.hintValue ? "textToSpeech_hint_type" : "textToSpeech"}>
            {
                props.switch === "textToSpeech" ?
                    <div className="textToSpeech_choose">

                        <div className="textToSpeech_choose_btnList">

                            <div className={props.btnChinaType ? "textToSpeech_choose_english_btn" : "textToSpeech_choose_chinese_btn"}>
                                <span onClick={(e) => {
                                    props.chooseChineBtn(e, props.form)
                                }}>中文合成</span></div>
                            <div className={!(props.btnChinaType) ? "textToSpeech_choose_english_btn" : "textToSpeech_choose_chinese_btn"} >
                                <span
                                    onClick={(e) => {
                                        props.chooseChineBtn(e, props.form)
                                    }}
                                >英文合成</span></div>
                        </div>
                        <div className="textToSpeech_choose_random" onClick={() => props.getRandomChineseWord(props.form)}>点击获取随机示例</div>
                    </div>
                    :
                    <div className="textToSpeech_choose">

                        <div className="textToSpeech_choose_btnList">

                            <div className={props.btnType ? "textToSpeech_choose_english_btn" : "textToSpeech_choose_chinese_btn"}>
                                <span onClick={(e) => {
                                    props.chooseBtn(e, props.form)
                                }}>中文识别</span></div>
                            <div className={!(props.btnType) ? "textToSpeech_choose_english_btn" : "textToSpeech_choose_chinese_btn"} >
                                <span
                                    onClick={(e) => {
                                        props.chooseBtn(e, props.form)
                                    }}
                                >英文识别</span></div>
                        </div>
                        {/* <div className="textToSpeech_choose_random" onClick={() => props.getRandomChineseWord(props.form)}>点击获取随机示例</div> */}
                    </div>
            }

            {
                props.switch === "textToSpeech" ?
                    <div className="textToSpeech_content_show">
                        <Form
                            form={props.form}

                        >
                            <Form.Item
                                name="text"
                            >
                                <TextArea
                                    showCount
                                    maxLength={props.btnType ? 150 : 75}
                                    className="textToSpeech_content_show_text"
                                    bordered={false}
                                    autoSize={false}
                                ></TextArea>
                            </Form.Item>
                        </Form>
                    </div>
                    :
                    <div>
                        <DetectionUrl />
                        {props.textDta.data ?
                            <div className="textToSpeech_text"
                                ref={codeDomRef}
                            >
                                {props.textDta.data}
                                <div className="textToSpeech_text_hint_box">
                                    <div></div>
                                    <div className="voice_usedToIntroduce_context_title_context_top__box"
                                        onClick={() => {
                                            onCopyClick()
                                        }}
                                    >
                                        <div
                                            className="voice_usedToIntroduce_context_title_context_top_box_img"
                                        >
                                            {copied ?
                                                <div className='voice_usedToIntroduce_context_title_context_top_img_hint'>复制成功</div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            (props.audioLoading ? null : <UploadAudio uploadRequest={props.uploadRequest} />)
                        }
                    </div>
            }

            {
                props.switch === "textToSpeech" ?
                    <div className="textToSpeech_action">
                        <div className="textToSpeech_action_audio">
                            {
                                props.audioData.data ?
                                    <AudioPlayback switch={props.switch} src={props.switch === "textToSpeech" ? props.audioData.data : props.textDta.speech_url} />
                                    :
                                    null
                            }
                        </div>
                        <div className="textToSpeech_action_btn_box">
                            {
                                props.audioData.data ?
                                    <a href={props?.audioData?.data_download ? props?.audioData?.data_download : ""} className="textToSpeech_action_btn_a">下载音频</a>
                                    :
                                    null

                            }
                            <Button className={
                                props.audioData.data
                                    ?
                                    "textToSpeech_action_btn_loading"
                                    :
                                    "textToSpeech_action_btn"

                            }
                                ref={startBtn}
                                loading={props.textLoading}
                                onClick={() => props.startSynthesis(startBtn, props.form)}>{
                                    props.textLoading ?
                                        (props.audioData.data ? "清除内容" : "合成中")
                                        :
                                        (props.audioData.data ? "清除内容" : "开始合成")
                                }</Button>
                        </div>

                    </div>
                    :
                    null
            }
            {
                props.switch === "textToSpeech" ?

                    (props.textLoading ?
                        <div className="textToSpeech_mask">
                            {props.switch === "textToSpeech" ? null : <><Spin indicator={antIcon} ></Spin>音频处理中...</>}
                        </div> : null)

                    :
                    (props.audioLoading ?
                        <div className="textToSpeech_mask">
                            {props.switch === "textToSpeech" ? null : <><Spin indicator={antIcon} ></Spin>音频处理中...</>}
                        </div> : null)


            }
            {
                props.audioData.data && props.switch === "textToSpeech" ?
                    <div className="textToSpeech_mask_disable">

                    </div>
                    :
                    null
            }

        </div>
    )
}

export default TextToSpeech;


