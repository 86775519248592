/**
 * @file 顶部导航栏
 * @author FengGuang(fengguang01@baidu.com)
 */

import { useCallback, useEffect, useState } from 'react';

import {
    fetchGetUserState,
} from '../../api/components/headerApi';

import { IUserInfo } from './type';


// 预请求
let preRequest: any = {
    fetchGetUserState: fetchGetUserState()
};
// 检查用户信息
export const useUserLoginState = () => {
    const [userInfo, setUserInfo] = useState<IUserInfo>({});

    const getUserInfo = useCallback(async () => {
        let res;
        const fetchFunc = preRequest.fetchGetUserState || fetchGetUserState();
        delete preRequest.fetchGetUserState;
        try {
            res = await fetchFunc;
        }
        catch (err: any) {
            // ignore
        }
        if (res && res.body) {
            setUserInfo(res.body.data);
        }
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    // 登陆状态
    const checkState = useCallback(() => {
        if (userInfo.loginUrl) {
            window.location.href = userInfo.loginUrl;
            return false;
        }
        return true;
    }, [userInfo]);

    const login = useCallback(() => {
        if (userInfo.loginUrl) {
            window.location.href = userInfo.loginUrl;
            return false;
        }
        return true;
    }, [userInfo]);

    const logout = useCallback(() => {
        window.location.href = '/user/logout';
    }, []);

    return {
        userInfo,
        login,
        logout,
        checkState
    };
};

const useHeaderHooks = () => {

    const { userInfo, login, logout, checkState } = useUserLoginState();

    return {
        userInfo,
        logout,
        login,
        checkState,
    };
};

export default useHeaderHooks;
