/**
 * @file paddlehub demo ai写作面板 藏头诗
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback} from 'react';
import {IShowPaneProps} from './types';
import ShowPane from './ShowPane';

const AcrosticShowPane: React.FC<IShowPaneProps> = (props) => {
    const validate = useCallback((inputValue) => {
        if (!inputValue || !(/^[\u4e00-\u9fa5]+$/).test(inputValue) || inputValue.length === 0 || inputValue.length > 4) {
            return '格式错误，请输入1-4个关键字';
        }
    }, []);

    return (
        <ShowPane
            model="ernie_gen_acrostic_poetry"
            inputPlaceholder="请输入1-4个关键字"
            validate={validate}
            resultTransition={(result) => {
                const result2 = result?.replace(/，/g, '，/n').replace(/。/g, '。/n');
                return (
                    <React.Fragment>
                        {result2?.split(/\/n/g).map((r, index) => (
                            <p key={index}>{r}</p>
                        ))}
                    </React.Fragment>
                );
            }}
            {...props}
        />
    );
};

export default AcrosticShowPane;
