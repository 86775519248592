/**
 * @file paddlehub demo 口罩识别
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Upload, UploadProps } from 'antd';
import classNames from 'classnames';
import hljs from './highlight';
import useMaskDetectHooks from './maskDetectDemoHooks';
import { IMaskDetectDemoProps } from './types';


const MaskDetectDemo: React.FC<IMaskDetectDemoProps> = props => {
    const {
        resultImg,
        resultJson,
        imgGallery,
        imgGallerySelected,

        onImgGalleryChange,
        getMaskDetectResult
    } = useMaskDetectHooks(props);

    const contentRef = useRef<HTMLPreElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            hljs.highlightBlock(contentRef.current);
        }
    }, [resultJson]);

    const uploadRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>(
        (options) => {
            // antd 4.13.1 类型错误
            getMaskDetectResult(options.file as File);
        },
        [getMaskDetectResult]
    );

    return (
        <div className="mask-detect clear-both">
            <div className="mask-detect-right-background" />
            <div className="mask-detect-right">
                <div className="mask-detect-json">
                    <div className="mask-detect-json-title">
                        Response
                    </div>
                    <div className="mask-detect-json-body">
                        <pre
                            ref={contentRef}
                            className="mask-detect-json-body-content"
                            dangerouslySetInnerHTML={{ __html: resultJson }}
                        >
                        </pre>
                    </div>
                </div>
            </div>
            <div className="mask-detect-main">
                <div
                    className="mask-detect-img"
                    style={{ backgroundImage: `url(${resultImg})` }}
                >
                    <div className="mask-detect-img-input clear-both">
                        <div className="mask-detect-img-input-info">
                            图片文件类型支持PNG、JPG、JPEG、BMP，图片大小不超过2M。
                        </div>

                        <Upload
                            customRequest={uploadRequest}
                            showUploadList={false}
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="mask-detect-img-input-upload"
                            >
                                试试你的图片?
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="mask-detect-img-gallery">
                    <ul className="mask-detect-img-gallery-item-wrap">
                        {imgGallery.map(item => (
                            <li
                                key={item.img}
                                className={classNames(
                                    'mask-detect-img-gallery-item',
                                    {
                                        'mask-detect-img-gallery-item-selected': item.img === imgGallerySelected
                                    }
                                )}
                                style={{ backgroundImage: `url(${item.img})` }}
                                onClick={() => onImgGalleryChange(item.img)}
                            >
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MaskDetectDemo;
