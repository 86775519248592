/**
 * @file 搜索框 hook
 * @author FengGuang(fengguang01@baidu.com)
 */

import { AutoCompleteProps } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import omit from 'object.omit';
import { fetchGetHotWords, fetchGetSearchSug } from '../../api/components/headerApi';

export const useHotKeyWords = () => {
    const [hotKeyWords, setHotKeyWords] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const res = await fetchGetHotWords().catch(() => {
                // ignore
            });
            if (res) {
                const data = res.body?.result?.pageData || {};
                const hotKeyWords: any[] = Array.isArray(data.hotKeyWords) ? data.hotKeyWords : [];

                const tmpDom = document.createElement('div');

                setHotKeyWords(
                    hotKeyWords.map(i => {
                        // bca-disable-line
                        tmpDom.innerHTML = '' + i;
                        return tmpDom.innerText;
                    })
                );
            }
        })();
    }, []);

    return {
        hotKeyWords
    };
};

export const useRecentSearchWords = () => {
    const [recentSearchWords, setRecentSearchWords] = useState<string[]>(() => {
        if (window.localStorage) {
            const storage = window.localStorage;
            const recent: string[] = (() => {
                let rec = [];
                try {
                    rec = JSON.parse(storage.getItem('header-recent-search-words') || '') || [];
                } catch (err: any) {}
                return Array.isArray(rec) ? rec.map(i => '' + i) : [];
            })();
            return recent;
        }
        return [];
    });

    const addRecentSearchWords = useCallback(
        (key: string) => {
            setRecentSearchWords(old => {
                // 如果之前就输入过，则将词语从中间删除，再插入到前面
                const theWords = old.filter(i => i !== key);
                theWords.unshift(key);
                const newArr = theWords.slice(0, 5);
                if (window.localStorage) {
                    const storage = window.localStorage;
                    storage.setItem('header-recent-search-words', JSON.stringify(newArr));
                }
                return Array.from(newArr);
            });
        },
        [setRecentSearchWords]
    );

    return {
        recentSearchWords,
        addRecentSearchWords
    };
};

export const useVisible = () => {
    const [visible, setVisible] = useState<boolean>(false);

    return {
        visible,
        setVisible,
        onVisibleChange: setVisible
    };
};

export const useSearchSug = () => {
    const [searchSug, setSearchSug] = useState<NonNullable<AutoCompleteProps['options']>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onSearchChange = useMemo(() => {
        const onChange = async (value: string) => {
            setLoading(true);
            const res = await fetchGetSearchSug(value, {}).catch(() => {
                // ignore
            });
            setLoading(false);
            if (res) {
                const data = res.body?.data;
                const list = Array.isArray(data) ? data : [];

                const tmpDom = document.createElement('div');

                const theOptions = list.map(group => {
                    const theOptions: any[] = Array.isArray(group.docVo) ? group.docVo : [];

                    return {
                        label: group.searchTypeName,
                        options: theOptions.map(item => {
                            // bca-disable-line
                            tmpDom.innerHTML = item.title;
                            const title = tmpDom.innerText;

                            return {
                                key: `${title}${item.type}${item.path}`,
                                label: item.title,
                                value: `${title}-search-result-handle-${item.path}`,
                                type: item.type,
                                link: item.path,
                                compareDesc: item.pytorchCompareDesc,
                                compareHref: item.pytorchCompareHref
                            };
                        })
                    };
                });
                const theOptions2 = theOptions.filter(group => {
                    return group.options && group.options.length > 0;
                });
                setSearchSug(theOptions2);
            }
        };

        const onSearchChangeDebounce = debounce(onChange, 200);
        return (value: string) => {
            if (!value) {
                setSearchSug([]);
                return;
            }
            setLoading(true);
            onSearchChangeDebounce(value);
        };
    }, [setSearchSug, setLoading]);

    const submitSearch = useCallback((value: string, option?: NonNullable<AutoCompleteProps['options']>[number]) => {
        // 在 document 页面中从全局接收 文档参数
        const docInfo: any = {
            lang: 'zh',
            version: '2.6',
            ...(window as any).docInfo
        };

        const searchParams = omit(
            {
                q: value,
                language: docInfo.lang,
                version: docInfo.version
            },
            val => typeof val !== 'undefined'
        );

        if ((window as any)._hmt && option) {
            const docInfo = {
                lang: 'zh', // emun: zh/en
                version: '',
                ...(window as any).docInfo
            };
            (window as any)._hmt.push([
                '_trackEvent',
                '文档搜索',
                '点击sug',
                `标题"${option.title}",搜索词"${value}",语言"${docInfo.lang}",版本"${docInfo.version}"`
            ]);
        }

        if (option && option.link) {
            window.location.href = option.link;
            return;
        }

        const query = new URLSearchParams(searchParams);
        window.location.href = `/searchall?${query.toString()}`;
    }, []);

    return {
        searchSug,
        loading,
        onSearchChange,
        submitSearch
    };
};

export const useValue = () => {
    const [value, setValue] = useState<string>(() => {
        if (window.location.pathname === '/searchdoc') {
            const searchParams = new URLSearchParams(window.location.search.slice(1));
            const q = searchParams.get('q') || '';
            return q;
        }
        return '';
    });

    return {
        value,
        setValue
    };
};
