/**
 * @file paddlehub demo 新冠肺炎医疗影像 图像
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useMemo, useRef, useState} from 'react';
import {IImgItem} from './types';

const useImgHooks = () => {
    const [imgSelectedKey, setImgSelectedKey] = useState<string>('');
    const imgSelectedKeyRef = useRef<string>(imgSelectedKey);
    imgSelectedKeyRef.current = imgSelectedKey;

    const onImgSelectedChange = useCallback((key: string) => {
        if (imgSelectedKeyRef.current !== key) {
            setImgSelectedKey(key);
        }
    }, [setImgSelectedKey]);

    const [imgList, setImgList] = useState<IImgItem[]>([]);
    const [sourceImgList, setSourceImgList] = useState<IImgItem[]>([]);

    const imgSelectedIndex = useMemo(() => {
        return imgList.findIndex(item => item.key === imgSelectedKey);
    }, [imgSelectedKey, imgList]);

    const imgSelectedUrl = useMemo(() => {
        return imgList[imgSelectedIndex]?.url ?? '';
    }, [imgSelectedIndex, imgList]);

    return {
        imgSelectedIndex,
        imgSelectedUrl,
        imgSelectedKey,
        imgList,
        sourceImgList,

        onImgSelectedChange,
        setImgList,
        setSourceImgList
    };
};

export default useImgHooks;
