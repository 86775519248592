/**
 * @file paddle hub
 * 这一组接口由于不规范，所以就单独处理
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';
import { message } from 'antd';
import baseRequest from '../baseApi';
import baseRequest2 from '../baseApi2';


// 获取paddlehub首页数据
export const fetchGetIndex = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query({ requestUrl: '/hub/index' })
            .query(window.location.search.slice(1))
    );

// 获取paddlehub左侧菜单
export const fetchGetMenuList = async (params?: Object): Promise<request.Response> => {
    const res = await request.get('/paddlehub/category')
        .query({ ...params });

    const resBody = res.body;
    if (res.ok && resBody) {
        const baseResponseBody = {
            errorCode: 0,
            errorMsg: 'success',
            logId: '',
            result: {
                data: resBody.data
            },
            timestamp: Math.floor(Date.now() / 1000)
        };
        res.body = baseResponseBody;
        return res;
    }

    throw new Error('网络好像出问题了');
};

// 获取paddlehub模型列表
export interface IGetList {
    filter?: string;
    value?: string;
}

// export const fetchGetModelList = async (params?: IGetList | string): Promise<request.Response> => {
//     const res = await request.get('/paddlehub/get')
//         .query(params || '');

//     const resBody = res.body;
//     if (res.ok && resBody && resBody.status === 0) {
//         const baseResponseBody = {
//             errorCode: 0,
//             errorMsg: 'success',
//             logId: '',
//             result: {
//                 data: resBody.data
//             },
//             timestamp: Math.floor(Date.now() / 1000)
//         };
//         res.body = baseResponseBody;
//         return res;
//     }
//     else if (res.ok && resBody && resBody.status !== 0) {
//         (window as any).DEBUG && message.error('' + resBody.message);
//     }

//     throw new Error('网络好像出问题了');
// };


// 获取paddlehub预训练模型详情
export interface IGetModel {
    name?: string;
    en_category?: string;
}

export const fetchGetModelInfo = async (params?: IGetModel | string): Promise<request.Response> => {
    const res = await request.get('/paddlehub/info')
        .query(params || '');

    const resBody = res.body;
    if (res.ok && resBody && resBody.status === 0) {
        const baseResponseBody = {
            errorCode: 0,
            errorMsg: 'success',
            logId: '',
            result: {
                data: resBody.data
            },
            timestamp: Math.floor(Date.now() / 1000)
        };
        res.body = baseResponseBody;
        return res;
    }
    else if (res.ok && resBody && resBody.status !== 0) {
        (window as any).DEBUG && message.error('' + resBody.message);
    }

    throw new Error('网络好像出问题了');
};


interface IUploadModelSubmit {
    modelCategory: string;
    modelName: string;
    modelAbs: string;
    userEmail: string;
    fileKey: string;
}

export const fetchUploadModelSubmit = (params: IUploadModelSubmit): Promise<request.Response> =>
    baseRequest2(
        request.post('/user/submitModel')
            .type('form')
            .send(params)
    );


export const fetchGetProjectSInfo = (owner: string, name: string): Promise<request.Response> =>
    baseRequest2(
        request.get(`https://api.github.com/repos/${owner}/${name}`)
    );

export const fetchGetMenuList2 = () =>
    baseRequest2(
        request.get('/paddlehub/filtercondition')
    );


// 获取模型列表
interface IGetTopList {
    name?: string;
    scenes?: string;
    scheme?: string;
    transfer?: string;
    network?: string;
    dataset?: string;
    contributor?: string;
    update_time?: string;
    recommendation?: string;
    rule?: 'asc' | 'desc';
}
// 获取模型列表
export const fetchGetModelList = (
    params?: { [key: string]: any } | string,
    sortBy?: string
) => {
    let url = '/paddlehub/sortutime';
    if (sortBy === 'hot') {
        url = '/paddlehub/sorthot';
    }
    else if (sortBy === 'recom') {
        url = '/paddlehub/sortrecom';
    }
    else if (sortBy === 'utime') {
        url = '/paddlehub/sortutime';
    }
    return baseRequest2(
        request.get(url)
            .query(params || '')
    );
}

// 搜索
export const fetchGetSearchList = (
    params?: { [key: string]: any } | string,
) => {
    return baseRequest2(
        request.get('/paddlehub/searchlist')
            .query(params || '')
    );
}

// 获取右侧排行榜
interface IGetTopList {
    filter: string;
}
export const fetchGetTopList = (params: IGetTopList) =>
    baseRequest2(
        request.get('/paddlehub/sort')
            .query(params)
    );
