/**
 * @file icon卡片组件
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Col} from 'antd';

export interface IIconCard {
    iconUrl?: string;
    title?: string;
    text?: string;
}

const IconCard: React.FC<IIconCard> = (props) => {
    const {
        iconUrl,
        title,
        text
    } = props;

    return (
        <Col className="paddle-icon-card" xs={6}>
            <div className="paddle-icon-card-icon-wrap">
                <div
                    className="paddle-icon-card-icon"
                    style={{backgroundImage: `url(${iconUrl})`}}
                >
                    <img
                        alt={title}
                        src={iconUrl}
                    />
                </div>
            </div>
            <div className="paddle-icon-card-title">
                {title}
            </div>
            <div className="paddle-icon-card-text">
                {text}
            </div>
        </Col>
    );
};

export default IconCard;
