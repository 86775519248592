/**
 * @file 带图的卡片组
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import classNames from 'classnames';
import {Row} from 'antd';

import PictureCard, {IPictureCard} from './PictureCard';

interface IProps {
    className?: string;
    option: IPictureCard[];
}

const PictureCardGroup: React.FC<IProps> = props => {
    const {className, option} = props;

    return (
        <Row
            className={classNames(
                'paddle-picture-card-group-wrap',
                className
            )}
            gutter={24}
        >
            {option.map(item => (
                <PictureCard
                    key={`${item.title},${item.text}`}
                    {...item}
                />
            ))}
        </Row>
    );
};

export default PictureCardGroup;
