/**
 * @file 过滤标签中的html
 * @author FengGuang(fengguang01@baidu.com)
 */
const filteHtml = (innerHTML: string) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = innerHTML;
    return tmp.innerText;
};

export default filteHtml;
