/**
 * @file paddlehub demo 新冠肺炎医疗影像 api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';
import UploadBosClient from '../../../../utils/bos-client';
import baseRequest2 from '../../../../api/baseApi2';
import sleepPromise from '../../../../utils/sleep-promise';

interface IBosConfig {
    endpoint: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    fileKey: string;
    bucketName: string;
}

interface IUploadToBosOptions {
    file: File;
    onProgress?: (percent: number) => any;
}

// 上传到bos
export const uploadToBos = async (options: IUploadToBosOptions) => {
    // 获取config
    const res = await baseRequest2(request.get('/paddlehub/bosacl'));
    const config: IBosConfig = res.body.result || {};
    const client = new UploadBosClient({
        bucketName: config.bucketName,
        fileKey: config.fileKey,
        ak: config.accessKeyId,
        sk: config.secretAccessKey,
        sessionToken: config.sessionToken,
        endpoint: config.endpoint,

        file: options.file,
        onProgress: options.onProgress
    });
    client.start();
    return client;
};

// 提交上传结果
export const submitUploadFile = async (fileKey: string) => {
    let addFileRes = await baseRequest2(
        request.get('/paddlehub/addFile')
            .type('form')
            .query({
                fileKey
            })
    );
    const addFileRequestId = addFileRes?.body?.result?.requestId;
    if (!addFileRequestId) {
        return [addFileRes];
    }
    let getResultRes;
    const totalNum = 100000;
    for (let i = 0; i < totalNum; i++) {
        getResultRes = await baseRequest2(
            request.get('/paddlehub/getResult')
                .type('form')
                .query({
                    requestId: addFileRequestId
                })
        );
        const getResultCode = getResultRes?.body?.code;
        if (getResultCode === 400 || getResultCode === 500) {
            break;
        }
        if (getResultRes.body.code === 0 && getResultRes.body.result) {
            break;
        }
        await sleepPromise(500);
    }
    return [addFileRes, getResultRes];
};

// 获取默认数据
export const getDefaultData = async () => {
    let getResultRes;
    const totalNum = 3;
    for (let i = 0; i < totalNum; i++) {
        getResultRes = await baseRequest2(
            request.get('/paddlehub/getResult')
                .type('form')
                .query({
                    requestId: 'dcf257bc6b6f47a587f71c77aeb544e3'
                })
        );
        if (getResultRes.body.code === 0 && getResultRes.body.result) {
            break;
        }
        await sleepPromise(500);
    }
    return getResultRes;
};

