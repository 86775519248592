import { useEffect, useRef } from 'react';
import './style.less';
const Qrcode = ({ src }: { src: string }) => {
    const qrcodeRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (qrcodeRef.current) {
            let node = qrcodeRef.current.parentElement;
            if (node) {
                node.style.padding = '0px';
            };
        };
    }, [])
    return (
        <div className='qrcode' ref={qrcodeRef}>
            <img className='qrcode-img' src={src} alt="" />
        </div>
    )
};
export default Qrcode;