/**
 * @file 文字卡片组
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Row } from 'antd';

import IconTextCard from './IconTextCard';
import { IIconTextCard } from './types';

interface IProps extends IIconTextCard {
    className?: string;
}

const IconTextCardGroup: React.FC<IProps> = props => {
    const {
        className,
        children,
        list,
    } = props;

    const childRows = useMemo(() => {
        if (list?.length === 1) {
            return 24;
        }
        else if (list?.length === 2) {
            return 12;
        }
        else if (list?.length === 4) {
            return 12;
        }
        return 8;
    }, [list]);

    const hasTag = useMemo(() => {
        return list?.some((item) => !!item.tagText);
    }, [list]);

    return (
        <div
            className={classNames(
                'paddle-icon-text-card-wrap',
                className
            )}
        >
            <Row className="paddle-icon-text-card-group" gutter={[24, 24]}>
                {list?.map(item => (
                    <IconTextCard
                        key={`${item.title},${item.text}`}
                        {...item}
                        hasTag={hasTag}
                        childRows={childRows}
                    />
                ))}
                {children}
            </Row>
        </div>
    );
};

export default IconTextCardGroup;
