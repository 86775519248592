/**
 * @file paddlehub demo Hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import { useEffect, useState } from 'react';

import { IBanner, IPaddleHubDemoDataItem } from './types.d';
import { fetchGetPaddleDemoInfo } from '../../api/pages/paddleHubDemoApi';

// 从 url 中解析本页数据关联的key
const getParamsFromUrl = () => {
    const pathDetailUrl = window.location.pathname;
    const theUrlSearchs = new URLSearchParams(window.location.search.slice(1));
    const searchDetailUrl = theUrlSearchs.get('detailUrl');
    const requestUrl = searchDetailUrl || pathDetailUrl || undefined;

    return {
        requestUrl
    };
};

const usePaddleHubDemoHooks = () => {
    const [hubDemoData, setHubDemoData] = useState<IPaddleHubDemoDataItem[]>([]);
    const [bannerData, setBannerData] = useState<IBanner>({
        title: '',
        desc: '',
        btns: [],
        selector: []
    });

    useEffect(() => {
        (async () => {
            let res;
            try {
                res = await fetchGetPaddleDemoInfo(getParamsFromUrl());
            }
            catch (err: any) {
            }
            if (res) {
                const pageData = res?.body?.result?.pageData;
                const data: IPaddleHubDemoDataItem[] = Array.isArray(pageData) ? pageData : Object.values(pageData);
                const theBannerData = data.find(item => item.name === 'Banner');
                if (theBannerData && theBannerData.name === 'Banner') {
                    const b = theBannerData.options;
                    const btns: any[] = Array.isArray(b.btns) ? b.btns : [];
                    const selector: any[] = Array.isArray(b.selector) ? b.selector : [];

                    setBannerData({
                        title: b.title,
                        desc: b.desc,
                        btns: btns.map(btn => ({
                            label: btn.label,
                            href: btn.href
                        })),
                        selector: selector.map(s => ({
                            key: (new URL(s.href, window.origin)).href,
                            text: s.text,
                            href: s.href
                        }))
                    });
                }
                setHubDemoData(data.filter(item => item.name !== 'Banner'));
            }
        })();
    }, []);

    return {
        hubDemoData,
        bannerData
    };
};

export default usePaddleHubDemoHooks;
