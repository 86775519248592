/**
 * @file paddle hub 搜索排行榜列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC } from 'react';
import ChartsItem from './Chart';
import { IChartsListProps } from './types';

const ChartsList: FC<IChartsListProps> = (props) => {
    const { chartsAllList } = props;

    return (
        <div className="paddle-hub-charts-list">
            {chartsAllList.map((chart, index) => (
                <ChartsItem key={index} {...chart} />
            ))}
        </div>
    );
}

export default ChartsList;
