/**
 * @file paddlehub demo 新冠肺炎医疗影像 自定义message
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {message} from 'antd';
import {
    CheckCircleOutlined
} from '@ant-design/icons';

import {MessageApi} from 'antd/es/message';

let div: HTMLDivElement;
const messageSuccess: MessageApi['success'] = (content, duration?, onClose?) => {
    if (!div && typeof (document) === 'object' && document.body) {
        div = document.createElement('div');
        div.className = 'message-success';
        document.body.appendChild(div);
    }
    message.config({
        getContainer: () => div
    });
    const theContent =
        (typeof (content) === 'object' && !!(content as any).content)
            ? content
            : {content};
    const theContent2 = {
        icon: <CheckCircleOutlined />,
        ...theContent
    };
    return message.success(theContent2, duration, onClose);
};

export default messageSuccess;
