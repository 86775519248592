/**
 * @file paddle 开发者社区请求方法
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
import request from 'superagent';
import baseRequest from '../baseApi';


// 获取落地页详情
export interface IGetCommunityParam {
    requestUrl?: string;
}

export const fetGetchCommunityInfo = (params: IGetCommunityParam | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query(params)
            .query(window.location.search.slice(1))
    );