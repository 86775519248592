/**
 * @file 用于将大文件分段上传至bos的client
 * @author zhangwenxi(zhangwenxi@baidu.com)
 */

import async from 'async';
import {BosClient} from '@baiducloud/sdk/dist/baidubce-sdk.bundle.min';

const PART_SIZE = 2 * 1024 * 1024;
const THREADS = 3;


interface IBosClientConfig {
    ak: string; // STS服务器下发的临时ak
    sk: string; // STS服务器下发的临时sk
    bucketName: string;
    fileKey: string;
    sessionToken?: string;  // STS服务器下发的sessionToken
    endpoint?: string;
    file: File;
    onProgress?: (percent: number) => any;
}

interface ITask {
    file: File;
    uploadId: string;
    bucketName: string;
    key: string;
    partNumber: number;
    partSize: number;
    start: number;
    stop: number;
}

interface IBosUploadResponse {
    [key: string]: any
}

export default class UploadBosClient {
    client: any;

    fileKey: string = '';
    bucketName: string = '';
    uploadId: string = '';
    uploadTask: Promise<any> | undefined;

    file: File;

    // 本次任务已经放弃
    isAbort: boolean = false;

    loadedBytes: number = 0;

    onProgress?: (percent: number) => any;

    constructor(config: IBosClientConfig) {
        this.bucketName = config.bucketName || '';
        this.fileKey = config.fileKey;
        this.file = config.file;

        if (typeof (config.onProgress) === 'function') {
            this.onProgress = config.onProgress;
        }

        this.client = new BosClient({
            endpoint: config.endpoint,
            credentials: {
                ak: config.ak,
                sk: config.sk
            },
            sessionToken: config.sessionToken
        });
    }

    // 启动上传任务。如果任务已经启动过了就返回之前的结果
    start() {
        if (!this.uploadTask) {
            this.uploadTask = this.startUpload(this.file);
        }
        return this.uploadTask;
    }

    // 放弃上传，并阻止上传队列
    abort() {
        this.isAbort = true;
        this.client.abortMultipartUpload(this.bucketName, this.fileKey, this.uploadId);
    }

    private async startUpload(file: File) {
        if (this.uploadId) {
            return;
        }
        const client = this.client;

        if (this.onProgress) {
            // 监听上传进度事件
            this.client.on(
                'progress',
                (event: any) => {
                    const {loaded, total, lengthComputable} = event;

                    if (lengthComputable) {
                        if ((loaded / total) === 1) {
                            this.loadedBytes += total;
                        }
                    }
                    else {
                        return;
                    }

                    let percent = (this.loadedBytes / this.file!.size) * 100;

                    if (percent > 100) {
                        percent = 100;
                    }
                    else if (percent <= 0) {
                        percent = 0;
                    }

                    this.onProgress && this.onProgress(percent);
                }
            );
        }

        const initUploadResponse = await this.client.initiateMultipartUpload(this.bucketName, this.fileKey, {});
        const uploadId = initUploadResponse.body.uploadId;
        this.uploadId = uploadId;
        const tasks = this.getTasks(file, uploadId, this.bucketName, this.fileKey);

        const state = {
            lengthComputable: true,
            loaded: 0,
            total: tasks.length
        };


        const allResponse = await async.mapLimit<ITask, IBosUploadResponse>(
            tasks,
            THREADS,
            async (task, callback) => {
                if (this.isAbort) {
                    callback(null);
                    return;
                }
                try {
                    const blob = task.file.slice(task.start, task.stop + 1);
                    const res = await client.uploadPartFromBlob(
                        task.bucketName,
                        task.key,
                        task.uploadId,
                        task.partNumber,
                        task.partSize,
                        blob
                    );
                    ++state.loaded;
                    callback(null, res);
                }
                catch (err: any) {
                    callback(err);
                }
            }
        );

        if (this.isAbort) {
            return;
        }

        const partList = allResponse.map((response, index) => ({
            partNumber: index + 1,
            eTag: response.http_headers.etag
        }));

        return client.completeMultipartUpload(
            this.bucketName,
            this.fileKey,
            uploadId,
            partList
        );
    }

    private getTasks(file: File, uploadId: string, bucketName: string, fileKey: string) {
        let leftSize = file.size;
        let offset = 0;
        let partNumber = 1;

        let tasks: ITask[] = [];

        while (leftSize > 0) {
            let partSize = Math.min(leftSize, PART_SIZE);
            tasks.push({
                file: file,
                uploadId: uploadId,
                bucketName: bucketName,
                key: fileKey,
                partNumber: partNumber,
                partSize: partSize,
                start: offset,
                stop: offset + partSize - 1
            });

            leftSize -= partSize;
            offset += partSize;
            partNumber += 1;
        }
        return tasks;
    }
}
