/**
 * @file 语音演示首页
 * @author duzhengqiang
 */
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import VoiceHeader from './components/voiceHeader/VoiceHeader';
import FunctionalDemo from './components/functionalDemo/FunctionalDemo';
import UsedToIntroduce from './components/usedToIntroduce/UsedToIntroduce';
import useVoiceDemo from './useVoiceDemo';

// import AudioHooks from './components/functionalDemo/components/audioHooks/AudioHooks';

const VoiceDemo = () => {
    const antIcon = <LoadingOutlined className="loadingType" spin />
    const {
        voiceData,
        btnType,
        functionTextValue,
        chooseBtn,
        getRandomChineseWord,
        textOnchange,
        startSynthesis,
        textLoading,
        textDta,
        hintValue,
        audioData,
        BtnSendUrl,
        audioLoading,
        uploadRequest,
        form,
        TabsOnChange,
        chooseChineBtn,
        btnChinaType
    } = useVoiceDemo();
    return (
        <>
            <div className="viocedemo">
                <Header />

                {voiceData.paddleVoiceHeader.VoiceHeaderContent ?
                    <div>
                        <VoiceHeader {...voiceData.paddleVoiceHeader} />
                        <FunctionalDemo
                            btnType={btnType}
                            functionTextValue={functionTextValue}
                            chooseBtn={chooseBtn}
                            getRandomChineseWord={getRandomChineseWord}
                            textOnchange={textOnchange}
                            startSynthesis={startSynthesis}
                            textLoading={textLoading}
                            textDta={textDta}
                            hintValue={hintValue}
                            audioData={audioData}
                            BtnSendUrl={BtnSendUrl}
                            audioLoading={audioLoading}
                            uploadRequest={uploadRequest} 
                            form={form}
                            TabsOnChange={TabsOnChange}
                            chooseChineBtn={chooseChineBtn}
                            btnChinaType={btnChinaType}
                        />
                        <UsedToIntroduce {...voiceData.paddleVoiceUsedToIntroduce} />
                    </div>
                    :
                   <div className="initLoadingBox">
                        <Spin indicator={antIcon} />
                   </div>
                }
                <Footer />
            </div>
        </>
    )
};

export default VoiceDemo;
