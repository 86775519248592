/**
 * @file 核心优势
 * @author v_duzhengqiang
 */
import { RootObject, Content2 } from '../../types';

import './style.less';

const CoreAdvantage = (props: RootObject["coreAdvantage"]) => {
  const {
    title,
    content
  } = props;
  return (
    <div className='p-x-ai-core-advantage'>

      <div className='p-x-ai-core-advantage-title'>
        {
          title ?? ''
        }
      </div>
      <div className='p-x-ai-core-advantage-modules'>
        {
          content.map((item: Content2, index: number) => {
            return (
              <div className='p-x-ai-core-advantage-module' key={index}>
                <div className='p-x-ai-core-advantage-module-img'>
                  <img className="p-x-ai-core-advantage-module-img-box" src={item.titleImg} alt="" />
                </div>
                <div className='p-x-ai-core-advantage-module-title'>
                  {item.title ?? ''}
                </div>
                <div className='p-x-ai-core-advantage-module-desc'>
                  {item.desc ?? ''}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CoreAdvantage;