/**
 * @file paddlehub demo 新冠肺炎医疗影像 上传组件
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useRef, useState} from 'react';
import {UploadProps} from 'antd';
import {UploadChangeParam, UploadFile} from 'antd/es/upload/interface';

import {uploadToBos} from '../../medicalImageApi';
import UploadBosClient from '../../../../../../utils/bos-client';

const useUploadHooks = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const loadingRef = useRef<boolean>(loading);
    loadingRef.current = loading;

    // 上传到bos
    const customRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>((options) => {
        const {
            file,
            onError,
            onProgress,
            onSuccess
        } = options;

        let client: UploadBosClient | undefined = undefined;
        (async () => {
            try {
                client = await uploadToBos({
                    // antd 4.13.1 类型错误
                    file: file as File,
                    // @ts-ignore
                    onProgress: percent => onProgress && onProgress({percent})
                });
                if (!client) {
                    throw new Error('create client Error');
                }
                const res = await client.start();
                // @ts-ignore
                onSuccess(res, file);
            }
            catch (err: any) {
                // @ts-ignore
                onError(err);
            }
        })();
        return {
            abort() {
                if (client) {
                    client.abort();
                }
            }
        };
    }, []);

    const onChange = useCallback((info: UploadChangeParam) => {
        const {file, fileList} = info;
        setFileList(fileList);
        if (file.status === 'uploading') {
            setLoading(true);
        }
        else if (file.status === 'done') {
            const uploadingList = fileList.filter(item => item.status === 'uploading');
            if (uploadingList.length === 0) {
                setLoading(false);
            }
        }
    }, [setFileList]);

    const beforeUpload = useCallback(() => {
        return false;
    }, []);

    return {
        loading,
        fileList,
        customRequest,
        onChange,
        beforeUpload
    };
};

export default useUploadHooks;
