/**
 * @file 垂直tab+列表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useEffect, useMemo} from 'react';
import classNames from 'classnames';
import {IVerticalTabsListGroupItem} from './types';
import useVerticalTabsListHooks from './verticalTabsListHooks';
import MarkdownContainer from '../../markdown-container/MarkdownContainer';
import A from '../../a/A';

interface IVerticalTabsListProps {
    group: IVerticalTabsListGroupItem[];
}

const VerticalTabsList: React.FC<IVerticalTabsListProps> = props => {
    const {group} = props;

    const {
        selectedTabKey,
        isFirstLoad,
        setSelectedTabKey
    } = useVerticalTabsListHooks();

    const showList = useMemo(() => {
        const findItem = group.find(item => item.title === selectedTabKey);
        if (findItem) {
            return findItem.list;
        }
        return [];
    }, [group, selectedTabKey]);

    useEffect(() => {
        if (isFirstLoad.current && !selectedTabKey && group.length > 0) {
            isFirstLoad.current = false;
            setSelectedTabKey(group[0].title);
        }
    }, [selectedTabKey, group, isFirstLoad, setSelectedTabKey]);


    return (
        <div className="paddle-vertical-tabs-list clear-both">
            <div className="paddle-vertical-tabs-list-menu">
                {group.map(tab => (
                    <div
                        key={tab.title}
                        className={classNames(
                            'paddle-vertical-tabs-list-menu-item',
                            {'paddle-vertical-tabs-list-menu-item-selected': tab.title === selectedTabKey}
                        )}
                        onClick={() => setSelectedTabKey(tab.title)}
                        onMouseEnter={() => setSelectedTabKey(tab.title)}
                    >
                        {tab.title}
                    </div>
                ))}
            </div>
            <div className="paddle-vertical-tabs-list-menu-background" />
            <div className="paddle-vertical-tabs-list-body">
                {showList.map(item => (
                    <div key={item.title} className="paddle-vertical-tabs-list-item">
                        <div className="paddle-vertical-tabs-list-item-img">
                            <img alt={item.title} src={item.imgUrl} />
                        </div>
                        <div className="paddle-vertical-tabs-list-item-body">
                            <div className="paddle-vertical-tabs-list-item-title">
                                {item.title}
                            </div>
                            <div className="paddle-vertical-tabs-list-item-context">
                                <table className="paddle-vertical-tabs-list-item-context-form">
                                    <tbody>
                                    {(Array.isArray(item.desc) ? item.desc : []).map((descItem, index) => (
                                        <tr key={index}>
                                            <td className="paddle-vertical-tabs-list-item-context-form-label">{descItem.title}</td>
                                            <td>
                                                <MarkdownContainer source={descItem.text} />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="paddle-vertical-tabs-list-item-tags">
                                {(Array.isArray(item.btns) ? item.btns : []).map(btn => (
                                    <A
                                        key={btn.label}
                                        className={classNames(
                                            'paddle-vertical-tabs-list-item-tag',
                                            {'paddle-vertical-tabs-list-item-tag-disabled': !btn.href}
                                        )}
                                        href={btn.href}
                                        target={btn.target}
                                    >
                                        {btn.label}
                                    </A>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VerticalTabsList;
