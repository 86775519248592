/**
 * @file paddlehub 通用图片 demo 折叠面板，自动展开
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useState} from 'react';
import {CollapseProps} from 'antd/es/collapse';
import {Collapse} from 'antd';

const PCollapse: React.FC<CollapseProps> = props => {
    const [activeKeyState, setActiveKeyState] = useState<CollapseProps['activeKey']>(() => {
        if (props.defaultActiveKey !== undefined) {
            return props.defaultActiveKey;
        }
        return [];
    });

    const onChange = useCallback<(key: string | string[]) => void>((keys) => {
        if (Array.isArray(keys) && keys.length === 0) {
        }
        else if (!Array.isArray(keys) && !keys) {
        }
        else {
            setActiveKeyState(keys);
        }
    }, [setActiveKeyState]);

    return (
        <Collapse
            activeKey={activeKeyState}
            onChange={onChange}
            {...props}
        />
    );
};

export default PCollapse;
