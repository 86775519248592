/**
 * @file paddlehub 通用图片 demo
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Checkbox, Col, Row, Tooltip, Upload, UploadProps } from 'antd';
import usePictureDemoHooks from './pictureTransHooks';
import { IPictureDemoProps } from './types';
import useMouseDragHook from '../mouseDragHook';
import useOnResize from '../onResizeHook';

import classNames from 'classnames';


const PictureTrans: React.FC<IPictureDemoProps> = (props) => {
    const {
        imgGallery,
        imgSelectedItem,
        modelSelect,
        modelSelectStr,
        draggableTipShow,
        imgGallerySelected,
        hideDraggableTipShow,
        uploadImgErrStr,
        onModelSelectChange,
        onImgGalleryChange,
        onImgUpload
    } = usePictureDemoHooks(props);

    const visible = useMemo(() => {
        return props.visible === undefined ? true : props.visible;
    }, [props.visible]);

    const uploadPictureRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>(
        (options) => {
            // antd 4.13.1 类型错误
            if ((options.file as File).size < 50 * 1024 * 1024) {
                onImgUpload(options.file as File);
            }
        },
        [onImgUpload]
    );


    const rightImgWrapRef = useRef<HTMLDivElement>(null);
    const [draggableWrapRef, lineRef] = useMouseDragHook<HTMLDivElement>({
        onDrag: (params) => {
            if (lineRef.current && rightImgWrapRef.current) {
                const wrapWidth = draggableWrapRef.current?.offsetWidth ?? 0;
                const left = params.endX - params.startTargetX;
                const left2 = Math.max(5, Math.min(wrapWidth - 5, left));
                const leftPx = `${left2}px`;
                lineRef.current.style.left = leftPx;
                rightImgWrapRef.current.style.left = leftPx;
            }
        }
    });
    const rightImgRef = useRef<HTMLDivElement>(null);
    useOnResize({
        onResize: () => {
            if (draggableWrapRef.current && rightImgRef.current) {
                rightImgRef.current.style.width = `${draggableWrapRef.current.clientWidth}px`;
            }
        }
    });

    const img = imgSelectedItem?.img;
    const resImg = imgSelectedItem?.imgRes?.[modelSelectStr];


    const checkboxChange = (value: (string | number | boolean)[]) => {
        // 将一个多选改造为一个单选
        if (!value.length) {
            return;
        }
        const radioValue = value.filter((item) => !modelSelect.includes(item as string));
        onModelSelectChange(radioValue);
    }

    return (
        <div className="paddlegan-picture-demo clear-both">
            <div className="paddlegan-picture-demo-main">
                <div
                    className="paddlegan-picture-demo-img"
                    style={{ backgroundImage: img ? `url(${img})` : undefined }}
                    ref={draggableWrapRef}
                >
                    {imgSelectedItem?.key === '' && !!uploadImgErrStr
                        && (
                            <div className="paddlegan-picture-demo-img-uploadimgerr-wrap">
                                <div className="paddlegan-picture-demo-img-uploadimgerr">
                                    <div className="paddlegan-picture-demo-img-uploadimgerr-icon" />
                                    <div className="paddlegan-picture-demo-img-uploadimgerr-text">
                                        {uploadImgErrStr}
                                    </div>
                                </div>
                            </div>
                        )}
                    <div
                        className="paddlegan-picture-demo-img-right-wrap"
                        style={{ display: resImg ? undefined : 'none' }}
                        ref={rightImgWrapRef}
                        onClick={hideDraggableTipShow}
                        onMouseDown={hideDraggableTipShow}
                    >
                        <div
                            ref={rightImgRef}
                            className="paddlegan-picture-demo-img-right"
                            style={{ backgroundImage: resImg ? `url(${resImg})` : undefined }}
                        />
                    </div>
                    {!!imgSelectedItem?.loading
                        && (
                            <div className="paddlegan-picture-demo-img-loading-wrap">
                                <div className="paddlegan-picture-demo-img-loading" />
                            </div>
                        )}
                    <div
                        ref={lineRef}
                        className="paddlegan-picture-demo-img-center-line"
                        onClick={hideDraggableTipShow}
                        style={{ display: resImg ? undefined : 'none' }}
                    >
                        <Tooltip
                            placement="right"
                            title="拖动试试，优化前后差异很大哦"
                            visible={draggableTipShow && visible && (!!resImg)}
                        >
                            <div
                                className="paddlegan-picture-demo-img-center-line-handicon"
                                onClick={hideDraggableTipShow}
                                onMouseDown={hideDraggableTipShow}
                            />
                        </Tooltip>
                    </div>

                    <div
                        className="paddlegan-picture-demo-img-tag paddlegan-picture-demo-img-tag-left"
                        style={{ display: resImg ? undefined : 'none' }}
                    >
                        渲染前
                    </div>
                    <div
                        className="paddlegan-picture-demo-img-tag paddlegan-picture-demo-img-tag-right"
                        style={{ display: resImg ? undefined : 'none' }}
                    >
                        渲染后
                    </div>
                    <div className="paddlegan-picture-demo-img-input clear-both">
                        <div className="paddlegan-picture-demo-img-input-info">
                            图片文件类型支持PNG、JPG、JPEG、BMP，图片尺寸不超过800*800像素。
                            <div>上PaddleGAN Github官网即可获取老照片和视频修复的完整代码，欢迎大家尝试。</div>
                        </div>

                        <Upload
                            customRequest={uploadPictureRequest}
                            showUploadList={false}
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="paddlegan-picture-demo-img-input-upload"
                                loading={!imgSelectedItem?.key && imgSelectedItem?.loading}
                            >
                                本地上传
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="paddlegan-picture-demo-img-gallery">
                    <ul className="paddlegan-picture-demo-img-gallery-item-wrap">
                        {imgGallery.map(item => (
                            <li
                                key={item.img}
                                className={classNames(
                                    'paddlegan-picture-demo-img-gallery-item',
                                    {
                                        'paddlegan-picture-demo-img-gallery-item-selected': item.img === imgGallerySelected
                                    }
                                )}
                                style={{ backgroundImage: `url(${item.img})` }}
                                onClick={() => onImgGalleryChange(item.img)}
                            >
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="paddlegan-picture-demo-right">
                <div className="paddlegan-picture-demo-collapse">
                    <div className="paddlegan-picture-demo-collapse-title">
                        选择风格
                    </div>
                    <div className="paddlegan-picture-demo-collapse-body">
                        <Checkbox.Group value={modelSelect} onChange={checkboxChange}>
                            <Row>
                                <Col xs={24}>
                                    <Checkbox className="paddlegan-picture-demo-checkbox" value="StarrySky">梵高星空</Checkbox>
                                </Col>
                                <Col xs={24}>
                                    <Checkbox className="paddlegan-picture-demo-checkbox" value="Start">宇宙星辰</Checkbox>
                                </Col>
                                <Col xs={24}>
                                    <Checkbox className="paddlegan-picture-demo-checkbox" value="Sea">浩瀚大海</Checkbox>
                                </Col>
                                <Col xs={24}>
                                    <Checkbox className="paddlegan-picture-demo-checkbox" value="Circuit">复古电路</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PictureTrans;
