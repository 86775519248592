/**
 * @file paddlehub demo 词法分析
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import LexicalDemoInput from './components/lexical-input/LexicalDemoInput';
import {Form} from 'antd';
import TextResult from './components/text-result/TextResult';
import useTextDemoHooks from './textDemoHooks';
import {IDemoProps} from '../../types';
import ModelInfo from './components/model-info/ModelInfo';


const TextDemo: React.FC<IDemoProps> = props => {
    const {
        inputForm,
        modelList,
        frameVersion,
        devResource,
        wordResultRs,
        selectedWordResultRs,
        errorText,
        isInputEmpty,
        wordTypeList,
        selectedWordType,
        wordResultFilterByWordTypeList,
        onWordDetailPrevClick,
        onWordDetailNextClick,

        onWordResultRsChange,
        onWordTypeChange,
        onSubmitText,
        onRandomClick
    } = useTextDemoHooks(props);

    return (
        <div className="lexical-demo">
            <div className="lexical-demo-input-label">
                <span className="lexical-demo-input-label-title">
                    请输入一段想分析的文本：
                </span>
                <span
                    className="lexical-demo-input-label-link"
                    onClick={onRandomClick}
                >
                    随机示例
                </span>
            </div>
            <Form form={inputForm} onValuesChange={onSubmitText}>
                <div className="lexical-demo-input-wrap">
                    <Form.Item
                        noStyle
                        name="text"
                    >
                        <LexicalDemoInput
                            maxLength={150}
                        />
                    </Form.Item>
                </div>
                <ModelInfo
                    modelList={modelList}
                    frameVersion={frameVersion}
                    devResource={devResource}
                />
            </Form>
            {!isInputEmpty && !errorText
            && (
                <div>
                    <div className="lexical-demo-result">
                        分析结果
                    </div>
                    <TextResult
                        wordResultRs={wordResultRs}
                        selectedWordResultRs={selectedWordResultRs}
                        onWordResultRsChange={onWordResultRsChange}
                        wordTypeList={wordTypeList}
                        selectedWordType={selectedWordType}
                        wordResultFilterByWordTypeList={wordResultFilterByWordTypeList}
                        onWordTypeChange={onWordTypeChange}
                        onWordDetailPrevClick={onWordDetailPrevClick}
                        onWordDetailNextClick={onWordDetailNextClick}
                    />
                </div>
            )}
            {!isInputEmpty && !!errorText
            && (
                <div className="lexical-demo-result-err">
                    {errorText}
                </div>
            )}
        </div>
    );
};

export default TextDemo;
