/**
 * @file 顶部导航栏的数据
 * @author FengGuang(fengguang01@baidu.com)
 */
import UrlPattern from 'url-pattern';
export interface IMenuDataTagsItem {
    color: 'red' | 'green' | 'blue';
    text: string;
}

export interface IMenuDataItem {
    key: string;
    title: string;
    url?: string;
    urlPattern?: string | string[] | undefined;
    urlPatternExclude?: string | string[];
    tags?: IMenuDataTagsItem[];
    target?: string | boolean;
    children?: IMenuDataItem[];
}

// 根据 url 设置唯一key
export const setKeyFromObj = (item: IMenuDataItem[]): IMenuDataItem[] => {
    return item.map((subItem, index) => {
        if (!subItem.key && subItem.url) {
            subItem.key = subItem.url;
        } else if (!subItem.key && subItem.title) {
            subItem.key = `$${subItem.title}`;
        } else if (!subItem.key) {
            subItem.key = `$${index}`;
        }
        if (subItem.children) {
            subItem.children = setKeyFromObj(subItem.children);
        }
        return subItem;
    });
};

export const menuRightData: IMenuDataItem[] = setKeyFromObj([
    {
        key: '',
        title: '论坛',
        url: 'http://discuss.paddlepaddle.org.cn',
        target: '_blank'
    },
    {
        key: '',
        title: 'GitHub',
        url: 'https://github.com/paddlepaddle/paddle',
        target: '_blank'
    }
]);

// 遍历元素找到当前页面 url 匹配的选项
export const findMenu = (
    theMenuData: IMenuDataItem[],
    targetUrl: string = window.location.href.slice(window.location.origin.length)
): IMenuDataItem[] => {
    let resItem: IMenuDataItem[] = [];
    theMenuData.some(item => {
        // 先匹配 urlPatternExclude 如果匹配上说明这条不是我们需要的记录
        let urlPatternExclude: string[] = [];
        if (Array.isArray(item.urlPatternExclude)) {
            urlPatternExclude = item.urlPatternExclude;
        } else if (typeof item.urlPatternExclude === 'string') {
            urlPatternExclude = [item.urlPatternExclude];
        }
        const patternExcludeMatch = urlPatternExclude.some(urlpatt => !!new UrlPattern(urlpatt).match(targetUrl));
        if (patternExcludeMatch) {
            return false;
        }

        // 再匹配 urlExclude，如果匹配上说明这是我们需要的记录
        let urlPattern: string[] = [];
        if (Array.isArray(item.urlPattern) && !!item.urlPattern.length) {
            urlPattern = item.urlPattern;
        } else if (typeof item.urlPattern === 'string') {
            urlPattern = [item.urlPattern];
        }

        const patternMatch = urlPattern.some(urlpatt => {
            if (new UrlPattern(urlpatt).match(targetUrl)) {
                resItem.push(item);
                return true;
            }
            return false;
        });
        if (patternMatch) {
            return true;
        }

        // 如果都没匹配到，则去递归遍历子节点
        if (item.children) {
            const subResItem = findMenu(item.children, targetUrl);
            if (subResItem.length) {
                resItem.push(item);
                subResItem.forEach(childItem => {
                    resItem.push(childItem);
                });
                return true;
            }
        }
        return false;
    });
    return resItem;
};
