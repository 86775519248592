/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {isValidElement, useCallback, useRef, useState} from 'react';
import {Modal} from 'antd';
import classNames from 'classnames';
import Video from './Video';

interface IVideoModalPlayerProps {
    className?: string;
    src?: string;
    trigger?: 'onClick' | 'onMouseEnter';
    visible?: boolean;
    onVisibleChange?: (value: boolean) => void;
}

const VideoModal: React.FC<IVideoModalPlayerProps> = props => {
    const {
        children,
        className,
        src,
        visible,
        onVisibleChange
    } = props;
    const [_modalVisible, setModalVisible] = useState<boolean>(false);
    const modalVisible = ('visible' in props) ? visible : _modalVisible;

    const videoRef = useRef<HTMLVideoElement>(null);
    const trigger = props.trigger || 'onClick';

    const onStart = useCallback(() => {
        onVisibleChange && onVisibleChange(true);
        setModalVisible(true);
    }, [onVisibleChange, setModalVisible]);

    const onStop = useCallback(() => {
        onVisibleChange && onVisibleChange(false);
        setModalVisible(false);
    }, [onVisibleChange, setModalVisible]);

    const onTrigger = useCallback(() => {
        setModalVisible(old => {
            onVisibleChange && onVisibleChange(!old);
            return !old;
        });
    }, [onVisibleChange, setModalVisible]);

    const childrenAddProps: any = {};
    if (trigger === 'onClick') {
        childrenAddProps.onClick = onTrigger;
    }
    else if (trigger === 'onMouseEnter') {
        childrenAddProps.onMouseEnter = onStart;
        childrenAddProps.onMouseEnter = onStop;
    }

    const isChildrenEmpty = children === undefined || children === null;

    return (
        <React.Fragment>
            {!isChildrenEmpty && isValidElement(children)
            && React.cloneElement(children, childrenAddProps)
            }
            {!isChildrenEmpty && !isValidElement(children)
            && <span {...childrenAddProps}>{children}</span>
            }
            <Modal
                wrapClassName="paddle-video-modal-wrap"
                className={classNames(
                    'paddle-video-modal',
                    className
                )}
                closable={true}
                visible={modalVisible}
                onOk={onTrigger}
                onCancel={onTrigger}
                footer={null}
                width="100%"
                destroyOnClose
            >
                <div className="paddle-video-modal-player-wrap">
                    <div className="paddle-video-modal-player-inner">
                        <Video
                            className="paddle-video-modal-player"
                            preload="auto"
                            autoPlay
                            controls
                            ref={videoRef}
                            src={src}
                        />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default VideoModal;
