/* eslint-disable array-callback-return */
/**
 * @file 使用介绍
 * @author duzhengqiang
 */

import "./style.less";
import { PaddleVoiceUsedToIntroduce, UsedToIntroduceVersionList } from '../../type';
import copy from 'copy-text-to-clipboard';
// import useCopyBtnDom from "../../../install/components/doc-container/components/copy-btn-dom/CopyBtnDomHooks";
import { useCallback, useEffect, useRef, useState } from "react";
const hljs = import('highlight.js');
const UsedToIntroduce = (props: PaddleVoiceUsedToIntroduce) => {
    const codeDomRef: any = useRef(null);
    const [copied, setCopied] = useState<boolean>(false);
    const [valueIndex, setIndex] = useState<number>(0);
    const delHtmlTag = (str: any) => {
        return str.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, '')
    };
    const onCopyClick = useCallback(async (value: string, index: number) => {
        await setIndex(index);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
        copy(delHtmlTag(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.UsedToIntroduceVersionList, setCopied]);
    useEffect(() => {
        if (codeDomRef.current) {
            hljs.then((hl) => {
                if (codeDomRef.current) {
                    hl.highlightBlock(codeDomRef.current);
                }
            });
        }
    }, []);
    return (
        <div className="voice_usedToIntroduce">
            <div className="voice_usedToIntroduce_title">{props?.UsedToIntroduceTitle ?? ""}</div>
            {
                props?.UsedToIntroduceVersionList[0]?.title !== "推荐版本" ?

                    null
                    :
                    <div className="voice_usedToIntroduce_context_version">
                        <div className="voice_usedToIntroduce_version_context_title">
                            {props?.UsedToIntroduceVersionList[0]?.title ?? ""}
                        </div>
                        <div className="voice_usedToIntroduce_version_context_title_context"
                            {...{ dangerouslySetInnerHTML: { __html: props?.UsedToIntroduceVersionList[0]?.partnerList } }}
                        >
                        </div>
                    </div>
            }

            {props.UsedToIntroduceVersionList.map((value: UsedToIntroduceVersionList, index: number) => {
                if (value.title !== "推荐版本") {
                    return (
                        <div className="voice_usedToIntroduce_context">
                            <div className="voice_usedToIntroduce_context_title">{value.title ?? ""}</div>
                            <div className="voice_usedToIntroduce_context_title_context"
                            //  {...{ dangerouslySetInnerHTML: { __html: value.partnerList } }}                        
                            >
                                <div className="voice_usedToIntroduce_context_title_context_top">
                                    <div>{value.subtitle ?? ""}</div>
                                    <div className="voice_usedToIntroduce_context_title_context_top__box">
                                        <div className="voice_usedToIntroduce_context_title_context_top_box_img" onClick={(e) => {
                                            onCopyClick(value.partnerList, index)
                                        }}>
                                            {copied && (valueIndex === index) ?
                                                <div className='voice_usedToIntroduce_context_title_context_top_img_hint'>复制成功</div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div ref={codeDomRef} className="usedToIntroduce_ulBox"  {...{ dangerouslySetInnerHTML: { __html: value.partnerList } }}></div>
                            </div>
                        </div>
                    )
                };

            })}
        </div>
    )
}

export default UsedToIntroduce;
