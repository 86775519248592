
// 头部简介
import { Button } from 'antd';
import { RootObject, Content } from '../../types';
import './style.less';
const HeaderIntro = (props: RootObject["headerIntro"]) => {
  const {
    title,
    desc,
    content
  } = props;
  return (
    <div className='p-x-ai-header-intro'>
      <div className='p-x-ai-header-intro-title'>
        {
          title ?? ''
        }
      </div>
      <div className='p-x-ai-header-intro-desc'>
        {
          desc ?? ''
        }
      </div>
      <div className='p-x-ai-header-intro-btn-List'>
        {
          content.map((e: Content, index: number) => {
            return <Button
              disabled={e.link ? false : true}
              className="p-x-ai-header-intro-btn"
              type="primary"
              key={index}
            >
              <a href={e.link ? e.link : ''}> {e.title}</a>
            </Button>
          })
        }
      </div>
    </div>
  )
}

export default HeaderIntro;