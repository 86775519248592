/**
 * @file paddlehub demo ai写作面板
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Button, Input} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';

import {IShowPaneProps} from './types';
import TagItem from '../tag-item/TagItem';
import useShowPaneHooks from './showPaneHooks';

const ShowPane: React.FC<IShowPaneProps> = (props) => {
    const {
        examples,
        value,
        inputValue,
        resultList,
        showResultIndex,
        loading,
        errorInfo,

        resultTransition,

        onTabChange,
        onInputChange,
        onBtnClick,
        onSwitchClick
    } = useShowPaneHooks(props);

    const {
        inputPlaceholder
    } = props;

    return (
        <div className="ai-writing-show-pane">
            <div className="ai-writing-show-pane-exampletag">
                <div className="ai-writing-show-pane-exampletag-label">
                    热门：
                </div>
                <div className="ai-writing-show-pane-exampletag-right">
                    {examples.map((item, index) => (
                        <TagItem
                            key={index}
                            checked={item === value}
                            onChange={() => onTabChange(item)}
                        >
                            {item}
                        </TagItem>
                    ))}
                </div>
            </div>
            <div className="ai-writing-show-pane-form">
                <Input
                    className="ai-writing-show-pane-input"
                    value={inputValue}
                    onChange={(event) => onInputChange(event.target.value)}
                    placeholder={inputPlaceholder}
                    onPressEnter={onBtnClick}
                />
                <Button
                    type="primary"
                    onClick={onBtnClick}
                    loading={loading}
                >
                    生成
                </Button>
                <div
                    className="ai-writing-show-pane-switch-btn"
                    onClick={onSwitchClick}
                    style={{display: resultList.length <= 1 ? 'none' : undefined}}
                >
                    <ReloadOutlined />
                    换一换
                </div>
            </div>
            <div className="ai-writing-show-pane-errorinfo">
                {errorInfo}
            </div>

            <div className="ai-writing-show-pane-result-wrap">
                <div className="ai-writing-show-pane-result">
                    <div className="ai-writing-show-pane-result-inner">
                        {resultTransition(resultList[showResultIndex])}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowPane;
