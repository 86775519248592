/**
 * @file 排序tag，
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useLatest } from 'react-use';
import SorterIcon from './SorterIcon';
import SorterIconDown from './SorterIconDown';
import SorterIconUp from './SorterIconUp';
import { ISorterProps } from './types';

const Sorter: FC<ISorterProps> = (props) => {
    const {
        className
    } = props;

    const sortIcon = useMemo(() => {
        return props.sortIcon === undefined
            ? true
            : props.sortIcon;
    }, [props.sortIcon]);

    const options = useMemo(() => {
        return props.options || [];
    }, [props.options]);

    const [_value, _setValue] = useState(() => {
        return options[0]?.value;
    });

    const value = useMemo(() => {
        return props.value !== undefined
            ? props.value
            : _value;
    }, [props.value, _value]);

    const valueIndex = useMemo(() => {
        return options.findIndex(i => i.value === value);
    }, [options, value]);

    const onChange = useMemo(() => {
        return props.onChange || (() => undefined);
    }, [props.onChange]);


    const optionsRef = useLatest(options);
    const valueRef = useLatest(value);
    const handleOnClick = useCallback(() => {
        const oldValue = valueRef.current;
        const oldIndex = optionsRef.current.findIndex(i => i.value === oldValue);
        const newIndex = (oldIndex + 1) % optionsRef.current.length;
        const newValue = optionsRef.current[newIndex].value
        _setValue(newValue);
        onChange && onChange(newValue);
    }, [valueRef, optionsRef, onChange]);


    const currentOption = useMemo(() => {
        return options.find(i => i.value === value);
    }, [options, value]);


    return (
        <div
            className={classNames(
                'paddle-sorter',
                className
            )}
            onClick={handleOnClick}
        >
            {!!currentOption
                && (<>
                    <span>{currentOption.label}</span>
                    {sortIcon
                        && <>
                            {valueIndex === 0 && <SorterIcon />}
                            {valueIndex === 1 && <SorterIconUp />}
                            {valueIndex === 2 && <SorterIconDown />}
                        </>
                    }
                </>)
            }
        </div>
    );
}

export default Sorter;
