 // @ts-nocheck

/**
 * @file 多选 cascader 工具
 * @author FengGuang(fengguang01@baidu.com)
 */

import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { CascaderValueType } from 'antd/lib/cascader';
import { MultiCascaderOptionType } from './types';

// 兼容受控模式和非受控模式
export function usePropsOrState<S>(
    initialValue: S | (() => S),
    value?: S,
): [S, Dispatch<SetStateAction<S>>] {
    const [stateValue, setStateValue] = useState<S>(initialValue);

    return [
        value ?? stateValue,
        setStateValue
    ];
};


// option的map，方便快速查找。采用bfs方法遍历并存储
export function useOptionsMap(options: MultiCascaderOptionType[]) {
    const optionsMap = useMemo(() => {
        const list: MultiCascaderOptionType[] = [...options];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (Array.isArray(item.children)) {
                item.children.forEach((child) => {
                    list.push(child);
                });
            }
        }

        const newMap = new Map<string | number, MultiCascaderOptionType>();
        list.forEach((item) => {
            if (item.value) {
                newMap.set(item.value, item);
            }
        });
        return newMap;
    }, [options]);

    return optionsMap;
}


// 将树形的 option 转换成铺平的，使用bfs遍历
export function useFlatOptions(propsOptions: MultiCascaderOptionType[]) {
    const options = useMemo(() => {
        const list: MultiCascaderOptionType[] = [...propsOptions];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (Array.isArray(item.children)) {
                item.children.forEach((child) => {
                    list.push(child);
                });
            }
        }
        return list;
    }, [propsOptions]);

    return options;
}


// 将 option 转换成合适的格式
export function useFormatOptions(propsOptions: MultiCascaderOptionType[]) {
    return useMemo(() => {
        const optionLabelAddCheckbox = (options: MultiCascaderOptionType[], parent?: MultiCascaderOptionType) => {
            return options.map((item) => {
                let theItem: MultiCascaderOptionType = {
                    ...item,
                    title: item.title ?? item.label?.toString(),
                    key: item.key ?? item.value,
                    parent: parent
                };
                if (Array.isArray(theItem.children)) {
                    theItem.children = optionLabelAddCheckbox(theItem.children, theItem);
                }
                return theItem;
            });
        };
        return optionLabelAddCheckbox(propsOptions);
    }, [propsOptions]);
}

// 选中的value对应的option列表
export function useValueOption(value: CascaderValueType, optionsMap: Map<string | number, MultiCascaderOptionType>) {
    return useMemo(() => {
        const newOp: MultiCascaderOptionType[] = [];
        value.forEach((i) => {
            const item = optionsMap.get(i);
            if (item) {
                newOp.push(item);
            }
        });
        return newOp;
    }, [value, optionsMap]);
}


