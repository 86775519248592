/**
 * @file 云端快速开始
 * @author v_duzhengqiang
 */
import { Button } from 'antd';
import { RootObject, Content4 } from '../../types';

import './style.less';
const CloudFastStart = (props: RootObject["cloudFastStart"]) => {
    const {
        title,
        content,
        skip
    } = props;
    return (
        <div className='p-x-ai-cloud-fast-start'>
            <div className='p-x-ai-cloud-fast-start-title'>
                {
                    title ?? ''
                }
            </div>
            <div className='p-x-ai-cloud-fast-start-modules-wrapper'>
                <div className='p-x-ai-cloud-fast-start-modules'>
                    {
                        content.map((item: Content4, index: number) => {
                            return (
                                <div className='p-x-ai-cloud-fast-start-modules-box' key={index}>
                                    <div className='p-x-ai-cloud-fast-start-module'>
                                        <div className='p-x-ai-cloud-fast-start-module-img'>
                                            <img className="p-x-ai-cloud-fast-start-module-img-box" src={item.titleImg} alt="" />
                                        </div>
                                        <div className='p-x-ai-cloud-fast-start-module-title'>
                                            {item.title ?? ''}
                                        </div>

                                    </div>
                                    {
                                        index === 3 ?
                                            null
                                            :
                                            <div className='p-x-ai-cloud-fast-start-module-line' />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='p-x-ai-cloud-fast-start-btn'>
                <Button
                    type="primary"
                    className='p-x-ai-cloud-fast-start-btn-skip'
                    disabled={skip.skipLink ? false : true}
                    onClick={() => window.open(skip.skipLink ? skip.skipLink : undefined)}
                >
                    {skip.skipName ?? ''}
                </Button>
            </div>
        </div>
    )
}

export default CloudFastStart;