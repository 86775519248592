/**
 * @file 用于 antd@4 的from 表单的 shouldUpdate
 * @author zhangwenxi(zhangwenxi@baidu.com)
 */
interface IStore {
    [key: string]: any;
}

const shouldUpdate = (dependencies: string[], prev: IStore, cur: IStore) => {
    return dependencies.some((key) => {
        return prev[key] !== cur[key];
    });
};

export const generateShouldUpdate = (dependencies: string[]) => {
    return (prev: IStore, cur: IStore) => {
        return dependencies.some((key) => {
            return prev[key] !== cur[key];
        });
    };
};

export default shouldUpdate;
