/**
 * @file 文字卡片
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React from 'react';
import A from '../a/A';
import VerticalEllipsis from '../vertical-ellipsis/VerticalEllipsis';

export interface ITextCard {
    title: string;
    text: string;
    link?: string;
    target?: string | boolean;
    onClick?: () => any;
    tagText: string;
}

interface IProps extends ITextCard {
    children?: React.ReactNode;
}

const TextCard: React.FC<IProps> = props => {
    const {
        title,
        text,
        link,
        onClick,
        target,
        tagText
    } = props;

    return (
        <div className="paddle-text-card-grid">
            <div className="paddle-text-card">
                <A
                    className="paddle-text-card-full-link"
                    href={link}
                    onClick={onClick}
                    target={target ? '_blank' : undefined}
                    rel={target ? 'noopener noreferrer' : undefined}
                >
                    <div className="paddle-text-card-title">
                        {title}
                    </div>
                    <div className="paddle-text-card-text">
                        <VerticalEllipsis>
                            {text}
                        </VerticalEllipsis>
                    </div>
                    <div className="paddle-text-card-tag-group">
                        <div className="paddle-text-card-tag">
                            {tagText}
                        </div>
                    </div>
                </A>
            </div>
        </div>
    );
};

export default TextCard;
