/**
 * @file 文字卡片组
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React, { useMemo } from 'react';
import { Row } from 'antd';

import TextCard, { ITextCard } from './TextCard';
import { ITextCardCardListItem } from '../../types';

interface IProps {
    className?: string;
    options?: ITextCard[];
    title: string;
    subtitle: string;
    cardList: ITextCardCardListItem[];
}

const TextCardGroup: React.FC<IProps> = props => {
    const {
        className,
        children,
        title,
        cardList
    } = props;

    const childRows = useMemo(() => {
        if (cardList.length === 1) {
            return 24;
        }
        else if (cardList.length === 2) {
            return 12;
        }
        else if (cardList.length === 4) {
            return 12;
        }
        return 8;
    }, [cardList]);

    const hasInfo = useMemo(() => {
        return cardList.some((item) => {
            return !!item.info && item.info.length > 0;
        });
    }, [cardList]);

    return (
        <div
            className={className}
        >
            <div className="paddle-hub-demo-page-context-h2">
                {title}
            </div>
            <Row className="paddle-text-card-group">
                {cardList.map(card => (
                    <TextCard
                        xs={childRows}
                        key={card.title}
                        title={card.title}
                        text={card.text}
                        link={card.link}
                        info={card.info}
                        hasInfo={hasInfo}
                        // onClick={item.onClick}
                        // target={item.target}

                        tagText={card.tagText}
                    />
                ))}
                {children}
            </Row>
        </div>
    );
};

export default TextCardGroup;
