
import { useCallback, useRef, useState, useMemo } from 'react';
import { PaginationProps } from 'antd';
import { fetchGetModelList } from "../../../../api/pages/paddleHubApi";
import UniqueKey from "../../../../utils/unique-key";
import { IPaddleHubModelItem } from "./types";

const usePaddleHubModelList = () => {
    const [modelList, setModelList] = useState<IPaddleHubModelItem[]>([]);

    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0
    });


    const onPageChange = useCallback((page: number, pageSize?: number) => {
        setPagination((old) => {
            return {
                ...old,
                current: page,
                pageSize: pageSize ?? old.pageSize
            };
        });
    }, [setPagination]);

    const showModelList = useMemo(() => {
        const current = pagination.current ?? 1;
        const pageSize = pagination.pageSize ?? 10;
        const start = (current - 1) * pageSize;
        if (modelList.length <= pageSize) {
            return modelList;
        }
        return modelList.slice(start, start + pageSize);
    }, [modelList, pagination]);


    // 由于这个请求比较慢，所以留个时间戳
    const timestamp = useRef<number>(0);

    const getModelList = useCallback(
        async (params: { [key: string]: any } = {}, sortBy?: string) => {
            const startTimestamp = Date.now();
            timestamp.current = startTimestamp;

            const page = params.page ?? (pagination.current !== undefined ? pagination.current - 1 : undefined) ?? 0;
            const size = params.size ?? pagination.pageSize ?? 10;

            const res = await fetchGetModelList(
                {
                    ...params,
                    page,
                    size
                },
                sortBy
            ).catch(() => {
                // ignore
            });

            if (res && startTimestamp === timestamp.current) {
                const data = res?.body?.data;
                const list: any[] = Array.isArray(data?.data) ? data.data : [];
                const total = data?.total ?? 0;

                const uniqueKey = new UniqueKey();

                const newList: IPaddleHubModelItem[] = list.map(item => {
                    const category = ((item.category || []) as any[])
                        .map(cItem => cItem.chn)
                        .join('-');

                    const theCategory = ((item.category || []) as any[]);
                    const enCategory = theCategory.slice(-1)[0]?.en || '';

                    return {
                        key: uniqueKey.add(item.md5),
                        name: item.name,
                        contributor: item.contributor,
                        summary: item.summary,
                        enCategory,
                        category,
                        hot: item.hot,
                        network: item.network,
                        dataset: item.dataset,
                        recommendation: item.recommendation,
                        update_time: item.update_time
                    };
                });
                setModelList(newList);
                setPagination((old) => {
                    return {
                        ...old,
                        current: page + 1,
                        total: total || newList.length
                    };
                });
                return newList;
            }
            return [];
        },
        [pagination, setPagination]
    );

    return {
        modelList,
        showModelList,
        pagination,

        setModelList,
        setPagination,
        onPageChange,

        getModelList
    };
};

export default usePaddleHubModelList;
