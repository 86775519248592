/**
 * @file 语音演示头部
 * @author duzhengqiang
 */

import "./style.less";
import { PaddleVoiceHeader, VoiceHeaderJumpContent, VoiceHeadercontent } from '../../type';


const VoiceHeader = (props: PaddleVoiceHeader) => {
    
    return (
        <div className="voice_header">

            <div className="voice_header_title">{props.VoiceHeaderTitle}</div>
            <div className="voice_header_title_content">
                {props.VoiceHeaderContent}🌸🌸😁🌸
            </div>

            <div className='voice_header_details'>
                {props.VoiceHeaderJumpContent.map((item: VoiceHeaderJumpContent) => {
                    return (<div className="voice_header_details_list" key={item.title}>
                        <a key={item.title} href={item.detailLink} target='_blank' rel='noreferrer'  className="voice_header_details_list_a">
                            {item.title}
                        </a>
                    </div>)
                })}
            </div>
            <div className="line"></div>
            <div className="voice_header_introduce">
                {
                    props.VoiceHeadercontent.map((value: VoiceHeadercontent) => (
                        <div className="voice_header_introduce_List" key={value.title}>
                            <div className="voice_header_introduce_List_top">
                                <img src={value.partnerList} alt="" />
                                <span>{value.title}</span>
                            </div>
                            <div className="voice_header_introduce_List_bottom">
                                <div className="voice_header_introduce_List_bottom_content">
                                    {value.titleContent}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default VoiceHeader;
