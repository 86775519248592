/**
 * @file 排序tag的icon，
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC } from 'react';

import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';

const SorterIconUp: FC = () => {
    return (
        <span className="paddle-sorter-icon paddle-sorter-icon-up">
            <span className="paddle-sorter-icon-inner">
                <CaretUpOutlined />
                <CaretDownOutlined />
            </span>
        </span>
    );
}

export default SorterIconUp;
