/**
 * @file paddlehub demo 新冠肺炎医疗影像 用户须知弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Button, Modal} from 'antd';

interface IUserInstructionsModalProps {
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
}

const UserInstructionsModal: React.FC<IUserInstructionsModalProps> = props => {
    const {
        visible,
        onCancel,
        onOk
    } = props;

    return (
        <Modal
            title="用户须知"
            className="medical-img-modal"
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={400}
            footer={
                <React.Fragment>
                    <Button onClick={onCancel}>拒绝后离开</Button>
                    <Button onClick={onOk} type="primary">同意并继续</Button>
                </React.Fragment>
            }
        >
            1、本模型仅面向机构（如全国定点收治医院）提供测试使用服务，测试使用者保证为具有相关应用需求且根据相关法律法规有资格进行本项测试的机构。<br />
            2、测试使用者保证对上传的影像合规性负责并有权使用上传影像进行测试，不得将任何非法来源的影像用于本次测试使用。<br />
            3、本测试系统承诺不对测试使用者上传的任何信息进行存储。<br />
        </Modal>
    );
};

export default UserInstructionsModal;
