/**
 * @file 特殊兴趣小组 SIG Detail
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */

import React, { useEffect, useState } from 'react';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import usePopularDetail from './SpecialGroupDetailHook';
import {
    IPopularDetailTopCard,
    IPopularDetailSigDemo,
    IPopularDetailJoinWay,
    IPopularDetailMember,
    ISIGPopularDetail
} from './../../pages/sig/types';

import './style/style.less';

const SpecailGroupDetail: React.FC = (props: any) => {
    const {
        popularList
    } = usePopularDetail();
    const detailData: ISIGPopularDetail = popularList[parseInt(window.location.search.slice(1).split('=')[1])] ? popularList[parseInt(window.location.search.slice(1).split('=')[1])]['detail'] : {
        groupMember: {
            activeMember: {
                title: '',
                activeMemberItems: []
            },
            Member: {
                title: '',
                memberItems: []
            },
            title: ''
        },
        joinWay: {description: '', link: '', title: ''},
        sigDemo: {demoItems: [], title: ''},
        topCardInfo: {description: '', title: ''}
    };
    const [topCardInfo, setTopCardInfo] = useState<IPopularDetailTopCard>({description: '', title: ''});
    const [sigDemo, setSigDemo] = useState<IPopularDetailSigDemo>({demoItems: [], title: ''});
    const [joinWay, setJoinWay] = useState<IPopularDetailJoinWay>({description: '', link: '', title: ''});
    const [groupMember, setGroupMember] = useState<IPopularDetailMember>({
        activeMember: {
            title: '',
            activeMemberItems: []
        },
        Member: {
            title: '',
            memberItems: []
        },
        title: ''
    });
    useEffect(() => {
        detailData.topCardInfo && setTopCardInfo(detailData.topCardInfo);
        detailData.sigDemo && setSigDemo(detailData.sigDemo);
        detailData.joinWay && setJoinWay(detailData.joinWay);
        detailData.groupMember && setGroupMember(detailData.groupMember);
    }, [detailData.topCardInfo, detailData.sigDemo, detailData.joinWay, detailData.groupMember]);
    return (
        <React.Fragment>
            <Header />
            <div className="PP-SIG-popular-wrapper">
                
                <div className="PP-SIG-popular-top-card">
                    <div className="top-card-title">{topCardInfo.title}</div>
                    <div className="top-card-desc">{topCardInfo.description}</div>
                </div>
                <div className="cooperate-content-wrapper">
                    <div className="cooperate-content-title">{sigDemo.title}</div>
                    {
                        (sigDemo.demoItems || []).map(item => <div className="cooperate-content-item">
                            <a href={item.contentLink} className="trans-content-color">{ item.content }</a>
                        </div>)
                    }
                </div>
                <div className="join-way-wrapper">
                    <div className="join-way-title">{joinWay.title}</div>
                    <span className="join-way-content">{joinWay.description}</span>
                    <a href={joinWay.link}><span className="join-way-link">{joinWay.link}</span></a>
                </div>
                <div className="group-member-wrapper">
                <div className="group-member-title">{groupMember.title}</div>
                    <div className="group-member-type">
                        <div className="group-member-sub-title">{groupMember.activeMember?.title}</div>
                        <div className="member-info-card-wrapper">
                            {
                                (groupMember.activeMember?.activeMemberItems || []).map(item => <div className="member-info-card">
                                    <a href={item.githubLink}><img className="member-avator" src={item.avatorUrl} alt="" /></a>
                                    <div className="member-name">{item.memberName}</div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className="group-member-type">
                        <div className="group-member-sub-title">{groupMember.Member?.title}</div>
                        <div className="member-info-card-wrapper">
                            {
                                (groupMember.Member?.memberItems || []).map(item => <div className="member-info-card">
                                    <a href={item.githubLink}><img className="member-avator" src={item.avatorUrl} alt="" /></a>
                                    <div className="member-name">{item.memberName}</div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
         </React.Fragment>
    );
};

export default SpecailGroupDetail;