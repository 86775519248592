/**
 * @file 文字卡片
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React, { useCallback, useRef, useState } from 'react';
import { useLatest } from 'react-use';
import classNames from 'classnames';
import { Col } from 'antd';
import A from '../../../../components/a/A';
import VerticalEllipsis from '../../../../components/vertical-ellipsis/VerticalEllipsis';

export interface ITextCardInfoItem {
    label: string;
    text: string;
}

export interface ITextCard {
    title: string;
    text: string;
    info: ITextCardInfoItem[];
    link?: string;
    target?: string;
    onClick?: () => any;
    tagText: string;
    hasInfo?: boolean;
}

interface ITextCardProps extends ITextCard {
    xs?: number;
}

const TextCard: React.FC<ITextCardProps> = React.memo(props => {
    const [infoHeight, setInfoHeight] = useState<string | number | undefined>();
    const wrapRef = useRef<HTMLDivElement>(null);
    const [wrapHeight, setWrapHeight] = useState<string | number | undefined>();
    const infoRef = useRef<HTMLDivElement>(null);
    const infoInnerRef = useRef<HTMLDivElement>(null);
    const [textHeight, setTextHeight] = useState<string | number | undefined>();
    const textRef = useRef<HTMLDivElement>(null);
    const textInnerRef = useRef<HTMLDivElement>(null);
    const [isExpand, setIsExpand] = useState<boolean>(false);
    const isExpandRef = useLatest(isExpand);

    const {
        xs,
        title,
        text,
        link,
        info,
        onClick,
        target,
        tagText,
        hasInfo
    } = props;

    const onMouseEnter = useCallback(() => {
        setIsExpand(true);
        if (infoInnerRef.current) {
            const height = infoRef.current?.offsetHeight;
            const innerHeight = infoInnerRef.current?.offsetHeight;
            if (!height || !innerHeight || height < innerHeight) {
                setInfoHeight(innerHeight);
            }
        }
        if (textInnerRef.current) {
            const height = textRef.current?.offsetHeight;
            const innerHeight = textInnerRef.current?.offsetHeight;
            if (!height || !innerHeight || height < innerHeight) {
                setTextHeight(innerHeight);
            }
        }
        if (wrapRef.current) {
            const refHeight = wrapRef.current?.offsetHeight;
            if (refHeight && refHeight > 0) {
                setWrapHeight(refHeight);
            }
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        setIsExpand(false);
        setTextHeight(undefined);
        setInfoHeight(undefined);
        setTimeout(() => {
            if (!isExpandRef.current) {
                setWrapHeight(undefined);
            }
        }, 500);

    }, [isExpandRef]);

    return (
        <Col xs={xs} className="paddle-text-card-grid">
            <div
                className="paddle-text-card-wrap"
                ref={wrapRef}
                style={{
                    height: wrapHeight
                }}
            >
                <div
                    className={classNames(
                        'paddle-text-card',
                        { 'paddle-text-card-expend': isExpand }
                    )}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <A
                        className="paddle-text-card-full-link"
                        href={link}
                        onClick={onClick}
                        target={target}
                        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                    >
                        <div className="paddle-text-card-title">
                            {title}
                        </div>
                        {hasInfo
                            && (
                                <div className="paddle-text-card-info">
                                    <VerticalEllipsis
                                        className="paddle-text-card-info-inner"
                                        style={{
                                            height: infoHeight
                                        }}
                                        ref={infoRef}
                                        innerRef={infoInnerRef}
                                        delay={300}
                                    >
                                        {info.map(item => (
                                            <React.Fragment key={`${item.label}${item.text}`}>
                                                <span>{item.label}</span>
                                                <span>{item.text}</span>
                                            </React.Fragment>
                                        ))}
                                    </VerticalEllipsis>
                                </div>
                            )
                        }

                        {!!text
                            && (
                                <div className="paddle-text-card-text">
                                    <VerticalEllipsis
                                        className="paddle-text-card-text-inner"
                                        style={{
                                            height: textHeight
                                        }}
                                        ref={textRef}
                                        innerRef={textInnerRef}
                                        delay={300}
                                    >
                                        {text}
                                    </VerticalEllipsis>
                                </div>
                            )}
                        <div className="paddle-text-card-tag-group">
                            <div className="paddle-text-card-tag">
                                {tagText}
                            </div>
                        </div>
                    </A>
                </div>
            </div>
        </Col>
    );
});

export default TextCard;
