/**
 * @file paddlegan 混合demo
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { FC, useCallback, useRef, useState } from 'react';
import { Tabs } from 'antd';
import PictureDemo from './components/picturedemo/PictureDemo';
import PictureTrans from './components/pictrueStyleTrans/PictrueTrans';
import Video from '../../../../components/video/Video';

import { IPaddleGanProps } from './types';
import { VideoJsPlayer } from 'video.js';

const PaddleGan: FC<IPaddleGanProps> = (props) => {
    const [tabsActiveKey, setTabsActiveKey] = useState<string>('4');

    const onTabsChange = useCallback((key: string) => {
        setTabsActiveKey(key);
        video2Ref.current?.pause();
        video3Ref.current?.pause();
    }, [setTabsActiveKey]);

    const video2Ref = useRef<VideoJsPlayer>();
    const video3Ref = useRef<VideoJsPlayer>();

    return (
        <div>
            <Tabs className="paddlegan-tabs" activeKey={tabsActiveKey} onChange={onTabsChange}>
                <Tabs.TabPane
                    tab={props.title4 ?? ''}
                    key="4"
                >
                    <div className="paddlegan-tabs-subtitle">
                        {props.subtitle4 ?? ''}
                    </div>
                    <PictureTrans image={props.image2} visible={tabsActiveKey === '1'} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={props.title1 ?? ''}
                    key="1"
                >
                    <div className="paddlegan-tabs-subtitle">
                        {props.subtitle1 ?? ''}
                    </div>
                    <PictureDemo image={props.image} visible={tabsActiveKey === '1'} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={props.title2 ?? ''}
                    key="2"
                >
                    <div className="paddlegan-tabs-subtitle">
                        {props.subtitle2 ?? ''}
                    </div>
                    <div
                        className="paddlegan-tabs-video-wrap"
                    >
                        <Video
                            className="paddlegan-tabs-video"
                            poster={props.videoCover2 ?? ''}
                            src={props.videoSrc2 ?? ''}
                            controls
                            ref={video2Ref}
                        />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={props.title3 ?? ''}
                    key="3"
                >
                    <div className="paddlegan-tabs-subtitle">
                        {props.subtitle3 ?? ''}
                    </div>
                    <div
                        className="paddlegan-tabs-video-wrap"
                    >
                        <Video
                            className="paddlegan-tabs-video"
                            poster={props.videoCover3 ?? ''}
                            src={props.videoSrc3 ?? ''}
                            controls
                            ref={video3Ref}
                        />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default PaddleGan;
