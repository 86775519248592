/**
 * @file PaddleXAi Hooks
 * @author v_duzhengqiang
 */

import { useState } from 'react';
import { useMount } from 'react-use';
import { fetchGetPaddleInfo } from '../../api/pages/paddleApi';
import {
    RootObject
} from './types';


const PaddleXAiHook = () => {
    // 头部简介
    const [headerIntro, setHeaderIntro] = useState<RootObject["headerIntro"]>({
        title: '',
        desc: '',
        content: [],
    });
    // 核心优势
    const [coreAdvantage, setCoreAdvantage] = useState<RootObject["coreAdvantage"]>({
        title: '',
        content: [],
    });
    // 应用场景
    const [applicationScenario, setApplicationScenario] = useState<RootObject["applicationScenario"]>({
        title: '',
        content: [],
    });
    // 云端快速开始
    const [cloudFastStart, setCloudFastStart] = useState<RootObject["cloudFastStart"]>({
        title: '',
        content: [],
        skip: {
            skipName: '',
            skipLink: ''
        }
    });
    // 云端快速开始
    const [localDownload, setLocalDownload] = useState<RootObject["localDownload"]>({
        title: '',
        tips: '',
        content: []
    });
    // 相关咨询
    const [relatedInformation, setRelatedInformation] = useState<RootObject["relatedInformation"]>({
        title: '',
        content: [],
        skip: {
            skipName: '',
            skipLink: '',
        }
    });
    // 相关咨询
    const [exchangeAndCooperation, setExchangeAndCooperation] = useState<RootObject["exchangeAndCooperation"]>({
        title: '',
        content: []
    });
    useMount(() => {
        (async () => {
            const res = await fetchGetPaddleInfo({ requestUrl: '/paddle/paddle-x-ai' })
                .catch((err) => {
                    // ignore
                });
            if (res) {
                const data = res.body.result?.pageData || {};
             

                data.headerIntro && setHeaderIntro(data.headerIntro);
                data.coreAdvantage && setCoreAdvantage(data.coreAdvantage);
                data.applicationScenario && setApplicationScenario(data.applicationScenario);
                data.cloudFastStart && setCloudFastStart(data.cloudFastStart);
                data.localDownload && setLocalDownload(data.localDownload);
                data.relatedInformation && setRelatedInformation(data.relatedInformation);
                data.exchangeAndCooperation && setExchangeAndCooperation(data.exchangeAndCooperation);
            }
        })();
    });
    return {
        headerIntro,
        coreAdvantage,
        applicationScenario,
        cloudFastStart,
        localDownload,
        relatedInformation,
        exchangeAndCooperation
    }
}

export default PaddleXAiHook;