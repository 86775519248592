/**
 * @file 首页banner
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';

import { IBannerProps } from './types';


const Banner: React.FC<IBannerProps> = React.memo((props) => {
    // 如果传入的是字符串则使用 InnerHTML 设置。方便从cms里写html标签
    const subtitmeInnerHTMLObj = useMemo(() => {
        if (typeof (props.subtitle) === 'string') {
            return { __html: props.subtitle };
        }
        return undefined;
    }, [props.subtitle]);

    return (
        <div
            className={classNames(
                'paddle-banner-wrap',
                props.className
            )}
        >
            <div className="paddle-banner">
                <div className="paddle-banner-container">
                    <div className="paddle-banner-title">{props.title}</div>
                    <div
                        className="paddle-banner-subtitle"
                        {...{ dangerouslySetInnerHTML: subtitmeInnerHTMLObj }}
                    >
                        {subtitmeInnerHTMLObj ? undefined : props.subtitle}
                    </div>
                    {props.children}
                    {
                        !!props.divider
                        && <div className="paddle-banner-divider" />
                    }
                </div>
            </div>
        </div>
    );
});

export default Banner;
