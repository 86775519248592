/**
 * @file highlight.js 代码块
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useEffect, useRef} from 'react';
import hljs from './highlight';

interface IHeightlightBlockProps {
    resultJson: string;
}

const HeightlightBlock: React.FC<IHeightlightBlockProps> = React.memo(props => {
    const resultJson = props.resultJson;

    const contentRef = useRef<HTMLPreElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            hljs.highlightBlock(contentRef.current);
        }
    }, [resultJson]);

    return (
        <pre
            ref={contentRef}
            className="picture-demo-json-body-content"
            dangerouslySetInnerHTML={{__html: resultJson}}
        ></pre>
    );
});

export default HeightlightBlock;
