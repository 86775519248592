/**
 * @file 文字卡片组
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React from 'react';
import classNames from 'classnames';

import TextCard, { ITextCard } from './TextCard';

interface IProps {
    className?: string;
    option?: ITextCard[];
    cols?: number;
}

const TextCardGroup: React.FC<IProps> = props => {
    const { className, children, option, cols } = props;

    return (
        <div
            className={classNames('paddle-text-card-wrap', className, {
                'paddle-text-card-cols-3': cols === 3
            })}
        >
            <div className="paddle-text-card-group">
                {!!option &&
                    option.map(item => (
                        <TextCard
                            key={`${item.title},${item.text}`}
                            title={item.title}
                            text={item.text}
                            link={item.link}
                            onClick={item.onClick}
                            target={item.target}
                            tagText={item.tagText}
                        />
                    ))}
                {children}
            </div>
        </div>
    );
};

export default TextCardGroup;
