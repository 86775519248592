/**
 * @file paddlehub 通用图片 demo api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';

interface IGetPictureDemoResultParams {
    image?: string;
    image_url?: string;
}

export const fetchGetPictureResult = (
    params: IGetPictureDemoResultParams,
    model: string
): Promise<request.Response> => {
    return request
        .post(`/paddlehub-api/image_classification/${model}`)
        .send(params);
};
export const fetchGetPictureResultNEWOCR = (
    params: IGetPictureDemoResultParams,
    model: string
): Promise<request.Response> => {
    
    return request
        .post(`/${model}`)
        .send(params);
};
