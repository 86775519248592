/**
 * @file paddlehub demo 新冠肺炎医疗影像 输入框
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {CSSProperties, DetailedHTMLProps, TextareaHTMLAttributes, useCallback, useState} from 'react';
import classNames from 'classnames';

interface ITextareaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    wrapClassName?: string;
    wrapStyle?: CSSProperties;
}

const Textarea: React.FC<ITextareaProps> = React.memo(props => {
    const {
        maxLength,
        onChange
    } = props;
    const [stateValue, setStateValue] = useState<string>('');
    const value = props.value ?? stateValue;
    const setValue = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typeof (onChange) === 'function') {
            onChange(event);
        }
        setStateValue(event.target.value);
    }, [onChange, setStateValue]);

    const valueLength = (() => {
        if (typeof (value) === 'number') {
            return value;
        }
        return value.length;
    })();

    return (
        <div
            className={classNames(
                props.wrapClassName,
                'textarea-wrap'
            )}
            style={props.wrapStyle}
        >
            <textarea
                {...props}
                className={classNames(
                    'textarea',
                    props.className
                )}
                value={value}
                onChange={setValue}
            />
            <div className="textarea-length">
                {valueLength}/{maxLength ?? 0}
            </div>
        </div>
    );
});

export default Textarea;
