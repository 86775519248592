/**
 * @file 视频
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { useEffect, useImperativeHandle, useRef, useState, VideoHTMLAttributes } from 'react';
import { useLatest } from 'react-use';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import classNames from 'classnames';

interface IVideoProps extends VideoHTMLAttributes<HTMLVideoElement>, Pick<VideoJsPlayerOptions, Exclude<keyof VideoJsPlayerOptions, keyof VideoHTMLAttributes<HTMLVideoElement>>> {
}

const transOptions = (options: IVideoProps) => {
    const { children, ...restOptions } = options;
    const newOptions: VideoJsPlayerOptions = {
        autoplay: options.autoPlay,
        controls: options.controls,
        loop: options.loop,
        muted: options.muted,
        poster: options.poster,
         // @ts-ignore
        preload: options.preload,
        src: options.src,
        sources: options.src
            ? [{
                // type: 'video/mp4',
                src: options.src
            }]
            : [],
        ...restOptions,
        height: typeof (options.height) === 'string' ? parseFloat(options.height) : options.height,
        width: typeof (options.width) === 'string' ? parseFloat(options.width) : options.width
    };
    return newOptions;
};

const Video = React.memo(React.forwardRef<VideoJsPlayer | null | undefined, IVideoProps>((props, ref) => {
    const videoRef = useRef<HTMLVideoElement>();
    const videoWrapRef = useRef<HTMLDivElement>(null);
    const [player, setPlayer] = useState<VideoJsPlayer | undefined>();
    const playerRef = useLatest(player);

    const { className } = props;

    useImperativeHandle(ref, () => (
        player
    ), [player]);

    const options = transOptions(props);
    useEffect(() => {
        playerRef.current?.src({
            src: options.src ?? '',
            type: ''
        });
    }, [playerRef, options.src]);
    useEffect(() => {
        playerRef.current?.poster(options.poster ?? '');
    }, [playerRef, options.poster]);
    useEffect(() => {
        playerRef.current?.autoplay(options.autoplay !== undefined ? options.autoplay : false);
    }, [playerRef, options.autoplay]);
    useEffect(() => {
        playerRef.current?.loop(!!options.loop);
    }, [playerRef, options.loop]);
    useEffect(() => {
        playerRef.current?.controls(!!options.controls);
    }, [playerRef, options.controls]);
    useEffect(() => {
        playerRef.current?.preload(!!options.preload);
    }, [playerRef, options.preload]);

    const propsRef = useLatest(props);
    useEffect(() => {
        if (!videoRef.current) {
            videoRef.current = document.createElement('video');
            videoRef.current.className = classNames(propsRef.current.className, 'video-js vjs-big-play-centered');
            if (videoWrapRef.current) {
                videoWrapRef.current.appendChild(videoRef.current);
            }
        }
        const thePlayer = videojs(videoRef.current, transOptions(propsRef.current));
        setPlayer(thePlayer);
        return () => {
            thePlayer.dispose();
            videoRef.current = undefined;
        };
    }, [propsRef]);

    // 不要使用 src 属性，而是用 <source src=''/> 标签
    // 因为使用 src 属性会出现 video.js 无法控制播放的 bug
    return (
        <div
            className={`paddle-video ${className}`}
            ref={videoWrapRef}
        >
        </div>
    );
}));

export default Video;
