// @ts-nocheck

/**
 * @file 多选 cascader 格式化value
 * @author FengGuang(fengguang01@baidu.com)
 */
import { TreeSelect, TreeSelectProps } from 'antd';
import { CascaderValueType } from 'antd/lib/cascader';
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { ChildSelectMapType, MultiCascaderOptionType, MultiCascaderProps } from "./types";

const {
    SHOW_ALL,
    SHOW_CHILD,
    SHOW_PARENT
} = TreeSelect;

// bfs遍历所有子元素
function getAllChildren(item: MultiCascaderOptionType) {
    const values: MultiCascaderOptionType[] = [item];
    for (let i = 0; i < values.length; i++) {
        const current = values[i];
        if (!current) {
            break;
        }
        if (Array.isArray(current.children)) {
            current.children.forEach(i => values.push(i));
        }
    }
    values.shift();
    return values;
}

// 遍历所有父元素
function getAllParents(item: MultiCascaderOptionType) {
    const values: MultiCascaderOptionType[] = [];
    let current: MultiCascaderOptionType | undefined = item?.parent;
    while (current) {
        if (current.value) {
            values.push(current);
        }
        current = current.parent;
    }
    return values;
}

// 计算 set 差集
function differenceSet<T>(set1: Set<T>, set2: Set<T>) {
    const theSet1 = new Set(set1);
    set2.forEach(i => theSet1.delete(i));
    return theSet1;
}


function changeChildSetMap(
    method: 'add' | 'delete',
    childSelectMap: ChildSelectMapType,
    item?: MultiCascaderOptionType,
) {
    const parentValue = item?.parent?.value;
    let newChildSelectMap = childSelectMap;
    if (item?.value) {
        const arr = childSelectMap.get(parentValue) || new Set();
        if ((method === 'add' && !arr.has(item.value)) || (method === 'delete' && arr.has(item.value))) {
            newChildSelectMap = new Map(childSelectMap);
        }
        arr[method](item.value);
        newChildSelectMap.set(parentValue, arr);
    }
    return newChildSelectMap;
}


// 根据 value 的变化改变 childSelectedMap
function changeFormatVal(
    value: CascaderValueType | Set<string | number> | undefined,
    prevValue: CascaderValueType | Set<string | number> | undefined,
    addedValue: CascaderValueType | Set<string | number> | undefined,
    removedValue: CascaderValueType | Set<string | number> | undefined,
    optionsMap: Map<string | number, MultiCascaderOptionType>,
    childSelectedMap: ChildSelectMapType,
) {
    const valueSet = new Set(value);
    const prevValueSet = new Set(prevValue);
    const addedValueSet = addedValue ? new Set(addedValue) : differenceSet(valueSet, prevValueSet);
    const removedValueSet = removedValue ? new Set(removedValue) : differenceSet(prevValueSet, valueSet);

    let csMap = childSelectedMap;

    addedValueSet.forEach((v) => {
        const item = optionsMap.get(v);
        csMap = changeChildSetMap('add', csMap, item);
        if (item) {
            getAllParents(item).forEach((c) => {
                const parentChildrenSelected = csMap.get(c.value) || new Set();

                if (c?.children && c.children.length <= parentChildrenSelected.size) {
                    csMap = changeChildSetMap('add', csMap, c);
                }
            });
            getAllChildren(item).forEach((c) => {
                csMap = changeChildSetMap('add', csMap, c);
            });
        }
    });

    removedValueSet.forEach((v) => {
        const item = optionsMap.get(v);

        csMap = changeChildSetMap('delete', csMap, item);
        if (item) {
            getAllParents(item).forEach((c) => {
                csMap = changeChildSetMap('delete', csMap, c);
            });
            getAllChildren(item).forEach((c) => {
                csMap = changeChildSetMap('delete', csMap, c);
            });
        }
    });
    return csMap;
}


function getValueSetFromChildSelectedMap(
    childSelectedMap: ChildSelectMapType,
    optionsMap: Map<string | number, MultiCascaderOptionType>,
    showCheckedStrategy?: TreeSelectProps<string | number>['showCheckedStrategy']
) {
    const valueSet: Set<string | number> = new Set();
    const halfValueSet: Set<string | number> = new Set();
    const theStrategy = showCheckedStrategy ?? SHOW_ALL;


    // if (theStrategy === SHOW_ALL)
    childSelectedMap.forEach((list, value) => {
        const item = value !== undefined ? optionsMap.get(value) : undefined;
        if (item
            && item.value
            && item.children
            && list.size > 0
            && item.children.length > list.size
        ) {
            halfValueSet.add(item.value);
        }
        list.forEach((cValue) => {
            valueSet.add(cValue);
        });
    });

    if (theStrategy === SHOW_PARENT) {
        valueSet.forEach((value) => {
            const item = optionsMap.get(value);
            if (valueSet.has(value) && item) {
                getAllChildren(item).forEach((subitem) => {
                    if (subitem.value !== undefined) {
                        valueSet.delete(subitem.value);
                    }
                });
            }
        });
    }
    else if (theStrategy === SHOW_CHILD) {
        valueSet.forEach((value) => {
            if (!valueSet.has(value)) {
                return;
            }
            const item = optionsMap.get(value);
            const childSelectedSet = childSelectedMap.get(value);
            if (item
                && item.children
                && item.children.length > 0
                && childSelectedSet
                && item.children.length <= childSelectedSet.size
            ) {
                valueSet.delete(value);
            }
        });
    }
    return { valueSet, halfValueSet };
}

/**
* 格式化 value 的选项，以方便树形 checkbox 正确的选中或没选中
* @param value 选中的选项
* @param optionsMap options的map，方便根据value快速查找对应的option选项
* @param props 传入的 onChange
* @param showCheckedStrategy 定制选中项回填的模式，参考 TreeSelect 相关选项
* @return 没有返回值
*/
export function useFormatVal(
    value: CascaderValueType | undefined,
    optionsMap: Map<string | number, MultiCascaderOptionType>,
    onChange: MultiCascaderProps['onChange'],
    showCheckedStrategy: TreeSelectProps<string | number>['showCheckedStrategy']
) {
    // 计算哪些 checkbox 选中哪些没选中，需要对比新 value 和旧 value，再根据差别
    // 标记到对应的 checkbox。
    const valueRef = useRef(value);

    const prevValueRef = useRef<CascaderValueType | undefined>([]);


    // 用来记录每个 checkbox 是否选中的变量。其结构是一个 map，表示一个节点有几个子节点被选中了。
    // 这样想查询某个节点的子节点选中情况，只需一次查询就能确定而不需要遍历子节点
    // 这个变量命名为 childSelectedMap

    const defaultChildSelectedMap = useMemo<ChildSelectMapType>(() => {
        const theMap: ChildSelectMapType = new Map();
        optionsMap.forEach((item) => {
            theMap.set(item.parent?.value, new Set());
        });
        return theMap;
    }, [optionsMap]);
    const childSelectedMapRef = useRef(defaultChildSelectedMap);

    // optionsMap 变化了，则清空 childSelectedMap 重新计算选中状态
    useMemo(() => {
        childSelectedMapRef.current = defaultChildSelectedMap;
        prevValueRef.current = [];
    }, [defaultChildSelectedMap]);

    // 对比新旧 value，并将结果写入 childSelectedMap 中。
    // 此时 childSelectedMap 中保存的就是现在这一刻所有 checkbox 被选中的状态
    useMemo(() => {
        childSelectedMapRef.current = changeFormatVal(
            value,
            prevValueRef.current,
            undefined,
            undefined,
            optionsMap,
            childSelectedMapRef.current
        );
        const { valueSet } = getValueSetFromChildSelectedMap(
            childSelectedMapRef.current,
            optionsMap,
            showCheckedStrategy
        );
        // 将格化后的 value 保存到 valueRef 中
        valueRef.current = Array.from(valueSet);
    }, [optionsMap, value, prevValueRef, showCheckedStrategy]);


    const handleOnChange = useCallback<Dispatch<SetStateAction<CascaderValueType>>>(
        (state) => {
            const newValue = typeof (state) === 'function'
                ? state(valueRef.current || [])
                : state

            console.log(newValue, valueRef.current);

            childSelectedMapRef.current = changeFormatVal(
                newValue,
                valueRef.current,
                undefined,
                undefined,
                optionsMap,
                childSelectedMapRef.current
            );
            const newFormat = getValueSetFromChildSelectedMap(
                childSelectedMapRef.current,
                optionsMap,
                showCheckedStrategy
            );

            const newFormatValue = Array.from(newFormat.valueSet);
            const newFormatHalfValue = Array.from(newFormat.halfValueSet);
            prevValueRef.current = newFormatValue;
            onChange && onChange(newFormatValue, newFormatHalfValue);

        },
        [onChange, optionsMap, showCheckedStrategy, valueRef, prevValueRef]
    );

    const handleOnCheckChange = useCallback(
        (key: string | number | undefined, checked: boolean) => {
            if (key !== undefined) {
                childSelectedMapRef.current = changeFormatVal(
                    undefined,
                    undefined,
                    checked ? [key] : [],
                    !checked ? [key] : [],
                    optionsMap,
                    childSelectedMapRef.current
                );
                const newFormat = getValueSetFromChildSelectedMap(
                    childSelectedMapRef.current,
                    optionsMap,
                    showCheckedStrategy
                );

                const newFormatValue = Array.from(newFormat.valueSet);
                const newFormatHalfValue = Array.from(newFormat.halfValueSet);
                prevValueRef.current = newFormatValue;
                onChange && onChange(newFormatValue, newFormatHalfValue);
            }
        },
        [onChange, optionsMap, showCheckedStrategy, prevValueRef]
    );


    const childSelectedMap = childSelectedMapRef.current;
    const resultMemo = useMemo(() => {
        const { valueSet, halfValueSet } = getValueSetFromChildSelectedMap(
            childSelectedMap,
            optionsMap,
            showCheckedStrategy
        );
        const {
            valueSet: valueForTreeSet,
            halfValueSet: halfValueForTreeSet
        } = getValueSetFromChildSelectedMap(
            childSelectedMap,
            optionsMap,
        );
        return {
            valueSet,
            value: Array.from(valueSet),
            valueForTreeSet,
            halfValueSet,
            halfValue: Array.from(halfValueSet),
            halfValueForTreeSet
        };
    }, [childSelectedMap, optionsMap, showCheckedStrategy]);

    return {
        value: resultMemo.value,
        valueSet: resultMemo.valueSet,
        valueForTreeSet: resultMemo.valueForTreeSet,
        halfValue: resultMemo.halfValue,
        halfValueSet: resultMemo.halfValueSet,
        halfValueForTreeSet: resultMemo.halfValueForTreeSet,
        onChange: handleOnChange,
        onCheckChange: handleOnCheckChange
    };
}

