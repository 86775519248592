/**
 * @file PaddleHub 首页
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import { Button } from 'antd';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';

import PaddleHubModelList from './components/paddle-hub-model-list/PaddleHubModelList';
import usePaddleHub from './usePaddleHub';
import PaddleHubMenu from './components/paddle-hub-menu/PaddleHubMenu';

import Banner from './components/banner/Banner';
import ChartsList from './components/charts/ChartsList';
import SearchForm from './components/search-form/SearchForm';


const PaddleHub: React.FC = () => {
    const {
        onSearchFormChange,
        onMenuSelectedKeysChange,

        paddleHubModelListHook,
        searchFormHook,
        paddleHubMenuHubHook,
        chartsListHook,

        onPageChange
    } = usePaddleHub();

    return (
        <div className="page paddle-hub-page">
            <Header />
            <Banner
                title="PaddleHub模型搜索"
                subtitle=""
            >
                <div className="paddle-hub-page-banner-btn-group">
                    <a
                        href="https://aistudio.baidu.com/aistudio/personalcenter/thirdview/79927"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button
                            type="link"
                            size="large"
                            className="paddle-hub-page-banner-btn"
                        >
                            使用文档
                        </Button>
                    </a>
                    <a
                        href="https://github.com/paddlepaddle/paddlehub"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button
                            type="link"
                            size="large"
                            className="paddle-hub-page-banner-btn"
                        >
                            GitHub
                        </Button>
                    </a>
                </div>
            </Banner>

            <div className="paddle-hub-page-container clear-both">
                <div className="paddle-hub-page-container-left">
                    <PaddleHubMenu
                        onFinish={onMenuSelectedKeysChange}
                        {...paddleHubMenuHubHook}
                    />
                </div>
                <div className="paddle-hub-page-container-center">
                    <div className="paddle-hub-page-container-center-top">
                        <SearchForm
                            {...searchFormHook}
                            onFinish={onSearchFormChange}
                        />
                    </div>
                    <div className="paddle-hub-page-container-center-center">
                        <div className="paddle-hub-model-list-wrap">
                            <PaddleHubModelList {...paddleHubModelListHook} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
                <div className="paddle-hub-page-container-right">
                    <ChartsList {...chartsListHook} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaddleHub;
