/**
 * @file 垂直省略号
 * @author FengGuang(fengguang01@baidu.com)
 *
 */


/*
 * <VerticalEllipsis>
 *     {text}
 * </VerticalEllipsis>
 */
import React, {
    CSSProperties,
    MutableRefObject,
    RefCallback,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import { getTextInfo } from './../../utils/getTextInfo';
import isNil from 'lodash/isNil';

interface IVerticalEllipsisProps {
    className?: string;
    ellipsis?: string;
    title?: string;
    style?: CSSProperties;
    html?: string;
    innerRef?: RefCallback<HTMLDivElement> | MutableRefObject<HTMLDivElement | null>;
    delay?: number;
    children?: React.ReactNode;
}

const VerticalEllipsis = React.forwardRef<HTMLDivElement | null, IVerticalEllipsisProps>((props, ref) => {
    const [showEllipsis, setShowEllipsis] = useState<boolean>(false);
    const wrapRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const textInnerRef = useRef<HTMLDivElement>(null);

    const {
        className,
        children,
        title,
        style,
        innerRef
    } = props;

    const getProps = children ? { children } : getTextInfo(props.html ?? '');

    useLayoutEffect(() => {
        if (innerRef) {
            if (typeof (innerRef) === 'function') {
                innerRef(textInnerRef.current);
            }
            else {
                innerRef.current = textInnerRef.current;
            }
        }
    });

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
        ref,
        () => wrapRef.current
    );

    const ellipsis = !isNil(props.ellipsis) ? props.ellipsis : '...';

    const showEllipsisRef = useRef(showEllipsis);
    showEllipsisRef.current = showEllipsis;
    const checkNeedShowEllipsis = useCallback(() => {
        if (wrapRef.current && textRef.current && textInnerRef.current) {
            const textHeight = textRef.current.offsetHeight;
            const textInnerHeight = textInnerRef.current.offsetHeight;
            const newShowEllipsis = textHeight < textInnerHeight;
            setShowEllipsis(newShowEllipsis);
        }
    }, [setShowEllipsis]);

    // 检查是否需要显示省略号，如果需要则会触发重新渲染
    // 只依赖 props 的变化，忽略 showEllipsis 的变化
    useEffect(
        () => {
            if (props.delay && props.delay > 0) {
                setTimeout(checkNeedShowEllipsis, props.delay);
            }
            else if (props.delay === 0) {
                checkNeedShowEllipsis();
            }
            else {
                setTimeout(checkNeedShowEllipsis, 100);
            }
        },
        [props, checkNeedShowEllipsis]
    );

    // 监听窗口大小变化事件，调整是否显示省略号
    useEffect(() => {
        window.addEventListener('resize', checkNeedShowEllipsis);
        // willUnmount
        return () => {
            window.removeEventListener('resize', checkNeedShowEllipsis);
        };
    }, [checkNeedShowEllipsis]);

    return (
        <div
            className={classNames('vertical-ellipsis-wrap', className)}
            style={style}
            ref={wrapRef}
        >
            <div className="vertical-ellipsis-inner" ref={textRef}>
                <div ref={textInnerRef} title={title} {...getProps} />
            </div>
            {showEllipsis
            && (
                <div className="vertical-ellipsis-ellipsis">{ellipsis}</div>
            )}
        </div>
    );
});

export default VerticalEllipsis;
