
import {useRef, useState} from 'react';

const ApplicationScenarioHooks = () => {
    const [selectedTabKey, setSelectedTabKey] = useState<string>('');
    const isFirstLoad = useRef<boolean>(true);

    return {
        selectedTabKey,
        setSelectedTabKey,
        isFirstLoad,
        onSelectedTabKeyChange: setSelectedTabKey
    };
};

export default ApplicationScenarioHooks;
