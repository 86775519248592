/**
 * @file paddle 客户案例 视频文字卡片
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback, useRef} from 'react';
import {Col, Row} from 'antd';
import VideoCover from '../../../components/video-cover/VideoCover';
import VideoModal from '../../../components/video-modal/VideoModal';
import {IVerticalVideoTextCard} from './types.d';


const VerticalVideoTextCard: React.FC<IVerticalVideoTextCard> = React.memo(props => {
    const videoCoverRef = useRef<HTMLVideoElement>(null);
    const onCoverMouseEnter = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.play();
        }
    }, []);
    const onCoverMouseLeave = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.pause();
        }
    }, []);

    return (
        <div className="paddle-vertical-video-text-card-wrap">
            {props.list.map(item => (
                <div key={item.title} className="paddle-vertical-video-text-card">
                    <Row className="paddle-vertical-video-text-card-body">
                        <Col xs={24} className="paddle-vertical-video-text-card-text">
                            <div className="paddle-vertical-video-text-card-text-title">
                                {props.title}
                            </div>
                            <div
                                className="paddle-vertical-video-text-card-text-text"
                                dangerouslySetInnerHTML={{__html: item.text || ''}}
                            >
                            </div>
                        </Col>
                        <Col xs={24} className="paddle-vertical-video-text-card-video">
                            <VideoModal
                                src={item.videoUrl}
                            >
                                <div
                                    className="paddle-vertical-video-text-card-video"
                                    onMouseEnter={onCoverMouseEnter}
                                    onMouseLeave={onCoverMouseLeave}
                                    style={{backgroundImage: item.imageUrl ? `url(${item.imageUrl})` : undefined}}
                                >
                                    <span className="paddle-vertical-video-text-card-video-play-btn" />
                                    <VideoCover
                                        className="paddle-vertical-video-text-card-video-background-video"
                                        src={item.videoCoverUrl}
                                        ref={videoCoverRef}
                                        loop
                                    />
                                </div>
                            </VideoModal>
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
});

export default VerticalVideoTextCard;
