/**
 * @file 新闻页的api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';
import baseRequest from '../baseApi';

// 获取新闻列表
export const fetchGetNewsList = (params: object | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/news/getNewsList')
            .query(params)
    );

// 获取新闻列表推荐
export const fetchGetNewsRecommend = (params: object | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/news/getNewsRecommend')
            .query(params)
    );

// 获取新闻详情
export const fetchGetNewsDetail = (params: object | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/news/getNewsDetail')
            .query(params)
    );
