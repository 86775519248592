/**
 * @file paddle hub 搜索排行榜列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import { IChart } from './types';
import A from '../../../../components/a/A';

const Chart: FC<IChart> = (props) => {
    const { title, list } = props;

    return (
        <div className="paddle-hub-chart">
            <div className="paddle-hub-chart-title">
                {title}
            </div>
            <div className="paddle-hub-chart-body">
                {list.map((item, index) => (
                    <A key={index} className="paddle-hub-chart-line" href={item.url}>
                        <span
                            className={classNames({
                                'paddle-hub-chart-item-red': index < 3
                            })}
                        >
                            {index + 1}
                        </span> &nbsp;
                        <Tooltip title={`${item.name}   ${item.tag}`}>
                            <span>{item.name}</span>
                        </Tooltip>
                        <Tooltip title={`${item.name}   ${item.tag}`}>
                            <span>{item.tag}</span>
                        </Tooltip>
                    </A>
                ))}
            </div>
        </div>
    );
}

export default Chart;
