/**
 * @file paddlehub demo 新冠肺炎医疗影像 图像
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {KeyboardEvent, UIEvent, useCallback, useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import debounceAnimationFrame from 'debounce-animation-frame';


import {IMainImgProps} from './types';

const MainImg: React.FC<IMainImgProps> = React.memo(props => {
    const {
        imgSelectedIndex,
        imgSelectedUrl,
        imgSelectedKey,
        imgList,
        sourceImgList,

        onImgSelectedChange
    } = props;

    // 如果imgList没有数据就占位
    const imgListPlaceholder = useMemo(() => {
        return Array.from({length: 6}).map((v, i) => '' + i);
    }, []);

    // 监听滚动
    const imgListDivRef = useRef<HTMLDivElement>(null);
    const imgListInnerDivRef = useRef<HTMLDivElement>(null);

    interface imgListItemOffsetItem {
        left: number;
        right: number;
        key: string;
    }

    const imgListItemOffset = useRef<imgListItemOffsetItem[]>([]);
    useEffect(() => {
        if (imgListDivRef.current && imgListInnerDivRef.current) {
            const domList = imgListInnerDivRef.current.querySelectorAll<HTMLLIElement>('.medical-img-main-img-gallery-item');
            imgListItemOffset.current = [];
            domList.forEach((item, index) => {
                const sourceImgItem = sourceImgList[index];
                imgListItemOffset.current.push({
                    key: sourceImgItem?.key,
                    right: item.offsetLeft + item.offsetWidth,
                    left: item.offsetLeft
                });
            });
        }
    }, [sourceImgList]);

    const imgSelectedIndexRef = useRef<number>(imgSelectedIndex);
    imgSelectedIndexRef.current = imgSelectedIndex;

    const onImgListScroll = useMemo(() => {
        const deboundFunc = debounceAnimationFrame((wrapDiv: HTMLDivElement) => {
            const selectedIndex = imgSelectedIndexRef.current;
            const itemOffset = imgListItemOffset.current;
            const itemLeft = itemOffset[selectedIndex]?.left ?? 0;
            const itemRight = itemOffset[selectedIndex]?.right ?? 0;
            if (itemLeft < wrapDiv.scrollLeft) {
                const resItem = itemOffset.find(i => i.left > wrapDiv.scrollLeft);
                if (resItem?.key) {
                    onImgSelectedChange(resItem.key);
                }
            }
            else if (itemRight > wrapDiv.scrollLeft + wrapDiv.offsetWidth) {
                const resItem = itemOffset
                    .slice(0)
                    .reverse()
                    .find(i => i.right < wrapDiv.scrollLeft + wrapDiv.offsetWidth);
                if (resItem?.key) {
                    onImgSelectedChange(resItem.key);
                }
            }
        });
        return (event: UIEvent<HTMLDivElement>) => {
            const wrapDiv = event.target as HTMLDivElement;
            deboundFunc(wrapDiv);
        };
    }, [onImgSelectedChange]);

    const onImgListKeyUp = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        const wrapDiv = event.target as HTMLDivElement;

        if (event.keyCode === 37) {
            // 按下向左键
            event.preventDefault();
            const itemOffset = imgListItemOffset.current;
            const nextIndex = imgSelectedIndexRef.current - 1;
            const nextKey = imgListItemOffset.current[nextIndex]?.key;
            if (nextKey) {
                onImgSelectedChange(nextKey);
                if (itemOffset[nextIndex].left < wrapDiv.scrollLeft) {
                    wrapDiv.scrollLeft = itemOffset[nextIndex].left;
                }
            }
        }
        else if (event.keyCode === 39) {
            // 按下向右键
            event.preventDefault();
            const itemOffset = imgListItemOffset.current;
            const nextIndex = imgSelectedIndexRef.current + 1;
            const nextKey = imgListItemOffset.current[nextIndex]?.key;
            if (nextKey) {
                onImgSelectedChange(nextKey);
                if (itemOffset[nextIndex].right > wrapDiv.scrollLeft + wrapDiv.offsetWidth) {
                    wrapDiv.scrollLeft = itemOffset[nextIndex].right - wrapDiv.offsetWidth;
                }
            }
        }
    }, [onImgSelectedChange]);

    const onMouseEnter = useCallback(() => {
        if (imgListDivRef.current) {
            imgListDivRef.current.focus();
        }
    }, []);

    return (
        <div onMouseEnter={onMouseEnter}>
            <div className="medical-img-main-img-wrap">
                <div
                    className="medical-img-main-img"
                    style={{backgroundImage: `url(${imgSelectedUrl})`}}
                >
                    <div className="medical-img-main-length">
                        {imgSelectedIndex + 1}/{imgList.length}
                    </div>
                </div>
            </div>
            <div
                className="medical-img-main-img-gallery-wrap"
                ref={imgListDivRef}
                onScroll={onImgListScroll}
                tabIndex={0}
                onKeyDown={onImgListKeyUp}
            >
                <div
                    className="medical-img-main-img-gallery-inner"
                    ref={imgListInnerDivRef}
                >
                    <ul className="medical-img-main-img-gallery">
                        {sourceImgList.map(item => (
                            <li
                                key={item.key}
                                className={classNames(
                                    'medical-img-main-img-gallery-item',
                                    {
                                        'medical-img-main-img-gallery-item-selected': imgSelectedKey === item.key
                                    }
                                )}
                                style={{backgroundImage: `url(${item.url})`}}
                                onClick={() => onImgSelectedChange(item.key)}
                            />
                        ))}
                        {/*如果imgList没有数据就占位*/}
                        {sourceImgList.length === 0
                        && imgListPlaceholder.map(i => (
                            <li key={i} className="medical-img-main-img-gallery-item" />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default MainImg;
