/**
 * @file paddle hub 首页模型列表元素
 * @author FengGuang(fengguang01@baidu.com)
 */
import { Tag } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import A from '../../../../components/a/A';

import { IPaddleHubModelItemProps } from './types';


const PaddleHubModelItem: React.FC<IPaddleHubModelItemProps> = props => {
    const [showEllipsis, setShowEllipsis] = useState<boolean>(false);
    const summaryRef = useRef<HTMLDivElement>(null);
    const summaryInnerRef = useRef<HTMLDivElement>(null);
    const {
        name,
        summary,
        enCategory,
        contributor,
        category,
        network,
        hot,
        dataset,
        recommendation,
        update_time
    } = props.model;


    const resizeMethod = useCallback(() => {
        if (summaryRef.current && summaryInnerRef.current) {
            const textHeight = summaryRef.current.getBoundingClientRect().height;
            const textInnerHeight = summaryInnerRef.current.getBoundingClientRect().height;
            const newShowEllipsis = textHeight < textInnerHeight;
            setShowEllipsis(newShowEllipsis);
        }
    }, [setShowEllipsis]);

    // 检查是否需要显示省略号，如果需要则会触发重新渲染
    useEffect(resizeMethod, [summary, showEllipsis, resizeMethod]);


    // 监听窗口大小变化事件，调整是否显示省略号
    useEffect(() => {
        const resize = resizeMethod;
        window.addEventListener('resize', resize);
        // willUnmount
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [showEllipsis, resizeMethod]);

    return (
        <A
            className="paddle-hub-model-item"
            href={`/hubdetail?name=${name}&en_category=${enCategory}`}
        >
            <div className="paddle-hub-model-item-name">
                {name}
                {hot === 2
                    && (
                        <Tag className="paddle-hub-model-item-tag" color="volcano">当月首推</Tag>
                    )
                }
                {hot === 1
                    && (
                        <Tag className="paddle-hub-model-item-tag" color="volcano">推荐</Tag>
                    )
                }
            </div>
            <div className="paddle-hub-model-item-info">
                {!!contributor
                    && (
                        <span className="paddle-hub-model-item-contributor">
                            <span>贡献者：</span>{contributor}
                        </span>
                    )
                }
                {!!category
                    && (
                        <span className="paddle-hub-model-item-category">
                            <span>类别：</span>{category}
                        </span>
                    )
                }
                {!!network
                    && (
                        <span className="paddle-hub-model-item-network">
                            <span>网络：</span>{network}
                        </span>
                    )
                }
                {!!dataset
                    && (
                        <span className="paddle-hub-model-item-dataset">
                            <span>数据集：</span>{dataset}
                        </span>
                    )
                }
            </div>
            <div className="paddle-hub-model-item-summary" ref={summaryRef}>
                <div className="paddle-hub-model-item-summary-inner" ref={summaryInnerRef}>
                    {summary}
                </div>
                {
                    !!showEllipsis
                    && <div className="paddle-hub-model-item-summary-ellipsis">...</div>
                }
            </div>
            <div className="paddle-hub-model-item-more-info">
                {!!update_time
                    && <span>更新：{update_time}</span>
                }
                {!!recommendation
                    && (
                        <span>
                            推荐指数：
                            <span className="paddle-hub-model-item-more-info-recommendation-number">
                                {recommendation}
                            </span>
                            分
                        </span>
                    )
                }
            </div>
        </A>
    );
};

export default PaddleHubModelItem;
