/**
 * @file paddlehub 混合demo api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';
import UploadBosClient from '../../../../utils/bos-client';

// 请求视频模型
interface IGetVideoResultParams {
    object_key?: string;
    url?: string;
}

export const fetchGetVideoResult = (
    params: IGetVideoResultParams,
    model: string
): Promise<request.Response> => {
    return request
        .post(`/paddlehub-api/videotag/${model}`)
        .send(params);
};


// 请求图片模型
interface IGetPictureDemoResultParams {
    image?: string;
    image_url?: string;
    model_select: string[];
}

export const fetchGetPictureResult = (
    params: IGetPictureDemoResultParams
): Promise<request.Response> => {
    return request
        .post('/paddlehub-api/photo_restoration/photo_restoration')
        .send(params);
};

// 更换图片类型
interface ITransImgStyle {
    style?: string;
    image_url?: string;
}

export const fetchImageStyle = (
    params: ITransImgStyle
): Promise<request.Response> => {
    return request
        .post('/transfer/image/style')
        .send(params);
};


interface IBosConfig {
    endpoint: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    fileKey: string;
    bucketName: string;
}

interface IUploadToBosOptions {
    file: File;
    onProgress?: (percent: number) => any;
}

// 分片上传文件到bos
export const uploadToBos = async (options: IUploadToBosOptions) => {
    // 获取config
    const res = await request.get('/paddlehub/bosacl');
    const config: IBosConfig = res.body.result || {};
    const client = new UploadBosClient({
        bucketName: config.bucketName,
        fileKey: config.fileKey,
        ak: config.accessKeyId,
        sk: config.secretAccessKey,
        sessionToken: config.sessionToken,
        endpoint: config.endpoint,

        file: options.file,
        onProgress: options.onProgress
    });
    client.start();
    return client;
};
