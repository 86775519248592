/**
 * @file PaddleHub 首页教学卡片
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {ITextCard2Component} from '../../types';
import A from '../../../../components/a/A';

const TextCards: React.FC<ITextCard2Component> = props => {
    const {list, linkMore} = props;

    return (
        <div className="paddle-text-card2-wrap">
            <div className="paddle-text-card2-group clear-both">
                {list.map(item => (
                    <A
                        key={item.title}
                        className="paddle-text-card2"
                        href={item.links.href}
                    >
                        <div className="paddle-text-card2-head">
                            <div className="paddle-text-card2-title">
                                {item.title}
                            </div>
                        </div>
                        <div className="paddle-text-card2-body">
                            <div className="paddle-text-card2-context">
                                {item.content}
                            </div>
                            <div className="paddle-text-card2-tags">
                                <span>
                                    {item.links.label}
                                </span>
                            </div>
                        </div>
                    </A>
                ))}
            </div>
            {!!(linkMore && linkMore.label)
            && (
                <div className="paddle-text-card2-link">
                    <a href={linkMore.href}>
                        {linkMore.label} <i className="paddle-text-card2-right-icon" />
                    </a>
                </div>
            )}
        </div>
    );
};

export default TextCards;
