/**
 * @file 获取 innerText
 * @author FengGuang(fengguang01@baidu.com)
 */

const innerText = (el?: HTMLElement | null): string => {
    return el ? (el.innerText || el.textContent || el.innerHTML) : '';
};

export default innerText;
