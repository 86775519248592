/**
 * @file 数字增长动画
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

const numberFormat = (num: string | number) => {
    let theNum: number | undefined;
    if (typeof (num) === 'number') {
        theNum = num;
    }
    if (typeof (num) === 'string' && !isNaN(parseFloat(num))) {
        theNum = parseFloat(num);
    }

    if (theNum !== undefined) {
        if (theNum <= 0) {
            return `${theNum}`;
        }
        if (theNum / 100000000 > 1) {
            return `${Math.floor(theNum / 100000000)}亿 +`
        }
        if (theNum / 10000000 > 1) {
            return `${Math.floor(theNum / 10000000)}千万 +`
        }
        if (theNum / 1000000 > 1) {
            return `${Math.floor(theNum / 1000000)}百万 +`
        }
        if (theNum / 10000 > 1) {
            return `${Math.floor(theNum / 10000)}万 +`
        }
        return `${theNum}`;
    }
    return '';
}

export default numberFormat;
