/**
 * @file 搜索框
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { FC, isValidElement, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useLatest } from 'react-use';
import { AutoComplete, AutoCompleteProps, Input } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { fetchPostSearchInfo } from '../../api/components/headerApi';

import A from '../a/A';

import { useHotKeyWords, useRecentSearchWords, useSearchSug, useValue } from './searchACHooks';

import { ISearchACProps } from './types';

const SearchAC: FC<ISearchACProps> = props => {
    const { getPopupContainer, setShowMenu } = props;

    const searchVersionList = useMemo(() => ['develop', '2.4', '2.3', '2.2', '2.1', '2.0', '1.8'], []);

    const [open, setOpen] = useState<boolean>(false);

    const { hotKeyWords } = useHotKeyWords();
    const { recentSearchWords, addRecentSearchWords } = useRecentSearchWords();

    const { searchSug, loading, onSearchChange, submitSearch } = useSearchSug();

    const { value, setValue } = useValue();
    const valueRef = useLatest(value);

    const handleValue = (value: string): string => {
        if (value.includes('-search-result-handle-')) {
            return value.split('-search-result-handle-')[0];
        }
        return value;
    };

    const onChange = useCallback<NonNullable<AutoCompleteProps['onChange']>>(
        value => {
            setValue(handleValue(value));
            onSearchChange(handleValue(value));
        },
        [setValue, onSearchChange]
    );

    const returnVersion = useCallback(
        (pathname: string): string => {
            for (let item of searchVersionList) {
                if (pathname.includes(item)) {
                    return item;
                }
            }
            return '2.4';
        },
        [searchVersionList]
    );

    const onSelect = useCallback<NonNullable<AutoCompleteProps['onSelect']>>(
        (value: any, option: any) => {
            const urlParams = new URLSearchParams(window.location.search.slice(1));
            const docVersion = returnVersion(window.location.pathname);
            if (value) {
                fetchPostSearchInfo({
                    searchQuery: handleValue(value),
                    searchType: 1,
                    resultOrder: option.dataIndex,
                    resultUrl: option.link,
                    resultTitle: option.type,
                    version: urlParams.get('version') || docVersion || '2.4'
                }).catch(() => {
                    // ignore
                });
                addRecentSearchWords(handleValue(value));
                submitSearch(handleValue(value), option);
            }
        },
        [addRecentSearchWords, submitSearch, returnVersion]
    );

    const onOpen = useCallback(
        open => {
            setShowMenu(!open);
            //设置当搜索栏宽度变大时隐藏左侧menu
            if (open) {
                onSearchChange(valueRef.current);
            }
            setOpen(open);
        },
        [onSearchChange, valueRef, setShowMenu]
    );

    const handleOnSubmitSearch = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search.slice(1));
        const docVersion = returnVersion(window.location.pathname);
        fetchPostSearchInfo({
            searchQuery: value,
            searchType: 2,
            version: urlParams.get('version') || docVersion || '2.4'
        }).catch(() => {
            // ignore
        });
        addRecentSearchWords(value);
        submitSearch(valueRef.current);
    }, [addRecentSearchWords, submitSearch, returnVersion, value, valueRef]);

    const valueIsNull = !value;
    const options = useMemo(() => {
        if (valueIsNull) {
            let i = 0;
            const res = [
                {
                    label: '热门搜索',
                    options: hotKeyWords.map(str => ({
                        key: `${str},${i++}`,
                        label: str,
                        value: str
                    }))
                }
            ];
            if (recentSearchWords.length > 0) {
                res.unshift({
                    label: '最近搜索',
                    options: recentSearchWords.map(str => ({
                        key: `${str},${i++}`,
                        label: str,
                        value: str
                    }))
                });
            }

            return res;
        }

        if (loading) {
            return [
                {
                    label: '',
                    value: ''
                }
            ];
        }

        return searchSug.map(group => {
            const theOpotions: any[] = group.options;
            return {
                ...group,
                options: theOpotions.map((item, index) => {
                    return {
                        ...item,
                        dataIndex: index + 1,
                        label: (
                            <A className="paddle-head-search-ac-overlay-item">
                                <span
                                    className="paddle-head-search-ac-overlay-item-title"
                                    {...{ dangerouslySetInnerHTML: { __html: item.label } }}
                                ></span>
                                {!!item.type && (
                                    <>
                                        <div className="paddle-head-search-ac-overlay-item-divider" />
                                        <span
                                            style={{ width: '160px' }}
                                            className="paddle-head-search-ac-overlay-item-type .paddle-head-search-ac-overlay-ellipsis"
                                        >
                                            {item.type}
                                        </span>
                                        <span
                                            className="paddle-head-search-ac-overlay-item-type .paddle-head-search-ac-overlay-ellipsis"
                                            {...{ dangerouslySetInnerHTML: { __html: item.compareDesc } }}
                                        ></span>
                                    </>
                                )}
                            </A>
                        )
                    };
                })
            };
        });
    }, [valueIsNull, hotKeyWords, recentSearchWords, searchSug, loading]);

    return (
        <AutoComplete
            className={classNames(props.className, 'paddle-head-search-ac')}
            value={value}
            getPopupContainer={getPopupContainer}
            open={open}
            onDropdownVisibleChange={onOpen}
            listHeight={800}
            options={options}
            onChange={onChange}
            dropdownMatchSelectWidth={800}
            dropdownAlign={{ points: ['br', 'tr'], offset: [0, 1] }}
            dropdownStyle={{ marginTop: 10 }}
            onSelect={onSelect}
            notFoundContent={' '}
            dropdownRender={menu => (
                <div
                    className={classNames({
                        'paddle-head-search-ac-overlay': true,
                        'paddle-head-search-ac-overlay-type1': valueIsNull,
                        'paddle-head-search-ac-overlay-type2': !valueIsNull
                    })}
                >
                    {loading ? (
                        <div className="paddle-head-search-ac-overlay-loading-wrap">
                            <LoadingOutlined />
                        </div>
                    ) : (
                        <>
                            <div>{menu}</div>
                            {!valueIsNull && (
                                <>
                                    <div className="paddle-head-search-ac-overlay-divider" />
                                    <div className="paddle-head-search-ac-overlay-more">
                                        <A onClick={handleOnSubmitSearch}>查看更多关于“{value}”的结果</A>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        >
            {(() => {
                if (props.children && isValidElement(props.children)) {
                    return React.cloneElement(props.children, {
                        //@ts-ignore
                        prefix: <SearchOutlined />,
                        allowClear: open,
                        bordered: open,
                        onPressEnter: handleOnSubmitSearch,
                        placeholder: '请输入搜索关键词'
                    });
                } else if (props.children) {
                    return props.children;
                }

                return (
                    <Input
                        prefix={<SearchOutlined />}
                        allowClear={open}
                        bordered={open}
                        onPressEnter={handleOnSubmitSearch}
                        placeholder="请输入搜索关键词"
                    />
                );
            })()}
        </AutoComplete>
    );
};

export default SearchAC;
