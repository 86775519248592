/**
 * @file paddlehub demo 新冠肺炎医疗影像 hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useState} from 'react';
import {useMount} from 'react-use';
import {UploadChangeParam} from 'antd/es/upload/interface';

import useUploadHooks from './components/upload-btn/uploadHooks';
import useImgHooks from './components/main-img/mainImgHooks';

import {getDefaultData, submitUploadFile} from './medicalImageApi';
import useLesionTableHooks from './components/lesion-table/lesionTableHooks';
import messageSuccess from './components/message-success/messageSuccess';
import useLungChartHooks from './components/lung-chart/lungChartHooks';
import useUserInstructionsModalHooks from './components/medical-img-modal/userInstructionsModalHooks';
import mModal from './components/medical-img-modal/medicalImgModal';


const useMedicalImageHooks = () => {
    const imgHooks = useImgHooks();
    const lesionTableHooks = useLesionTableHooks();
    const lungChartHooks = useLungChartHooks();
    const userInstructionsModalHooks = useUserInstructionsModalHooks();
    // 将上传的hooks提取到useUploadHooks里，
    // 但是又能改变 loading 属性的行为
    const uploadHooks = useUploadHooks();
    // 覆盖 uploadHooks 的 loading 状态
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    const imgHooksSetImgList = imgHooks.setImgList;
    const imgHooksSetSourceImgList = imgHooks.setSourceImgList;
    const uploadHooksOnChange = uploadHooks.onChange;
    const imgHooksOnImgSelectedChange = imgHooks.onImgSelectedChange;
    const lesionTableHooksSetTableData = lesionTableHooks.setTableData;
    const lungChartHooksSetStatistics = lungChartHooks.setStatistics;
    // 上传完成后触发解析图片请求并获得结果
    const onUploadChange = useCallback(
        (info: UploadChangeParam) => {
            const {file, fileList} = info;
            uploadHooksOnChange(info);
            if (file.status === 'uploading') {
                setUploadLoading(true);
            }
            else if (file.status === 'error') {
                mModal({
                    title: '导入失败',
                    content: '导入文件失败，未找到DCN文件',
                    okText: '确定'
                });
                setUploadLoading(false);
            }
            else if (file.status === 'done') {
                (async () => {
                    const fileKey = file.response?.body?.key ?? '';
                    let addFileRes;
                    let getResultRes;
                    try {
                        [addFileRes, getResultRes] = await submitUploadFile(fileKey);
                    }
                    catch (err: any) {
                        // ignore
                    }
                    const uploadingFileList = fileList.filter(item => item.status === 'uploading');
                    if (uploadingFileList.length === 0) {
                        setUploadLoading(false);
                    }
                    if ([400, 500].indexOf(addFileRes?.body?.code) > -1) {
                        const addFileErrorMsg = addFileRes?.body?.msg;
                        mModal({
                            title: '导入失败',
                            content: addFileErrorMsg,
                            okText: '确定'
                        });
                        return;
                    }
                    if ([400, 500].indexOf(getResultRes?.body?.code) > -1) {
                        const getResultErrorMsg = getResultRes?.body?.msg;
                        mModal({
                            title: '导入失败',
                            content: getResultErrorMsg,
                            okText: '确定'
                        });
                        return;
                    }
                    if (getResultRes && getResultRes.body) {
                        const resultImageList: string[] = getResultRes.body.result?.resultImageList ?? [];
                        const sourceImageList: string[] = getResultRes.body.result?.sourceImageList ?? [];
                        const list = resultImageList.map((url, index) => ({
                            key: '' + index,
                            url
                        }));
                        const sourceList = sourceImageList.map((url, index) => ({
                            key: '' + index,
                            url
                        }));
                        messageSuccess({
                            content: `导入成功，载入${list.length}张CT影像`
                        });
                        imgHooksSetImgList(list);
                        imgHooksSetSourceImgList(sourceList);
                        imgHooksOnImgSelectedChange(list[0]?.key ?? '');

                        const resultData = getResultRes.body.result?.resultData;
                        lesionTableHooksSetTableData(resultData);
                        lungChartHooksSetStatistics(resultData);
                    }
                })();
            }
        },
        [
            setUploadLoading,
            uploadHooksOnChange,
            imgHooksSetImgList,
            imgHooksSetSourceImgList,
            imgHooksOnImgSelectedChange,
            lesionTableHooksSetTableData,
            lungChartHooksSetStatistics
        ]
    );

    const userInstructionsModalHooksOnShow = userInstructionsModalHooks.onShow;
    const uploadHooksBefoeUpload = useCallback(() => {
        return userInstructionsModalHooksOnShow();
    }, [userInstructionsModalHooksOnShow]);

    // 进入页面时加载默认的图像
    const initData = useCallback(
        () => {
            (async () => {
                let res;
                try {
                    res = await getDefaultData();
                }
                catch (err: any) {
                    mModal({
                        title: '导入失败',
                        content: '导入文件失败，未找到DCN文件',
                        okText: '确定'
                    });
                }
                if (res && res.body) {
                    const resultImageList: string[] = res.body.result?.resultImageList ?? [];
                    const sourceImageList: string[] = res.body.result?.sourceImageList ?? [];
                    const list = resultImageList.map((url, index) => ({
                        key: '' + index,
                        url
                    }));
                    const sourceList = sourceImageList.map((url, index) => ({
                        key: '' + index,
                        url
                    }));
                    messageSuccess({
                        content: `导入成功，载入${list.length}张CT影像`
                    });
                    imgHooksSetImgList(list);
                    imgHooksSetSourceImgList(sourceList);
                    imgHooksOnImgSelectedChange(list[0]?.key ?? '');

                    const resultData = res.body.result?.resultData;
                    lesionTableHooksSetTableData(resultData);
                    lungChartHooksSetStatistics(resultData);
                }
            })();
        },
        [
            imgHooksSetImgList,
            imgHooksSetSourceImgList,
            imgHooksOnImgSelectedChange,
            lesionTableHooksSetTableData,
            lungChartHooksSetStatistics
        ]
    );
    useMount(initData);

    return {
        imgHooks,
        uploadHooks: {
            ...uploadHooks,
            loading: uploadLoading,
            onChange: onUploadChange,
            beforeUpload: uploadHooksBefoeUpload
        },
        lesionTableHooks,
        lungChartHooks,
        userInstructionsModalHooks
    };
};

export default useMedicalImageHooks;
