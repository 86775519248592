/**
 * @file 导航栏的api
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';

import baseRequest2 from '../baseApi2';

/**
 * 获取搜索结果
 *
 * @param {string} kw 搜索的关键字
 * @return {Object} promise对象
 */
interface IDocInfoProps {
    language?: 'zh' | 'en';
    version?: string;
}

export const fetchGetHeaderSearchSug = (kw: string, docInfo: IDocInfoProps): Promise<request.Response> => {
    const theDocInfo: IDocInfoProps = {
        ...docInfo,
        ...(window as any).docInfo
    };
    const windowDocInfo = (window as any).docInfo || {};
    if ('lang' in windowDocInfo) {
        theDocInfo.language = windowDocInfo.lang;
    }
    return baseRequest2(
        request.get('/searchapi').query({
            q: kw,
            language: theDocInfo.language,
            version: theDocInfo.version
        })
    );
};

export const fetchGetUserState = () => baseRequest2(request.post('/user/checkLogin'));

interface IPostSearchInfoParams {
    searchQuery?: string;
    searchType?: number;
    resultOrder?: number;
    resultUrl?: string;
    resultTitle?: string;
    page?: string;
    classification?: string;
    version?: string;
}
export const fetchPostSearchInfo = (params: IPostSearchInfoParams | string) =>
    baseRequest2(request.post('/insertQueryHistory').type('form').send(params));

export const fetchGetHotWords = () =>
    baseRequest2(request.post('/platform/page/data').query({ requestUrl: '/paddleorg/hotwords' }));

export const fetchGetSearchSug = (kw: string, docInfo: IDocInfoProps): Promise<request.Response> => {
    const theDocInfo: IDocInfoProps = {
        language: 'zh',
        version: '2.6',
        ...docInfo,
        ...(window as any).docInfo
    };
    const windowDocInfo = (window as any).docInfo || {};
    if ('lang' in windowDocInfo) {
        theDocInfo.language = windowDocInfo.lang;
    }
    return baseRequest2(
        request.get('/searchSuggest').query({
            q: kw,
            language: theDocInfo.language,
            version: theDocInfo.version
        })
    );
};
