/**
 * @file paddlehub demo 新冠肺炎医疗影像 通用消息弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback, useState} from 'react';
import ReactDOM from 'react-dom';
import {Button, Modal} from 'antd';
import classNames from 'classnames';
import {ModalFuncProps, ModalProps} from 'antd/es/modal/Modal';

const ConformModal: React.FC<ModalProps> = props => {
    const [visible, setVisible] = useState<boolean>(true);
    const onOk = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    return (
        <Modal
            visible={visible}
            onOk={onOk}
            onCancel={onOk}
            footer={<Button onClick={onOk} type="primary">{props.okText}</Button>}
            {...props}
            className={classNames(props.className, 'medical-img-modal')}
        />
    );
};

export const mModal = (props: ModalFuncProps) => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const {content, ...restProps} = props;

    ReactDOM.render(
        <ConformModal
            width={400}
            {...restProps}
            afterClose={() => {
                ReactDOM.unmountComponentAtNode(div);
            }}
        >
            {content}
        </ConformModal>,
        div
    );
};

export default mModal;
