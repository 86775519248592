import request from 'superagent';
import baseApi2 from '../baseApi2';
export interface IGetPaddleParam {
    [key: string]: any;
}
// 获取英文转音频
export const fetchGetPerformEnglishAudioTransfer = (params: IGetPaddleParam | string): Promise<request.Response> =>
    baseApi2(
        request.post(`/speech/fastspeech2_ljspeech`)
            .send(params)
            .query(window.location.search.slice(1))
            .set("Content-Type", "application/json")
    );

// 获取中文转音频
export const fetchGetPerformChineseAudioTransfer = (params: any): Promise<request.Response> =>
    // console.log(JSON.stringify(params ), "JSON.stringify({params})");

    baseApi2(
        request.post('/speech/fastspeech2_baker')
            .send(params)
            .query(window.location.search.slice(1))
            .set("Content-Type", "application/json")
    );
// 获取音频转中文
export const fetchGetPerformAudioChineseTransfer = (params: IGetPaddleParam | string): Promise<request.Response> =>
    baseApi2(
        request.post(`/speech/u2_conformer_aishell`)
            .send(params)
            // .query('format=json')
            .set("Content-Type", "application/json")
            .query(window.location.search.slice(1))
    );

// 获取音频英文
export const fetchGetPerformAudioEnglishTransfer = (params: IGetPaddleParam | string): Promise<request.Response> =>
    baseApi2(
        request.post('/speech/u2_conformer_librispeech')
            // .query(params)
            .send(params)
            // .query('format=json')
            .set("Content-Type", "application/json")
            .query(window.location.search.slice(1))
    );