/**
 * @file paddlehub demo ai写作 api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';

interface IFetchGetAiWritingParams {
    texts: string;
}

export const fetchGetAiWritingResult = (params: IFetchGetAiWritingParams, model: string): Promise<request.Response> => {
    return request
        .post(`/paddlehub-api/poetry/${model}`)
        .send(params);
};
