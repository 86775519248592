/**
 * @file paddlehub demo api
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';

interface IGetLexicalAnalysisResultParams {
    text: string[];
}

export const fetchGetLexicalAnalysisResult = (
    model: string,
    params: IGetLexicalAnalysisResultParams
): Promise<request.Response> => {
    return request
        .post(`/paddlehub-api/lexical_analysis/${model}`)
        .send(params);
};
