/**
 * @file PaddleHub 首页教学卡片
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import { Col, Row } from 'antd';
import { IProjectTextCard } from '../../types';
import A from '../../../../components/a/A';

const TextCards: React.FC<IProjectTextCard> = props => {
    const { list, linkMore } = props;

    return (
        <div className="paddle-text-card2-wrap">
            <Row className="paddle-text-card2-group" gutter={[24, 24]}>
                {list.map(item => (
                    <Col key={item.title} span={12}>
                        <A
                            className="paddle-text-card2"
                            href={item.links.href}
                        >
                            <div className="paddle-text-card2-head">
                                <div className="paddle-text-card2-title">
                                    {item.title}
                                </div>
                                <div className="paddle-text-card2-info">
                                    {!!item.prev
                                        && (
                                            <span className="paddle-text-card2-info-item">
                                                <i className="paddle-text-card2-info-icon paddle-text-card2-info-icon-prev" />
                                                <span>{item.prev}</span>
                                            </span>
                                        )
                                    }
                                    {!!item.fork
                                        && (
                                            <span className="paddle-text-card2-info-item">
                                                <i className="paddle-text-card2-info-icon paddle-text-card2-info-icon-fork" />
                                                <span>{item.fork}</span>
                                            </span>
                                        )
                                    }
                                    {!!item.star
                                        && (
                                            <span className="paddle-text-card2-info-item">
                                                <i className="paddle-text-card2-info-icon paddle-text-card2-info-icon-star" />
                                                <span>{item.star}</span>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="paddle-text-card2-body">
                                <div
                                    className="paddle-text-card2-context"
                                    {...{ dangerouslySetInnerHTML: { __html: item.content } }}
                                >
                                </div>
                                <div className="paddle-text-card2-tags">
                                    <span>
                                        {item.links.label}
                                    </span>
                                </div>
                            </div>
                        </A>
                    </Col>
                ))}
            </Row>
            {!!(linkMore && linkMore.label)
                && (
                    <div className="paddle-text-card2-link">
                        <a href={linkMore.href}>
                            {linkMore.label} <i className="paddle-text-card2-right-icon" />
                        </a>
                    </div>
                )}
        </div>
    );
};

export default TextCards;
