/**
 * @file 获取滚动高度
 * @author FengGuang(fengguang01@baidu.com)
 */

export const getScrollTop = (): number => {
    return document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop || 0;
};

export const getScrollLeft = (): number => {
    return document.documentElement.scrollLeft || window.pageXOffset || document.body.scrollLeft || 0;
};

export const getScrollHeight = (): number => {
    return document.documentElement.scrollHeight || document.body.scrollHeight || 0;
};

export const getScrollWidth = (): number => {
    return document.documentElement.scrollWidth || document.body.scrollWidth || 0;
};

export const getClientHeight = (): number => {
    if (document.compatMode === 'CSS1Compat') {
        return document.documentElement.clientHeight;
    }
    if (document.compatMode === 'BackCompat') {
        return document.body.clientHeight;
    }
    return document.documentElement.clientHeight || document.body.clientWidth || 0;
};

export const getClientWidth = (): number => {
    if (document.compatMode === 'CSS1Compat') {
        return document.documentElement.clientWidth;
    }
    if (document.compatMode === 'BackCompat') {
        return document.body.clientWidth;
    }
    return document.documentElement.clientWidth || document.body.clientWidth || 0;
};

export default function getScroll() {
    return {
        scrollTop: getScrollTop(),
        scrollLeft: getScrollLeft()
    };
}
