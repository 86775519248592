/**
 * @file 获取图片尺寸
 * @author FengGuang(fengguang01@baidu.com)
 */


/**
 * 快速获取图片尺寸
 * @param {File | String} url 图片文件或者图片的url或者base64数据
 * @returns {Promise} then {width,height,img}
 */
const getImageSize = async (url: File | string): Promise<{ width: number; height: number }> => {
    let imgUrl = '';

    if (typeof (url) === 'string') {
        imgUrl = url;
    }
    else {
        imgUrl = await (new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const result = fileReader.result;
                if (typeof (result) === 'string') {
                    resolve(result);
                }
                else {
                    reject('not image');
                }
            };
            fileReader.onerror = (err) => {
                reject(err);
            };
            fileReader.readAsDataURL(url);
        }));
    }

    return new Promise((resolve) => {
        const img = new Image();
        img.src = imgUrl;

        if (img.complete) {
            resolve({
                width: img.width || 0,
                height: img.height || 0
            });
        }
        else {
            const checkHandle = setInterval(() => {
                if (img.width > 0 || img.height > 0) {
                    resolve({
                        width: img.width || 0,
                        height: img.height || 0
                    });
                    clearInterval(checkHandle);
                }
            }, 50);

            img.onload = () => {
                resolve({
                    width: img.width || 0,
                    height: img.height || 0
                });
                clearInterval(checkHandle);
            };
        }
    });
};

export default getImageSize;
