/**
 * @file 固钉组件
 * 本组件需要在其外层包裹一层 position: relative 的div，
 * 并且div高度为可滑动的范围，以便让子元素在此范围内滑动
 * 被悬浮的目标不要使用 margin 属性，因为会导致计算不准确
 * @author FengGuang(fengguang01@baidu.com)
 */


/**
 * 使用方法
 *
 * <ReactStickyPolyfill>
 *     <div class="target"></div> // 本组件只会对第一个子元素模拟 sticky。
 * </ReactStickyPolyfill>
 *
 * <style>
 *     .target {
 *         position: static; // 如果不支持 sticky，则回落为 static
 *         position: sticky; // 如果浏览器支持 sticky 则会使用原生 sticky 样式
 *     }
 * </style>
 */

import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';

import { getClientHeight, getClientWidth, getScrollLeft, getScrollTop } from '../../utils/get-scroll';
import { IPosition, IStickyOption, ITargetOriginStyle, IWatershed } from './type';
import throttleRaf from '../../utils/throttle-raf';

// 是否支持 position:sticky
export const supportSticky = (() => {
    if (!window.getComputedStyle) {
        return true;
    }
    else {
        const testNode = document.createElement('div');

        if (
            ['', '-webkit-', '-moz-', '-ms-', '-o-'].some(prefix => {
                try {
                    testNode.style.position = prefix + 'sticky';
                }
                catch (e) {
                }

                return testNode.style.position !== '';
            })
        ) {
            return true;
        }
    }
    return false;
})();

let updateList: ((immediate?: boolean) => void)[] = [];

export const updateAll = (immediate: boolean = false) => {
    updateList.forEach(up => up(immediate));
};

interface IReactStickyFillForwardRef {
    update: () => void;
}

interface IReactStickyFillProps {
    autoUpdate?: boolean;
    forceUsePolyfill?: boolean;
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const useDidUpdateEffect = (effect: () => void, deps?: readonly any[]) => {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) effect();
        else didMountRef.current = true;
    }, deps); // eslint-disable-line
};

const ReactStickyPolyfill = React.forwardRef<IReactStickyFillForwardRef, IReactStickyFillProps>((props, ref) => {
    // 占位元素，用来占据空位以便计算“原来位置”
    const anchorRef = useRef<HTMLDivElement>(null);
    // 要悬浮的元素，悬浮以后会脱离文档流
    const targetRef = useRef<HTMLElement>();
    // 父元素，用于计算相对位置
    const parentRef = useRef<HTMLElement>();

    useEffect(() => {
        if (anchorRef.current) {
            targetRef.current = anchorRef.current.nextElementSibling as HTMLElement || undefined;
            parentRef.current = anchorRef.current.parentNode as HTMLElement || undefined;
        }
    }, [props.children]);


    const usePolyfill = props.forceUsePolyfill || !supportSticky;

    // 是否自动更新元素的位置。如果为 false，则继续监听 target 的属性但是不设置 target 的位置。
    // 以便父组件调用 update 时可以完成更新
    const autoUpdate = ('autoUpdate' in props) ? !!props.autoUpdate : true;


    // 吸边模式，是吸左、吸右还是吸上、吸下
    const stickyOptionRef = useRef<IStickyOption>({
        horizontalModel: '',
        verticalModel: ''
    });

    // target 的 style 属性
    const targetOriginStyleRef = useRef<ITargetOriginStyle>({});

    // 缓存元素的转折点
    const watershedRef = useRef<IWatershed>({
        horizontal: [0, 0],
        vertical: [0, 0]
    });
    const parentOriginPositionRef = useRef<IPosition>({
        width: 0,
        height: 0,
        offsetLeft: 0,
        offsetTop: 0
    });
    const targetOriginPositionRef = useRef<IPosition>({
        width: 0,
        height: 0,
        offsetLeft: 0,
        offsetTop: 0
    });

    // 计算当前元素的吸边模式
    const getStickyMode = useMemo(() => {
        const method = () => {
            const parentDom = parentRef.current;
            const anchorDom = anchorRef.current;
            const targetDom = targetRef.current;
            if (anchorDom && targetDom && parentDom) {
                const theStyle = window.getComputedStyle(targetDom);
                const display = theStyle.getPropertyValue('display');
                if (display !== 'block' && display !== 'inline-block') {
                    return;
                }

                const targetOriginStyle = targetOriginStyleRef.current;

                targetOriginStyle.display = theStyle.getPropertyValue('display');
                targetOriginStyle.position = theStyle.getPropertyValue('position');
                // 因为本组件会设置inline样式操作元素位置，
                // 所以当元素设置了inline样式就忽略掉
                if (!targetDom.style.top) {
                    const value = theStyle.getPropertyValue('top');
                    targetOriginStyle.top = value === 'auto'
                        ? undefined
                        : parseInt(value);
                }
                if (!targetDom.style.bottom) {
                    const value = theStyle.getPropertyValue('bottom');
                    targetOriginStyle.bottom = value === 'auto'
                        ? undefined
                        : parseInt(value);
                }
                if (!targetDom.style.left) {
                    const value = theStyle.getPropertyValue('left');
                    targetOriginStyle.left =
                        targetOriginStyle.bottom = value === 'auto'
                            ? undefined
                            : parseInt(value);
                }
                if (!targetDom.style.right) {
                    const value = theStyle.getPropertyValue('right');
                    targetOriginStyle.right =
                        targetOriginStyle.bottom = value === 'auto'
                            ? undefined
                            : parseInt(value);
                }

                const parentRect = parentDom.getBoundingClientRect();
                // 如果 target 是 block 样式，则让 anchor 也变成 block，
                // 以便从 anchor 读取宽度然后设置到 target 上
                if (targetOriginStyle.display === 'block') {
                    anchorDom.style.display = 'block';
                    targetDom.style.maxWidth = '';
                }
                // 如果 target 是 inline-block 样式，则让 anchor 也变成 inline-block 样式
                // 并限制 target 的最大宽度。
                else if (targetOriginStyle.display === 'inline-block') {
                    anchorDom.style.display = 'inline-block';
                    targetDom.style.maxWidth = `${parentRect.width}px`;
                }

                const stickyOption = stickyOptionRef.current;
                // 如果 target 是 block 样式，则水平方向上肯定没有吸边效果
                if (targetOriginStyle.display === 'block') {
                    stickyOption.horizontalModel = '';
                }
                else if (targetOriginStyle.left !== undefined) {
                    stickyOption.horizontalModel = 'left';
                }
                else if (targetOriginStyle.right !== undefined) {
                    stickyOption.horizontalModel = 'right';
                }
                stickyOption.verticalModel = '';
                if (targetOriginStyle.top !== undefined) {
                    stickyOption.verticalModel = 'top';
                }
                else if (targetOriginStyle.bottom !== undefined) {
                    stickyOption.verticalModel = 'bottom';
                }
            }
        };

        const throttleMethod = throttle(method, 1000, { leading: false });

        return () => {
            if (!targetOriginStyleRef.current.display) {
                method();
            }
            else {
                throttleMethod();
            }
        };
    }, []);

    // 计算元素的位置信息
    const getPosition = useMemo(() => {
        const method = () => {
            const parentDom = parentRef.current;
            const anchorDom = anchorRef.current;
            const targetDom = targetRef.current;
            if (parentDom && anchorDom && targetDom) {
                const stickyOption = stickyOptionRef.current;
                const watershed = watershedRef.current;
                const parentRect = parentDom.getBoundingClientRect();
                const anchorRect = anchorDom.getBoundingClientRect();
                const targetRect = targetDom.getBoundingClientRect();
                const targetStyle = targetOriginStyleRef.current;


                const originPosition = (() => {
                    const originPositionWidth = (!targetStyle.display || targetStyle.display === 'block')
                        ? anchorRect.width
                        : targetRect.width;
                    return {
                        top: anchorRect.top,
                        left: anchorRect.left,
                        bottom: anchorRect.top + targetRect.height,
                        right: anchorRect.left + originPositionWidth,
                        height: targetRect.height,
                        width: originPositionWidth
                    };
                })();

                targetOriginPositionRef.current = {
                    width: originPosition.width,
                    height: originPosition.height,
                    offsetLeft: anchorRect.left + getScrollLeft(),
                    offsetTop: anchorRect.top + getScrollTop()
                };

                parentOriginPositionRef.current = {
                    width: parentRect.width,
                    height: parentRect.height,
                    offsetLeft: parentRect.left + getScrollLeft(),
                    offsetTop: parentRect.top + getScrollTop()
                };
                // 垂直方向
                if (stickyOption.verticalModel === 'top') {
                    const targetStyleTop = targetStyle.top || 0;
                    const scrollTop = getScrollTop();
                    watershed.vertical[0] = originPosition.top + scrollTop - targetStyleTop;
                    watershed.vertical[1] = parentRect.bottom + scrollTop - originPosition.height - targetStyleTop;
                }
                else if (stickyOption.verticalModel === 'bottom') {
                    const targetStyleBottom = targetStyle.bottom || 0;
                    const scrollTop = getScrollTop();
                    const clientHeight = getClientHeight();
                    watershed.vertical[0] = parentRect.top + scrollTop + originPosition.height - clientHeight;
                    watershed.vertical[1] = originPosition.bottom + scrollTop - clientHeight + targetStyleBottom;
                }

                // 水平方向
                if (stickyOption.horizontalModel === 'left') {
                    const targetStyleLeft = targetStyle.left || 0;
                    const scrollLeft = getScrollLeft();
                    watershed.horizontal[0] = originPosition.left + scrollLeft - targetStyleLeft;
                    watershed.horizontal[1] = parentRect.right + scrollLeft - originPosition.width - targetStyleLeft;
                }
                else if (stickyOption.horizontalModel === 'right') {
                    const targetStyleLeft = targetStyle.left || 0;
                    const scrollLeft = getScrollLeft();
                    const clientWidth = getClientWidth();
                    watershed.horizontal[0] = parentRect.left + scrollLeft + originPosition.width - clientWidth;
                    watershed.horizontal[1] = originPosition.right + scrollLeft - clientWidth + targetStyleLeft;
                }
            }
        };

        const throttleMethod = throttle(method, 200, { leading: false });
        return (immediate: boolean = true) => {
            if (immediate) {
                method();
            }
            else {
                throttleMethod();
            }
        };
    }, []);

    const updatePosition = useMemo(() => {
        const method = () => {
            const parentDom = parentRef.current;
            const anchorDom = anchorRef.current;
            const targetDom = targetRef.current;
            if (parentDom && anchorDom && targetDom) {
                const targetStyle = targetOriginStyleRef.current;
                const stickyOption = stickyOptionRef.current;
                const targetDisplay = targetStyle.display;
                const watershed = watershedRef.current;
                const targetOriginPosition = targetOriginPositionRef.current;
                const parentOriginPosition = parentOriginPositionRef.current;
                const scrollTop = getScrollTop();
                const scrollLeft = getScrollLeft();

                let horizontalState: '' | 'sticky' | 'stuck' = '';
                let verticalState: '' | 'sticky' | 'stuck' = '';

                if (stickyOption.verticalModel === 'top') {
                    if (scrollTop <= watershed.vertical[0]) {
                        verticalState = '';
                    }
                    else if (scrollTop >= watershed.vertical[1]) {
                        verticalState = 'stuck';
                    }
                    else {
                        verticalState = 'sticky';
                    }
                }
                else if (stickyOption.verticalModel === 'bottom') {
                    if (scrollTop >= watershed.vertical[1]) {
                        verticalState = '';
                    }
                    else if (scrollTop <= watershed.vertical[0]) {
                        verticalState = 'stuck';
                    }
                    else {
                        verticalState = 'sticky';
                    }
                }

                if (stickyOption.horizontalModel === 'left') {
                    if (scrollLeft <= watershed.horizontal[0]) {
                        horizontalState = '';
                    }
                    else if (scrollLeft >= watershed.horizontal[1]) {
                        horizontalState = 'stuck';
                    }
                    else {
                        horizontalState = 'sticky';
                    }
                }
                else if (stickyOption.horizontalModel === 'right') {
                    if (scrollLeft >= watershed.horizontal[1]) {
                        horizontalState = '';
                    }
                    else if (scrollLeft <= watershed.horizontal[0]) {
                        horizontalState = 'stuck';
                    }
                    else {
                        horizontalState = 'sticky';
                    }
                }


                // 显示元素的状态
                if (verticalState === '') {
                    targetDom.setAttribute('data-vertical-sticky-state', 'none');
                }
                else if (verticalState === 'sticky') {
                    targetDom.setAttribute('data-vertical-sticky-state', 'sticky');
                }
                else if (verticalState === 'stuck') {
                    targetDom.setAttribute('data-vertical-sticky-state', 'stuck');
                }
                if (horizontalState === '') {
                    targetDom.setAttribute('data-horizontal-sticky-state', 'none');
                }
                else if (horizontalState === 'sticky') {
                    targetDom.setAttribute('data-horizontal-sticky-state', 'sticky');
                }
                else if (horizontalState === 'stuck') {
                    targetDom.setAttribute('data-horizontal-sticky-state', 'stuck');
                }
                if (verticalState === 'sticky' || horizontalState === 'sticky') {
                    targetDom.style.position = 'fixed';
                    anchorDom.style.height = `${targetOriginPosition.height}px`;
                    if (targetDisplay === 'block') {
                        targetDom.style.width = `${targetOriginPosition.width}px`;
                    }
                    if (targetDisplay === 'inline-block') {
                        anchorDom.style.width = `${targetOriginPosition.width}px`;
                    }
                }
                else if (verticalState === 'stuck' || horizontalState === 'stuck') {
                    targetDom.style.position = 'absolute';
                    anchorDom.style.height = `${targetOriginPosition.height}px`;
                    if (targetDisplay === 'block') {
                        targetDom.style.width = `${targetOriginPosition.width}px`;
                    }
                    if (targetDisplay === 'inline-block') {
                        anchorDom.style.width = `${targetOriginPosition.width}px`;
                    }
                }
                else {
                    anchorDom.style.height = '';
                    anchorDom.style.width = '';
                    targetDom.style.width = '';
                    targetDom.style.top = '';
                    targetDom.style.bottom = '';
                    targetDom.style.left = '';
                    targetDom.style.right = '';
                    targetDom.style.position = '';
                }

                if (verticalState === 'sticky' && horizontalState === 'sticky') {
                }
                else if (verticalState === '' && horizontalState === 'sticky') {
                    targetDom.style.top = `${targetOriginPosition.offsetTop - scrollTop}px`;
                    targetDom.style.bottom = 'auto';
                    targetDom.style.left = '';
                    targetDom.style.right = '';
                }
                else if (verticalState === 'stuck' && horizontalState === 'sticky') {
                    if (stickyOption.verticalModel === 'top') {
                        targetDom.style.top = 'auto';
                        targetDom.style.bottom = `${getClientHeight() - parentOriginPosition.offsetTop - parentOriginPosition.height + scrollTop}px`;
                    }
                    else if (stickyOption.verticalModel === 'bottom') {
                        targetDom.style.top = `${parentOriginPosition.offsetTop - scrollTop}px`;
                        targetDom.style.bottom = 'auto';
                    }
                    targetDom.style.left = '';
                    targetDom.style.right = '';
                }
                else if (verticalState === 'sticky' && horizontalState === '') {
                    targetDom.style.top = '';
                    targetDom.style.bottom = '';
                    targetDom.style.left = `${targetOriginPosition.offsetLeft - scrollLeft}px`;
                    targetDom.style.right = 'auto';
                }
                else if (verticalState === 'sticky' && horizontalState === 'stuck') {
                    targetDom.style.top = '';
                    targetDom.style.bottom = '';
                    if (stickyOption.horizontalModel === 'left') {
                        targetDom.style.left = `${targetOriginPosition.offsetLeft - scrollLeft}px`;
                        targetDom.style.right = 'auto';
                    }
                    else if (stickyOption.horizontalModel === 'right') {
                        targetDom.style.left = 'auto';
                        targetDom.style.right = `${getClientWidth() - targetOriginPosition.offsetLeft - targetOriginPosition.width + scrollLeft}px`;
                    }
                }
                else if (verticalState === 'stuck' && horizontalState === '') {
                    if (stickyOption.verticalModel === 'top') {
                        targetDom.style.top = 'auto';
                        targetDom.style.bottom = '0px';
                    }
                    else if (stickyOption.verticalModel === 'bottom') {
                        targetDom.style.top = '0px';
                        targetDom.style.bottom = 'auto';
                    }
                    targetDom.style.left = '';
                    targetDom.style.right = '';
                }
                else if (verticalState === '' && horizontalState === 'stuck') {
                    targetDom.style.top = '';
                    targetDom.style.bottom = '';
                    if (stickyOption.horizontalModel === 'left') {
                        targetDom.style.left = 'auto';
                        targetDom.style.right = '0px';
                    }
                    else if (stickyOption.horizontalModel === 'right') {
                        targetDom.style.left = '0px';
                        targetDom.style.right = 'auto';
                    }
                }

                // if (verticalState === 'sticky' && stickyOption.verticalModel === 'top') {
                //     targetDom.style.top = '0px';
                // }
                // else {
                //     targetDom.style.top = '';
                // }
                // if (verticalState === 'sticky' && stickyOption.verticalModel === 'bottom') {
                //     targetDom.style.bottom = '0px';
                // }
                // else {
                //     targetDom.style.bottom = '';
                // }
                // if (horizontalState === 'sticky' && stickyOption.horizontalModel === 'left') {
                //     targetDom.style.left = '0px';
                // }
                // else {
                //     targetDom.style.left = '';
                // }
                // if (horizontalState === 'sticky' && stickyOption.horizontalModel === 'right') {
                //     targetDom.style.right = '0px';
                // }
                // else {
                //     targetDom.style.right = '';
                // }
            }
        };

        return throttleRaf(method, { leading: true, trailing: true });
        // return throttle(method, 16, {leading: true});
        // return method;
    }, []);

    const update = useCallback((immediate: boolean = false) => {
        getStickyMode();
        getPosition(immediate);
        autoUpdate && updatePosition();
    }, [getStickyMode, getPosition, updatePosition, autoUpdate]);
    const updateRef = useRef(update);
    updateRef.current = update;

    useEffect(() => {
        if (usePolyfill) {
            const handle = () => updateRef.current(false);

            window.addEventListener('scroll', handle);
            window.addEventListener('resize', handle);

            return () => {
                window.removeEventListener('scroll', handle);
                window.removeEventListener('resize', handle);
            };
        }
    }, [usePolyfill, update]);

    useEffect(() => {
        if (usePolyfill) {
            updateRef.current(true);
        }
    }, [usePolyfill]);

    useDidUpdateEffect(() => {
        if (usePolyfill) {
            updateRef.current();
        }
    });

    useEffect(() => {
        const parentDom = parentRef.current;
        if (!parentDom) {
            return;
        }
        const theMutationObserver = MutationObserver || (window as any).webkitMutationObserver;
        if (MutationObserver) {
            const parentObserver = new theMutationObserver((mutationsList) => {
                mutationsList.forEach(mutation => {
                    if (mutation.type === 'attributes') {
                        updateRef.current();
                    }
                });
            });
            parentObserver.observe(parentDom, { attributes: true });
            return () => {
                parentObserver.disconnect();
            };
        }
        else {
            const handle = () => updateRef.current(false);
            parentDom.addEventListener('DOMAttrModified', handle);
            return () => {
                parentDom.removeEventListener('DOMAttrModified', handle);
            };
        }
    }, []);

    useImperativeHandle(ref, () => ({
        update
    }), [update]);

    useEffect(() => {
        const updateMethod = (immediate: boolean = true) => {
            updateRef.current(immediate);
        };
        updateList.push(updateMethod);

        return () => {
            updateList = updateList.filter(item => item !== updateMethod);
        };
    }, []);

    return (
        <React.Fragment>
            <div
                className="react-sticky-anchor"
                ref={anchorRef}
            />
            {props.children}
        </React.Fragment>
    );
});

export default ReactStickyPolyfill;
