/**
 * @file ppde 的 api
 * @author FengGuang(fengguang01@baidu.com)
 */
import baseRequest2 from '../baseApi2';
import request from 'superagent';

export const fetchGetPpdeInfo = () => (
    baseRequest2(
        request.get('/platform/page/data')
            .query({requestUrl: '/paddleppde2'})
            .query(window.location.search.slice(1))
    )
);

export const fetchGetPpdeMemberInfo = () => (
    baseRequest2(
        request.get('/platform/page/data')
            .query({requestUrl: '/paddleppdeml'})
            .query(window.location.search.slice(1))
    )
);
