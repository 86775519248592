/**
 * @file paddlehub demo ai写作面板 写诗
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback} from 'react';
import {IShowPaneProps} from './types';
import ShowPane from './ShowPane';

const PoemsShowPane: React.FC<IShowPaneProps> = (props) => {
    const validate = useCallback<Exclude<IShowPaneProps['validate'], undefined>>((inputValue) => {
        if (!inputValue) {
            return ' ';
        }
        if (!(/，/).test(inputValue) || !(/。/).test(inputValue) || (/[a-zA-Z0-9]/).test(inputValue) || inputValue.length > 20) {
            return '格式错误，请输入带有逗号和句号的中文语句，上限20个字';
        }
    }, []);

    const getDataTransition = useCallback((data: any, value: string) => {
        const theR: string[] = [];
        if (typeof (data) === 'string') {
            theR.push(data);
        }
        else if (data && typeof (data.text) === 'string') {
            theR.push(data.text);
        }
        else {
            const r: any[] = Array.isArray(data) ? data : [];
            r.forEach((subR) => {
                if (Array.isArray(subR)) {
                    subR.forEach((subR2) => {
                        theR.push('' + typeof (subR2) === 'string' ? subR2 : (subR2?.text ?? ''));
                    });
                }
                else {
                    theR.push('' + typeof (subR) === 'string' ? subR : (subR?.text ?? ''));
                }
            });
        }
        return theR.map(i => `${value}${i}`);
    }, []);

    return (
        <ShowPane
            model="ernie_gen_poetry"
            inputPlaceholder="输入包括逗号和句号的句子，上限20个字"
            validate={validate}
            resultTransition={(result) => {
                const result2 = result?.replace(/。/g, '。/n');
                return (
                    <React.Fragment>
                        {result2?.split(/\/n/g).map((r, index) => (
                            <p key={index}>{r}</p>
                        ))}
                    </React.Fragment>
                );
            }}
            getDataTransition={getDataTransition}
            {...props}
        />
    );
};

export default PoemsShowPane;
