/**
 * @file 交流&合作
 * @author v_duzhengqiang
 */
import { Popover } from 'antd';
import { RootObject, Content7 } from '../../types';
import Qrcode from '../qrcode/Qrcode';
import './style.less';
const ExchangeAndCooperation = (props: RootObject["exchangeAndCooperation"]) => {
    const {
        title,
        content,
    } = props;
    return (
        <div className='p-x-ai-exchange-and-cooperation'>
            <div className='p-x-ai-exchange-and-cooperation-title'>
                {
                    title ?? ''
                }
            </div>
            <div className='p-x-ai-exchange-and-cooperation-content'>

                {
                    content.map((value: Content7, index: number) => {
                        return (
                            <div className='p-x-ai-exchange-and-cooperation-content-module' key={index}>
                                <div className='content-module-title'>
                                    {
                                        value.title ?? ''
                                    }
                                </div>
                                <div className='content-module-desc'>
                                    {
                                        value.intro ?? ''
                                    }
                                </div>
                                <div className='content-module-jump-wrapper' >
                                    {
                                        !value.skip.skipLink ?
                                            <Popover
                                                // id='my-popover'
                                                overlayClassName='my-popover'


                                                placement="top"
                                                trigger="click"
                                                content={() => <Qrcode src={value.skip.skipImg} />
                                                }>
                                                <div

                                                    className={value.skip.skipImg ? 'content-module-jump content-module-jump-hover' : 'content-module-jump content-module-jump-disabled'}


                                                >
                                                    {
                                                        value.skip.skipName ?? ''
                                                    }
                                                </div>
                                            </Popover>
                                            :

                                            <div

                                                className={value.skip.skipLink ? 'content-module-jump content-module-jump-hover' : 'content-module-jump content-module-jump-disabled'}
                                                onClick={() => value.skip.skipLink ? window.open(value.skip.skipLink) : undefined}
                                            >
                                                {
                                                    value.skip.skipName ?? ''
                                                }
                                            </div>

                                    }
                                </div>
                            </div>
                        );
                    })
                }

            </div>
        </div>
    )
}

export default ExchangeAndCooperation;