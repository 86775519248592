import ads from './ads.txt';
import gunRelated from './gun-related.txt';
import politics from './politics.txt';
import salacity from './salacity.txt';

export default [
    ads,
    gunRelated,
    politics,
    salacity
].join('\n').split('\n').filter(i => i);
