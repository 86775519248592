/**
 * @file 获取元素绝对定位
 * @author FengGuang(fengguang01@baidu.com)
 */

export function getLeft(element: HTMLElement) {
    let actualLeft = element.offsetLeft;
    let current = element.offsetParent as HTMLElement;
    while (current) {
        actualLeft += current.offsetLeft;
        current = current.offsetParent as HTMLElement;
    }
    return actualLeft;
}

export function getTop(element: HTMLElement, parentElement?: HTMLElement) {
    let actualTop = element.offsetTop;
    let current = element.offsetParent as HTMLElement;
    while (current && current !== parentElement) {
        actualTop += current.offsetTop;
        current = current.offsetParent as HTMLElement;
    }
    return actualTop;
}

export default function getOffset(element: HTMLElement, parentElement?: HTMLElement) {
    let actualLeft = element.offsetLeft;
    let actualTop = element.offsetTop;
    let current = element.offsetParent as HTMLElement;
    while (current && current !== parentElement) {
        actualLeft += current.offsetLeft;
        actualTop += current.offsetTop;
        current = current.offsetParent as HTMLElement;
    }
    return {
        top: actualTop,
        left: actualLeft
    };
}
