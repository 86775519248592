/**
 * @file 输入字符串数组，返回不重复的字符串数组。
 *       如果输入的字符串与已有字符串重复，则自动增加后缀使其不重复
 * @author FengGuang(fengguang01@baidu.com)
 */

export default class UniqueKey {
    private arrSet: Set<string>;
    private count: number;

    constructor();
    constructor(initialArr: string[]);
    constructor(...rest: string[][]) {
        this.arrSet = new Set<string>();
        this.count = 1;
        if (Array.isArray(rest[0])) {
            rest[0].forEach(item => this.add(item));
        }
    }

    add(value: string): string {
        const theValue = '' + value;
        if (this.arrSet.has(theValue)) {
            const uniqueValue = `${theValue}-${this.count}`;
            this.arrSet.add(uniqueValue);
            this.count++;
            return uniqueValue;
        }
        this.arrSet.add(theValue);
        return theValue;
    }

    delete(value: string): boolean {
        return this.arrSet.delete(value);
    }
}
