/**
 * @file paddlehub demo
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {IDemoModel} from '../types';

// 词法分析
const LexicalTextDemo = React.lazy(() => import('./lexical'));
// 口罩检测
const MaskDetectDemo = React.lazy(() => import('./maskdetect'));
// 口罩检测
const MedicalImage = React.lazy(() => import('./pneumonia'));
// 通用图片demo
const PictureDemo = React.lazy(() => import('./picturedemo'));
// 通用视频demo
const VideoDemo = React.lazy(() => import('./videodemo'));
// ai 写作
const AiWriting = React.lazy(() => import('./aiwriting'));
// paddlegan照片视频修复
const PaddleGanOldRecovery = React.lazy(() => import('./paddleganoldrecovery'));
// paddlegan混合demo
const PaddleGan = React.lazy(() => import('./paddlegan'));


const Demo: React.FC<IDemoModel> = props => {
    const {
        title,
        subtitle,
        demoPath
    } = props;

    return (
        <div className="paddle-hub-demo">
            <div className="paddle-hub-demo-page-context-h2">
                {title}
            </div>
            {!!subtitle
            && (
                <div className="paddle-hub-demo-page-context-h3">
                    {subtitle}
                </div>
            )}
            <React.Suspense fallback={<React.Fragment />}>
                {(() => {
                    switch (demoPath) {
                        case '/lexical':
                            return <LexicalTextDemo {...props} />;
                        case '/maskdetect':
                            return <MaskDetectDemo {...props} />;
                        case '/pneumonia':
                            return <MedicalImage {...props} />;
                        case '/picturedemo':
                            return <PictureDemo {...props} />;
                        case '/videodemo':
                            return <VideoDemo {...props} />;
                        case '/aiwriting':
                            return <AiWriting {...props} />;
                        case '/paddleganoldrecovery':
                            return <PaddleGanOldRecovery {...props} />;
                        case '/paddlegan':
                            return <PaddleGan {...props} />;
                    }
                })()}
            </React.Suspense>
        </div>
    );
};

export default Demo;
