/**
 * @file paddlehub 监听 size 事件同步dom元素的尺寸
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useEffect} from 'react';
import {useLatest} from 'react-use';

interface IUseOnResizeProps {
    onResize?: () => void;
}

const useOnResize = (props: IUseOnResizeProps = {}) => {
    const onResizeRef = useLatest(props.onResize);

    useEffect(() => {
        const onResize = () => {
            onResizeRef.current && onResizeRef.current();
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResizeRef]);
};
export default useOnResize;
