/**
 * @file paddlehub demo 词法分析模型信息
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Form, Select} from 'antd';
import classNames from 'classnames';
import {IDemoInfoProps} from '../../../../types';

interface IModelInfo extends IDemoInfoProps {
}

const ModelInfo: React.FC<IModelInfo> = props => {
    const {
        modelList,
        frameVersion,
        devResource
    } = props;

    return (
        <div
            className="lexical-model-info"
        >
            {!!modelList && modelList.length > 0
            && (
                <div>
                    <Form.Item
                        label="选择模型"
                        name="selectedModel"
                    >
                        <Select
                            className="lexical-model-info-select-model"
                            size="large"
                        >
                            {modelList.map(model => (
                                <Select.Option
                                    key={model.key}
                                    value={model.key}
                                >
                                    {model.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            )}
            {!!frameVersion
            && (
                <div>
                    <Form.Item label="框架版本">
                        {frameVersion}
                    </Form.Item>
                </div>
            )}
            {!!devResource
            && (
                <div>
                    <Form.Item label="开发资源">
                        {devResource.map(item => (
                            <a
                                className={classNames(
                                    'lexical-model-info-devresource-item',
                                    {'lexical-model-info-devresource-item-disabled': !item.link}
                                )}
                                key={`${item.label}${item.link}`}
                                href={item.link}
                            >
                                {item.label}
                            </a>
                        ))}
                    </Form.Item>
                </div>
            )}
        </div>
    );
};

export default ModelInfo;
