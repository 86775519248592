/**
 * @file paddlehub 通用图片 demoHooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import { useCallback, useMemo, useState } from 'react';
import { fetchGetPictureResult, fetchGetPictureResultNEWOCR } from './pictureDemoApi';
import pictureCompress, { getImgBase64 } from '../../../../utils/picture-compressor';
import { IPictureDemoProps, IResultItem } from './types';

const usePictureDemotHooks = (props?: IPictureDemoProps) => {
    const [resultImg, setResultImg] = useState<string>('');
    const [resultJson, setResultJson] = useState<string>('');
    const [resultTable, setResultTable] = useState<IResultItem[]>([]);
    const [resultCollapseTable, setResultCollapseTable] = useState<IResultItem[]>([]);


    // 可用模型列表
    const propsModelList = props?.modelList;
    const resultPanel = !!props?.resultPanel;
    const resultCollapsePanel = !!props?.resultCollapsePanel;
    const modelList = useMemo(() => {
        return propsModelList || [];
    }, [propsModelList]);

    // img 可能是文件、url、base64
    const getPictureDemoResult = useCallback((img: File | string) => {
        (async () => {
            let fileString;
            if (typeof (img) === 'string') {
                fileString = img;
            }
            else if (img.size > 2 * 1024 * 1024) {
                const minImgRes = await pictureCompress({
                    img,
                    height: 524,
                    width: 840
                });
                fileString = minImgRes.img;
            }
            else {
                fileString = await getImgBase64(img);
            }
            if (!fileString) {
                return;
            }
            setResultImg(fileString);
            setResultJson('');
            const imgBase64type = (fileString.match(/data:[^;]*;base64,/))?.[0] ?? '';
            const isBase64 = !!fileString.match(/data:[^;]*;base64,/);
            const uploadBase64 = fileString.substr(imgBase64type.length);
            const firstModel = modelList[0]?.key || '';
            const resultCollapsePanelMethod = (accept_theResult: { confidence: string | number; text: any; }[], accept_theResultArr: { score: number; name: any; }[]) => {
                accept_theResult.forEach((item: { confidence: string | number; text: any; }) => {
                    accept_theResultArr.push({
                        score: (() => {
                            if (typeof (item.confidence) === 'number') {
                                return parseFloat(item.confidence.toFixed(3));
                            }
                            if (typeof (item.confidence) === 'string') {
                                return parseFloat(item.confidence);
                            }
                            return 0;
                        })(),
                        name: item.text ?? ''
                    });
                });
            }
            let res;
            try {

                if (firstModel === 'ocr_oracle/predict') {
                    res = await fetchGetPictureResultNEWOCR({
                        image: isBase64 ? uploadBase64 : undefined,
                        image_url: isBase64 ? undefined : fileString
                    }, firstModel);
                } else {
                    res = await fetchGetPictureResult({
                        image: isBase64 ? uploadBase64 : undefined,
                        image_url: isBase64 ? undefined : fileString
                    }, firstModel);
                };
            }
            catch (err: any) {
                // ignore
            }
            if (res) {
                const resImg = res.body.base64 || '';
                if (resImg) {
                    setResultImg(`${imgBase64type || 'data:image/jpeg;base64,'}${resImg}`);
                }
                let resString;
                try {
                    resString = JSON.stringify(
                        res.body,
                        (key, value) => {
                            if (key === 'base64') {
                                const v = '' + value;
                                return v.length > 50 ? `${v.substr(0, 50)}...` : v;
                            }
                            return value;
                        },
                        2
                    );
                }
                catch (err: any) {
                    // ignore
                }
                setResultJson(resString || '');

                if (resultPanel) {
                    const theResult: any[] = Array.isArray(res.body) ? res.body : [];
                    const theResultArr: IResultItem[] = [];
                    theResult.forEach(item => {
                        const i: any[] = Object.entries(item);
                        i.forEach((item) => {
                            theResultArr.push({
                                score: (() => {
                                    if (typeof (item[1]) === 'number') {
                                        return parseFloat(item[1].toFixed(3));
                                    }
                                    if (typeof (item[1]) === 'string') {
                                        return parseFloat(item[1]);
                                    }
                                    return 0;
                                })(),
                                name: item[0] ?? ''
                            });
                        });
                    });
                    const theResultArrSort = theResultArr.sort((a, b) => b.score - a.score);
                    setResultTable(theResultArrSort);
                }

                if (resultCollapsePanel) {

                    setResultCollapseTable([]);
                    const theResult: any[] = Array.isArray(res.body?.result) ? res.body?.result : [];
                    const theResultArr: IResultItem[] = [];

                    if (theResult.length > 1) {

                        resultCollapsePanelMethod(theResult, theResultArr);

                    } else {
                        theResult.forEach(item => {

                            const i: any[] = Array.isArray(item.data) ? item.data : [];
                            resultCollapsePanelMethod(i, theResultArr);

                        });
                    };

                    setResultCollapseTable(theResultArr);
                }
            }
        })();
    }, [modelList, resultCollapsePanel, resultPanel]);
    // console.log(resultCollapseTable,"resultCollapseTable");

    const [imgGallerySelected, setImgGallerySelected] = useState<string>('');

    const propsImgGallery = props?.imgGallery;
    const imgGallery = useMemo(() => {
        const list = propsImgGallery || [];
        const first = list[0]?.img ?? '';
        setImgGallerySelected(old => {
            if (!old) {
                getPictureDemoResult(first);
            }
            return !!old ? old : first;
        });
        return list;
    }, [setImgGallerySelected, propsImgGallery, getPictureDemoResult]);

    const onImgGalleryChange = useCallback((key: string) => {
        setImgGallerySelected(old => {
            if (old !== key) {
                getPictureDemoResult(key);
            }
            return old !== key ? key : old;
        });
    }, [setImgGallerySelected, getPictureDemoResult]);

    return {
        resultImg,
        resultJson,
        resultTable,
        resultCollapseTable,
        imgGallery,
        imgGallerySelected,
        modelList,

        onImgGalleryChange,
        getPictureDemoResult
    };
};

export default usePictureDemotHooks;
