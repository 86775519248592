/**
 * @file 特殊A标签
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
import React from 'react';
import omit from 'object.omit';

interface IProps {
    [key: string]: any;

    href?: string;
    target?: string | boolean;
    children?: React.ReactNode;
}

const A: React.FC<IProps> = props => {
    let target: string | undefined;
    let rel: string | undefined;
    if (typeof (props.target) === 'string') {
        target = props.target;
    }
    else {
        target = props.target ? '_blank' : undefined;
    }

    if (target === '_blank') {
        rel = 'noopener noreferrer';
    }

    if (props.href || props.onClick) {
        return (
            <a {...props} target={target} rel={rel}>
                {props.children}
            </a>
        );
    }

    const restProps = omit(props, ['href', 'target', 'rel']);

    return (
        <span {...restProps}>
            {props.children}
        </span>
    );
};
export default A;
