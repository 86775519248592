/**
 * @file paddle cms
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
import request from 'superagent';
import baseRequest from '../baseApi';


// 获取cms的数据
export interface IGetCommunityParam {
    requestUrl?: string;
}

export const getCmsDataInfo = (params: IGetCommunityParam | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query(params)
            .query(window.location.search.slice(1))
    );