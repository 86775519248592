/**
 * @file  paddle-x-ai-落地页
 * @author v_duzhengqiang
 */
import HeaderIntro from './components/headerIntro/HeaderIntro';
import CoreAdvantage from './components/coreAdvantage.tsx/CoreAdvantage';
import ApplicationScenario from './components/applicationScenario/ApplicationScenario';
import CloudFastStart from './components/cloudFastStart/CloudFastStart';
import LocalDownload from './components/localDownload/LocalDownload';
import RelatedInformation from './components/relatedInformation/RelatedInformation';
import ExchangeAndCooperation from './components/exchangeAndCooperation/ExchangeAndCooperation';
import usePaddleXAiHook from './PaddleXAiHook';

import './style.less';

const PaddleXAi = () => {
 
    const {
        headerIntro,
        coreAdvantage,
        applicationScenario,
        cloudFastStart,
        localDownload,
        relatedInformation,
        exchangeAndCooperation
    } = usePaddleXAiHook();
    return (
        <div className="paddle-x-ai">
            {/* 头部简介 */}
            <HeaderIntro {...headerIntro}/>
            {/* 核心优势 */}
            <CoreAdvantage {...coreAdvantage}/>
            {/* 应用场景 */}
            <ApplicationScenario {...applicationScenario}/>
            {/* 云端快速开始 */}
            <CloudFastStart {...cloudFastStart}/>
            {/* 本地端下载 */}
            <LocalDownload {...localDownload}/>
            {/* 产品动态 */}
            <RelatedInformation {...relatedInformation}/>
            {/* 交流&合作 */}
            <ExchangeAndCooperation {...exchangeAndCooperation}/>
        </div>
    );
};

export default PaddleXAi;
