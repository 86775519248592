/**
 * @file 验证码的hooks
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useEffect, useState} from 'react';
import {fetchGetVCode} from '../../api/components/verifyCodeApi';

export const useVerifyCode = () => {
    const [verifyCodeImg, setVerifyCodeImg] = useState<string>('');
    const [randomStr, setRandomStr] = useState<string>('');
    const [verifyCodeLoading, setVerifyCodeLoading] = useState<boolean>(false);

    const getVerifyCode = useCallback(async (): Promise<void> => {
        let res;
        setVerifyCodeLoading(true);
        try {
            res = await fetchGetVCode();
        }
        catch (err: any) {
            // ignore
        }
        setVerifyCodeLoading(false);
        if (res) {
            setVerifyCodeImg(res.body.result.codeImage || '');
            setRandomStr(res.body.result.randomStr || '');
        }
    }, []);

    useEffect(() => {
        getVerifyCode();
    }, [getVerifyCode]);

    return {
        verifyCodeImg,
        randomStr,
        verifyCodeLoading,

        getVerifyCode
    };
};
