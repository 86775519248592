/**
 * @file paddle hub 搜索框表单
 * @author FengGuang(fengguang01@baidu.com)
 */
import { AutoCompleteProps, Form } from "antd";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { fetchGetSearchList } from "../../../../api/pages/paddleHubApi";

const useSearchForm = () => {
    const [form] = Form.useForm();

    const [searchOptions, setSearchOptions] = useState<NonNullable<AutoCompleteProps['options']>>([]);

    const onNameSearch = useCallback(async (value: string) => {
        if (value) {
            const res = await fetchGetSearchList({ name: value },)
                .catch(() => {
                    // ignore
                });
            if (res) {
                const data = res?.body?.data;

                const theList: any[] = Array.isArray(data) ? data : [];

                const theOptionsList = theList.map((item) => ({ value: item, label: item }));
                setSearchOptions(theOptionsList);
            }
        }
        else {
            setSearchOptions([]);
        }
    }, []);

    const onNameSearchDebounce = useMemo(() => {
        return debounce(onNameSearch, 200);
    }, [onNameSearch]);

    return {
        form,
        onNameSearch: onNameSearchDebounce,
        searchOptions
    };
}

export default useSearchForm;
