/**
 * @file paddlehub demo 新冠肺炎医疗影像 上传失败谭弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useRef, useState} from 'react';

interface IPromiseHandler {
    promise: Promise<void>;
    resolve: () => void;
    reject: () => void;
}


const useUserInstructionsModalHooks = () => {
    const [visible, _setVisible] = useState<boolean>(false);
    const onShowPromiseRef = useRef<IPromiseHandler>();

    // 每次visible = ture 的时候生成一个新的promise，
    // 其他地方调用onShow时将promise返回
    // 可以监听用户最后点击了 确认 还是 取消
    const setVisible = useCallback((value: boolean) => {
        if (value) {
            let onResolve = () => {
            };
            let onReject = () => {
            };
            const p = new Promise<void>((resolve, reject) => {
                onResolve = resolve;
                onReject = reject;
            });
            onShowPromiseRef.current = {
                promise: p,
                resolve: onResolve,
                reject: onReject
            };
        }
        _setVisible(value);
    }, [_setVisible]);

    const onShow = useCallback(() => {
        setVisible(true);
        return onShowPromiseRef.current?.promise || Promise.resolve();
    }, [setVisible]);
    const onCancel = useCallback(() => {
        setVisible(false);
        onShowPromiseRef.current?.reject();
    }, [setVisible]);
    const onOk = useCallback(() => {
        setVisible(false);
        onShowPromiseRef.current?.resolve();
    }, [setVisible]);

    return {
        visible,
        setVisible,
        onShow,
        onOk,
        onCancel
    };
};

export default useUserInstructionsModalHooks;
