/**
 * @file paddle hub 首页模型列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { useMemo } from 'react';
import { Pagination } from 'antd';

import PaddleHubModelItem from './PaddleHubModelItem';

import { IPaddleHubModelListProps } from './types';


const PaddleHubModelList: React.FC<IPaddleHubModelListProps> = props => {
    const {
        showModelList,
        pagination,
        onPageChange,
    } = props;

    const showPagination = useMemo(() => {
        return pagination
            && typeof (pagination.total) === 'number'
            && typeof (pagination.pageSize) === 'number'
            && pagination.total > pagination.pageSize;
    }, [pagination]);

    return (
        <div className="paddle-hub-model-list">
            {showModelList.map(item => (
                <PaddleHubModelItem key={item.key} model={item} />
            ))}

            {showPagination
                && (
                    <div className="paddle-hub-model-list-pagination-wrap">
                        <Pagination {...pagination} onChange={onPageChange} showSizeChanger={false} />
                    </div>
                )}
        </div>
    );
};

export default PaddleHubModelList;
