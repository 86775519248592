/**
 * @file paddlehub demo ai写作
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useMemo} from 'react';
import {Tabs} from 'antd';


import {IAiWritingProps} from './types';
import LoverPrattleShowPane from './components/show-pane/LoverPrattleShowPane';
import AcrosticShowPane from './components/show-pane/AcrosticShowPane';
import CoupletShowPane from './components/show-pane/CoupletShowPane';
import PoemsShowPane from './components/show-pane/PoemsShowPane';

const AiWriting: React.FC<IAiWritingProps> = (props) => {
    const loverPrattleExample = useMemo(() => {
        return props.loverPrattleExample || [];
    }, [props.loverPrattleExample]);

    const poemsExample = useMemo(() => {
        return props.poemsExample || [];
    }, [props.poemsExample]);

    const acrosticExample = useMemo(() => {
        return props.acrosticExample || [];
    }, [props.acrosticExample]);

    const coupletExample = useMemo(() => {
        return props.coupletExample || [];
    }, [props.coupletExample]);

    return (
        <div className="ai-writing">
            <Tabs
                tabPosition="left"
            >
                <Tabs.TabPane
                    key="1"
                    tab="AI情话"
                >
                    <LoverPrattleShowPane
                        exampleList={loverPrattleExample}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="2"
                    tab="AI藏头诗"
                >
                    <AcrosticShowPane
                        exampleList={acrosticExample}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="3"
                    tab="AI对联"
                >
                    <CoupletShowPane
                        exampleList={coupletExample}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="4"
                    tab="AI写诗"
                >
                    <PoemsShowPane
                        exampleList={poemsExample}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default AiWriting;
