/**
 * @file paddlehub demo ai写作面板
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLatest} from 'react-use';
import {message} from 'antd';
import debounce from 'lodash/debounce';
import {IShowPaneProps} from './types';
import {fetchGetAiWritingResult} from '../../aiWritingApi';

const useShowPaneHooks = (props: IShowPaneProps) => {
    const model = props.model || '';

    // 热门词的结果缓存
    const initResCache = useMemo(() => {
        return new Map<string, string[]>();
    }, []);
    const resCache = useRef<Map<string, string[]>>(initResCache);
    // 热门词列表
    const [
        exampleList,
        examples
    ] = useMemo(() => {
        const exa = props.exampleList || [];
        const exaL = exa.map(i => i.text);
        // 缓存
        exa.forEach((obj) => {
            if (Array.isArray(obj.result) && obj.result.length > 0) {
                resCache.current.set(obj.text, obj.result);
            }
        });
        return [
            exa,
            exaL
        ];
    }, [props.exampleList]);

    const forbiddenListPromise = useMemo(() => {
        return import( './forbidden-words')
            .then(res => res.default)
            .catch(() => ([]));
    }, []);

    const resultTransition = useMemo(() => {
        return props.resultTransition || ((s: string) => s);
    }, [props.resultTransition]);

    const [loading, setLoading] = useState<boolean>(false);
    const loadingRef = useLatest(loading);

    const [value, setValue] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');
    const inputValueRef = useLatest(inputValue);

    const [resultList, setResultList] = useState<string[]>([]);
    const [showResultIndex, setShowResutIndex] = useState<number>(0);

    const [errorInfo, setErrorInfo] = useState<string>('');

    const onOvertimeError = useMemo(() => {
        return debounce(() => {
            message.warning({
                content: '当前访问人数较多，体验可能会有延迟，请耐心等待;'
            });
        }, 5000);
    }, []);

    const onSubmit = useCallback(
        (value: string) => {
            (async () => {
                const validate = props.validate;
                const validateErrors = validate ? validate(value) : undefined;
                if (validateErrors) {
                    setErrorInfo(validateErrors);
                    return;
                }
                const forbiddenList = await forbiddenListPromise;
                if (forbiddenList.some(str => value.indexOf(str) > -1)) {
                    setErrorInfo('严禁使用敏感词汇！');
                    return;
                }
                setErrorInfo('');
                setLoading(true);
                // 如果有缓存则用缓存
                onOvertimeError();
                const res = resCache.current.has(value)
                    ? {body: resCache.current.get(value)}
                    : (
                        await fetchGetAiWritingResult({texts: value}, model)
                            .catch(() => {
                                // ignore
                            })
                    );
                onOvertimeError.cancel();
                setLoading(false);
                if (res) {
                    const data = res.body;
                    const getData = props.getDataTransition || ((data: any, value: string) => {
                        const theR: string[] = [];
                        if (typeof (data) === 'string') {
                            theR.push(data);
                        }
                        else if (data && typeof (data.text) === 'string') {
                            theR.push(data.text);
                        }
                        else {
                            const r: any[] = Array.isArray(data) ? data : [];
                            r.forEach((subR) => {
                                if (Array.isArray(subR)) {
                                    subR.forEach((subR2) => {
                                        theR.push('' + typeof (subR2) === 'string' ? subR2 : (subR2?.text ?? ''));
                                    });
                                }
                                else {
                                    theR.push('' + typeof (subR) === 'string' ? subR : (subR?.text ?? ''));
                                }
                            });
                        }
                        return theR;
                    });
                    setResultList(getData(data, value));
                }
            })();
        },
        [
            model,
            setErrorInfo,
            props.validate,
            props.getDataTransition,
            forbiddenListPromise,
            onOvertimeError
        ]
    );

    useEffect(() => {
        if (exampleList && exampleList.length > 0) {
            const theValue = exampleList[0];
            setValue(theValue.text);
            setInputValue(theValue.text);
            onSubmit(theValue.text);
        }
    }, [exampleList, onSubmit]);

    const onTabChange = useCallback((value) => {
        if (!loadingRef.current) {
            setValue(value);
            setInputValue(value);
            onSubmit(value);
        }
    }, [loadingRef, onSubmit]);

    const onInputChange = useCallback((value) => {
        setInputValue(value);
    }, [setInputValue]);

    const onBtnClick = useCallback(() => {
        setValue(inputValueRef.current);
        onSubmit(inputValueRef.current);
    }, [onSubmit, inputValueRef]);

    const onSwitchClick = useCallback(() => {
        setShowResutIndex((old) => {
            return (old + 1) % resultList.length;
        });
    }, [resultList]);

    return {
        exampleList,
        examples,
        value,
        inputValue,
        resultList,
        showResultIndex,
        loading,
        errorInfo,

        resultTransition,

        onTabChange,
        onInputChange,
        onBtnClick,
        onSwitchClick
    };
};

export default useShowPaneHooks;
