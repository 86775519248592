/**
 * @file paddlehub demo ai写作面板 tag
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import {Tag} from 'antd';
import classNames from 'classnames';
import {CheckableTagProps} from 'antd/es/tag';

const {CheckableTag} = Tag;

const TagItem: React.FC<CheckableTagProps> = (props) => {
    const {className, children, ...restProps} = props;

    return (
        <CheckableTag
            {...restProps}
            className={classNames(
                'ai-writing-example-tag',
                className
            )}
        >
            {children}
        </CheckableTag>
    );
};

export default TagItem;
