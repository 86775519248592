/**
 * @file 验证码的请求方法
 * @author FengGuang(fengguang01@baidu.com)
 */

// 获取反馈验证码
import request from 'superagent';
import baseRequest from '../baseApi';

export const fetchGetVCode = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/vcode/get')
    );