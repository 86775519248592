/**
 * @file demo 配置
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';
import baseRequest from '../baseApi';
import baseRequest2 from '../baseApi2';

export const bucket = 'test';


export const fetchGetDemoResult = async () =>
    baseRequest2(
        request.post('/paddlehub-api/lexical_analysis/emotion_detectionemoemotion_detectionemotectionemo')
    );

// 获取落地页详情
export interface IGetPaddleParam {
    requestUrl?: string;
}

export const fetchGetPaddleDemoInfo = (params: IGetPaddleParam | string): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/page/data')
            .query(params)
            .query(window.location.search.slice(1))
    );
