/**
 * @file 自定义 markdown 中的 代码块
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';
import copy from 'copy-text-to-clipboard';

import CopyBtn from './CopyBtn';

const hljs = import('highlight.js');


interface ICodeBlockProps {
    className?: string;
    language?: string;
    value?: string;
    onCopyClick?: (value?: string) => void;
}

export function getCodeBlock(generateProps: ICodeBlockProps = {}) {
    const CodeBlock: React.FC<ICodeBlockProps> = cprops => {
        const props = {...generateProps, ...cprops};
        const theRef = useRef<HTMLElement>(null);
        const {value, className, onCopyClick} = props;

        useEffect(() => {
            hljs.then((hl) => {
                if (theRef.current) {
                    hl.highlightBlock(theRef.current);
                }
            });
        }, [value]);

        const onCopyClickHandle = useCallback(() => {
            copy(value || '');
            onCopyClick && onCopyClick(value);
        }, [value, onCopyClick]);

        return (
            <pre>
                <code
                    ref={theRef}
                    className={classNames(
                        props.language ? `language-${props.language}` : undefined,
                        className
                    )}
                >
                    {value}
                </code>
                <CopyBtn onClick={onCopyClickHandle} />
            </pre>
        );
    };

    return CodeBlock;
}

export default getCodeBlock();
