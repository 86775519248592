/**
 * @file 学术生态 详情
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import useAcademicDetail from './AcademicPaperDetailHook';

import { Breadcrumb, Button, Popover } from 'antd';
import classNames from 'classnames';

import { IpaperDetail, IpaperDetailActivityItem } from './../academicEcology/type';
import './style/style.less';
import { useMount } from 'react-use';

const AcademicPaperDetail: React.FC = () => {

    const { acedemicPaperDetail } = useAcademicDetail();
    const [currentDetail, setCurrentDetail] = useState<IpaperDetail>({
        paperActivity: {
            paperActivityTitle: '',
            activityList: []
        },
        paperOpenCode: {
            paperCodeTitle: '',
            paperPath: '',
            isOfficial: false,
            paperCodeDesc: '',
            paperCodeLink: ''
        },
        paperTopInfo: {
            paperTilte: '',
            paperDate: '',
            confName: '',
            author: [],
            paperIntroduce: '',
            PDFLink: '',
            CodeLink: '',
            kewordList: []
        }
    });

    useMemo(() => {
        const theUrlSearchs = (new URLSearchParams(window.location.search.slice(1))).get('id');
        acedemicPaperDetail && theUrlSearchs && setCurrentDetail((acedemicPaperDetail as any)[(theUrlSearchs as string)])
    }, [acedemicPaperDetail]);

    // 定位到页面的顶部位置
    useMount(() => {
        window.scroll(0, 0);
    });

    // 处理查看全部，收起全部的逻辑
    const introduceRef = useRef<HTMLDivElement>(null);
    const showRef = useRef<HTMLDivElement>(null);
    const takeAllRef = useRef<HTMLDivElement>(null);
    const handleCheckAllClick = (operate: string) => {
        if (showRef.current && introduceRef.current && takeAllRef.current) {
            if (operate === 'take') {
                introduceRef.current.style.height = '104px';
                showRef.current.style.display = 'flex';
                takeAllRef.current.style.display = 'none';
            }
            if (operate === 'extend') {
                introduceRef.current.style.height = '100%';
                showRef.current.style.display = 'none';
                takeAllRef.current.style.display = 'inline';
            }
        }
    };
    useEffect(() => {
        if (introduceRef.current && showRef.current && introduceRef.current.offsetHeight) {
            if (introduceRef.current.offsetHeight > 104) {
                introduceRef.current.style.height = '104px';
            }
            else {
                showRef.current.style.display = 'none';
            }
        }
    }, [acedemicPaperDetail]);

    return <React.Fragment>
        <Header />
        <div className="academic-paper-wrapper">


            <Breadcrumb separator=">">
                <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
                <Breadcrumb.Item href="/academicEcology">学术与开源</Breadcrumb.Item>
                <Breadcrumb.Item>论文详情</Breadcrumb.Item>
            </Breadcrumb>


            <div className="academic-paper-top-info">
                <div className="top-info-title">{currentDetail.paperTopInfo?.paperTilte}</div>
                <div className="top-info-meta-info">
                    <div className="meta-conf-name meta-right">{currentDetail.paperTopInfo?.confName}</div>
                    <div className="meta-date">{currentDetail.paperTopInfo?.paperDate}</div>
                    <div className="meta-point">·</div>
                    <div className="meta-author meta-right">{currentDetail.paperTopInfo?.author?.join('，')}</div>
                </div>

                <div className="top-info-introduce" ref={introduceRef}>
                    {currentDetail.paperTopInfo?.paperIntroduce}
                    <span className="take-all" onClick={() => {handleCheckAllClick('take')}} ref={takeAllRef}>&nbsp;&nbsp;收起全部</span>
                    <div className="show-all-introduce" ref={showRef}>
                        <span>...</span>
                        <span className="extend-operate" onClick={() => {handleCheckAllClick('extend')}}>查看全部</span> 
                    </div>
                </div>

                <div className={classNames('top-info-resource-link', {'disabled-icon': !currentDetail.paperTopInfo?.CodeLink})}>
                    <a href={currentDetail.paperTopInfo?.PDFLink}><Button icon={<span className="check-article check" />}>查看PDF</Button></a>
                    {
                        !!currentDetail.paperTopInfo?.CodeLink
                            ? <a href={currentDetail.paperTopInfo?.CodeLink}><Button icon={<span className="check-code check" />}>查看代码</Button></a>
                            : <a href={currentDetail.paperTopInfo?.CodeLink}><Popover placement="top" content="本论文源代码暂未开源"><Button icon={<span className="check-code check" />} disabled>查看代码</Button></Popover></a>
                    }
                </div>
                <div className="top-info-keyword">
                    <div className="keyword-label">关键词：</div>
                    {
                        currentDetail.paperTopInfo?.kewordList?.map((item: string) => <div className="keyword-item">{item}</div>)
                    }
                </div>
            </div>
            {
                !!currentDetail.paperTopInfo?.CodeLink
                ? <div className="academic-paper-open-code">
                    <div className="open-code-title">开源代码</div>
                    <div className="open-code-meta-info">
                        <div className="meta-info-icon" />
                        <div className="meta-info-path">{currentDetail.paperOpenCode?.paperCodeTitle}</div>
                        {
                            currentDetail.paperOpenCode?.isOfficial
                            && <div className="meta-info-official">
                                <span className="official-icon" />
                                <span className="official-word">official</span>
                            </div>
                        }
                    </div>
                    
                    <div className="open-code-content">
                        {
                            currentDetail.paperOpenCode?.paperCodeDesc?.split('(paragraph)').map((item: string) => <p>{item}</p>)
                        }
                    </div>
                    <div className="open-code-link-wrapper">
                        <span className="link-icon" />
                        <a href={currentDetail.paperOpenCode?.paperCodeLink}>{currentDetail.paperOpenCode?.paperCodeLink}</a>
                    </div>
                </div>
                : <div className="academic-paper-open-code">
                    <div className="open-code-title">开源代码</div>
                    <div className="open-code-content">论文代码暂未开源</div>
                </div>
            }


            <div className="academic-paper-activity">
                <div className="activity-title">{currentDetail.paperActivity?.paperActivityTitle}</div>
                <div className="activity-card-wrapper">
                    {
                        currentDetail.paperActivity?.activityList && currentDetail.paperActivity?.activityList.map((item: IpaperDetailActivityItem) => 
                            <div className="activity-card">
                                <a href={item.activityLink}><img className="activity-card-image" src={item.imageUrl} alt="" /></a>
                                <div className="activity-card-content">
                                    <div className="activity-card-title"><a href={item.activityLink}>{item.activityTitle}</a></div>
                                    <div className="activity-card-link"><a href={item.activityLink}>{item.linkWord}&nbsp;&gt;</a></div>
                                </div>
                            </div>
                        )
                    }
                    
                </div>
            </div>
        </div>
        <Footer />
    </React.Fragment>
}

export default AcademicPaperDetail;