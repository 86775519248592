/**
 * @file paddlehub demo
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import { Button, Select } from 'antd';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import Banner from '../../components/banner/Banner';
import A from '../../components/a/A';
import TextCardGroup from './components/text-card/TextCardGroup';
import Demo from './demo/Demo';
import VerticalTabsList from '../../components/hub-components/vertical-tabs-list/VerticalTabsList';
import HorizontalVideoTextCard from '../../components/hub-components/vertical-video-text-card/VerticalVideoTextCard';
import TextCardGroup2 from './components/text-card2/TextCards';

import usePaddleHubDemoHooks from './paddleHubSceneHooks';
import IconTextCardGroup from './components/icon-text-card/IconTextCardGroup';

const PaddleHub: React.FC = () => {
    const { hubDemoData, bannerData } = usePaddleHubDemoHooks();
    // console.log(window.location.pathname);

    return (
        <div className="page paddle-hub-demo-page">
            {window.location.pathname === '/hub/scene/oracle_ocr' ? null : <Header />}
            <Banner title={bannerData.title} subtitle={bannerData.desc} divider>
                <div className="paddle-hub-demo-page-banner-btn-group">
                    {bannerData.btns.map(btn => (
                        <A key={`${btn.label}${btn.href}`} href={btn.href} target>
                            <Button type="primary" size="large" className="paddle-hub-demo-page-banner-btn">
                                {btn.label}
                            </Button>
                        </A>
                    ))}
                </div>
                {(bannerData.selector?.length ?? 0) > 0 && (
                    <Select
                        className="paddle-hub-demo-page-banner-selector"
                        defaultValue={window.location.href}
                        onChange={(href: string) => (window.location.href = href)}
                    >
                        {bannerData.selector.map(s => (
                            <Select.Option key={s.key} value={s.key}>
                                {s.text}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Banner>

            <div className="paddle-hub-demo-page-content">
                {hubDemoData.map((item, index) => {
                    if (item.name === 'textCard') {
                        const options = item.options;
                        return <TextCardGroup key={index} {...options} />;
                    } else if (item.name === 'demoModel') {
                        const options = item.options;
                        return <Demo key={index} {...options} />;
                    } else if (item.name === 'paddleVerticalTabsList') {
                        const options = item.options;
                        return (
                            <div key={index} className="paddle-hub-demo-page-content">
                                {!!options.title && <div className="paddle-hub-demo-page-h2">{options.title}</div>}
                                {!!options.subtitle && (
                                    <div className="paddle-hub-demo-page-h3">{options.subtitle}</div>
                                )}
                                <VerticalTabsList key={index} {...options} />
                            </div>
                        );
                    } else if (item.name === 'paddleHubDemoIconTextCard') {
                        const options = item.options;
                        return (
                            <div key={index} className="paddle-hub-demo-page-content">
                                {!!options.title && <div className="paddle-hub-demo-page-h2">{options.title}</div>}
                                {!!options.subtitle && (
                                    <div className="paddle-hub-demo-page-h3">{options.subtitle}</div>
                                )}
                                <IconTextCardGroup key={index} {...options} />
                            </div>
                        );
                    } else if (item.name === 'paddleVerticalVideoTextCard') {
                        const options = item.options;
                        return (
                            <div key={index} className="paddle-hub-demo-page-content">
                                {!!options.title && <div className="paddle-hub-demo-page-h2">{options.title}</div>}
                                {!!options.subtitle && (
                                    <div className="paddle-hub-demo-page-h3">{options.subtitle}</div>
                                )}
                                <HorizontalVideoTextCard key={index} {...options} />
                            </div>
                        );
                    } else if (item.name === 'paddleHubDemoProjectTextCard') {
                        const options = item.options;
                        return (
                            <div key={index} className="paddle-hub-demo-page-content">
                                {!!options.title && <div className="paddle-hub-demo-page-h2">{options.title}</div>}
                                {!!options.subtitle && (
                                    <div className="paddle-hub-demo-page-h3">{options.subtitle}</div>
                                )}
                                <TextCardGroup2 {...options} />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            {window.location.pathname === '/hub/scene/oracle_ocr' ? null : <Footer />}
        </div>
    );
};

export default PaddleHub;
