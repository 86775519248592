/**
 * @file paddlehub 通用图片 demo
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useCallback } from 'react';
import { Button, Collapse, Upload, UploadProps } from 'antd';
import classNames from 'classnames';
import HeightlightBlock from './HeightlightBlock';
import useMaskDetectHooks from './videoDemoHooks';
import { IVideoDemoProps } from './types';
import Video from '../../../../components/video-modal/Video';
import PCollapse from './PCollapse';


const VideoDemo: React.FC<IVideoDemoProps> = props => {
    const {
        resultCover,
        resultVideo,
        resultJson,
        resultCollapseTable,
        videoGallery,
        videoGallerySelected,
        uploadLoading,

        onVideoGalleryChange,
        getVideoDemoResult
    } = useMaskDetectHooks(props);

    const uploadRequest = useCallback<Exclude<UploadProps['customRequest'], undefined>>(
        (options) => {
            // antd 4.13.1 类型错误
            if ((options.file as File).size < 50 * 1024 * 1024) {
                getVideoDemoResult(options.file as File);
            }
        },
        [getVideoDemoResult]
    );

    return (
        <div className="video-demo clear-both">
            <div className="video-demo-right-background" />
            <div className="video-demo-right">
                <div className="video-demo-json">
                    <PCollapse
                        defaultActiveKey={['2']}
                        expandIconPosition="right"
                        bordered={false}
                        accordion={true}
                        className={classNames(
                            props.resultPanel
                                ? 'video-demo-json-list-num-2'
                                : ''
                        )}
                    >
                        {props.resultPanel
                            && (
                                <Collapse.Panel
                                    key="1"
                                    header="识别"
                                >
                                    <div className="video-demo-json-body">
                                        <table className="video-demo-json-table">
                                            <thead>
                                                <tr>
                                                    <th>文字</th>
                                                    <th>置信度</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resultCollapseTable.map(item => (
                                                    <tr key={`${item.name}${item.score}`}>
                                                        <td>{item.name}</td>
                                                        <td>{item.score}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapse.Panel>
                            )}
                        <Collapse.Panel
                            key="2"
                            header="Response"
                        >
                            <div className="video-demo-json-body">
                                <HeightlightBlock
                                    resultJson={resultJson}
                                />
                            </div>
                        </Collapse.Panel>
                    </PCollapse>
                </div>
            </div>
            <div className="video-demo-main">
                <div
                    className="video-demo-img"
                // style={{backgroundImage: `url(${resultCover})`}}
                >
                    <Video
                        className="video-demo-video-player"
                        src={resultVideo}
                        preload="auto"
                        poster={resultCover}
                        controls
                    />
                    <div className="video-demo-img-input clear-both">
                        <div className="video-demo-img-input-info">
                            文件类型支持MP4，小于5MB。
                        </div>

                        <Upload
                            customRequest={uploadRequest}
                            showUploadList={false}
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="video-demo-img-input-upload"
                                loading={uploadLoading}
                            >
                                试试你的视频?
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="video-demo-img-gallery">
                    <ul className="video-demo-img-gallery-item-wrap">
                        {videoGallery.map(item => (
                            <li
                                key={item.key}
                                className={classNames(
                                    'video-demo-img-gallery-item',
                                    {
                                        'video-demo-img-gallery-item-selected': item.key === videoGallerySelected
                                    }
                                )}
                                style={{ backgroundImage: `url(${item.coverUrl})` }}
                                onClick={() => onVideoGalleryChange(item.key)}
                            >
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default VideoDemo;
