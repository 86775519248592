/**
 * @file paddlehub demo 新冠肺炎医疗影像 图表
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useState} from 'react';
import {IStatisticsGroup} from './types';

const useLungChartHooks = () => {
    const [statistics, _setStatistics] = useState<IStatisticsGroup>({
        lungLeft: {
            huStatistics: [],
            normalStatistics: []
        },
        lungRight: {
            huStatistics: [],
            normalStatistics: []
        }
    });

    const setStatistics = useCallback((value: any = {}) => {
        const huStatistics = value.hu_statistics || [];
        const normalStatistics = value.normal_statistics || [];
        const huStatisticsLungLeft = huStatistics.lung_l || {};
        const huStatisticsLungRight = huStatistics.lung_r || {};
        const normalStatisticsLungLeft = normalStatistics.lung_l || {};
        const normalStatisticsLungRight = normalStatistics.lung_r || {};


        const sortFunc = (obj: { [key: string]: number }) => {
            const keys = Object.keys(obj).map(i => parseInt(i));
            // 限制最小key不小于-1000
            const min = Math.max(Math.min(...keys), -1000);
            // 限制最大key不大于100
            const max = Math.min(Math.max(...keys), 100);
            const resList: [number, number][] = [];
            for (let i = min; i <= max; i++) {
                if (i in obj && i % 50 === 0) {
                    resList.push([i, (obj[i] || 0)]);
                }
            }
            return resList;
        };

        const sortedLungLeftHuStatistics = sortFunc(huStatisticsLungLeft);
        const sortedLungLeftNormalStatistics = sortFunc(normalStatisticsLungLeft);
        const sortedLungRightHuStatistics = sortFunc(huStatisticsLungRight);
        const sortedLungRightNormalStatistics = sortFunc(normalStatisticsLungRight);

        const getMaxValue = (...args: { [key: string]: number }[]) => {
            const maxList: number[] = [];
            args.forEach(obj => {
                maxList.push(Math.max(...Object.values(obj)));
            });
            return Math.max(...maxList);
        };

        _setStatistics({
            maxY: getMaxValue(
                huStatisticsLungLeft,
                normalStatisticsLungLeft,
                huStatisticsLungRight,
                normalStatisticsLungRight
            ),
            lungLeft: {
                huStatistics: sortedLungLeftHuStatistics,
                normalStatistics: sortedLungLeftNormalStatistics
            },
            lungRight: {
                huStatistics: sortedLungRightHuStatistics,
                normalStatistics: sortedLungRightNormalStatistics
            }
        });
    }, [_setStatistics]);

    return {
        statistics,
        setStatistics
    };
};

export default useLungChartHooks;
