/**
 * @file 学术生态 搜索框封装
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
import React, { useState } from 'react';
import './style.less';
import { Input } from 'antd';

interface IAcademicSearch {
    onSearch?: (value: any) => void;
    placeholder?: string;
}

const AcademicSearch: React.FC<IAcademicSearch> = props => {
    const { placeholder, onSearch } = props;

    const [currentValue, setCurrentValue] = useState<unknown>();
    const handleChange = (value: unknown) => setCurrentValue(value);

    return (
        <div className="academic-search-wrapper">
            <Input
                placeholder={placeholder}
                className="academic-search-input"
                suffix={
                    <i
                        className="academic-search-input-icon"
                        onClick={() => {onSearch && onSearch(currentValue)}}
                    />
                }
                onPressEnter={onSearch}
                onChange={handleChange}
            />
        </div>
    );
};

export default AcademicSearch;