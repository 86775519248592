/**
 * @file 应用场景
 * @author v_duzhengqiang
 */
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import useApplicationScenariosStore from './ApplicationScenarioHooks';
import { RootObject, DescContent } from '../../types';
import './style.less';

const ApplicationScenario = (props: RootObject["applicationScenario"]) => {
    const {
        title,
        content
    } = props;

    const {
        selectedTabKey,
        isFirstLoad,
        setSelectedTabKey
    } = useApplicationScenariosStore();

    const showList: any = useMemo(() => {
        const findItem = content.find(item => item.title === selectedTabKey);
        if (findItem) {
            return findItem.contentObject;
        }
        return [];
    }, [content, selectedTabKey]);

    useEffect(() => {
        if (isFirstLoad.current && !selectedTabKey && content.length > 0) {
            isFirstLoad.current = false;
            setSelectedTabKey(content[0].title);
        }
    }, [selectedTabKey, content, isFirstLoad, setSelectedTabKey]);
        
    return (
        <div className='p-x-ai-application-scenario'>
            <div className='p-x-ai-application-scenario-title'>
                {
                    title ?? ''
                }
            </div>
            {/* 内容 */}
            <div>
                {/* tabs内容 */}
                <div className="p-x-ai-application-scenario-modules clear-both">
                    <div className="p-x-ai-application-scenario-modules-menu">
                        {content.map(tab => (
                            <div
                                key={tab.title}
                                className={classNames(
                                    'p-x-ai-application-scenario-modules-menu-item',
                                    { 'p-x-ai-application-scenario-modules-menu-item-selected': tab.title === selectedTabKey }
                                )}
                                onClick={() => setSelectedTabKey(tab.title)}
                                onMouseEnter={() => setSelectedTabKey(tab.title)}
                            >
                                {tab.title}
                            </div>
                        ))}
                    </div>
                    <div className="p-x-ai-application-scenario-modules-menu-background" />
                    <div className="p-x-ai-application-scenario-modules-body">
                        {

                            <div className="p-x-ai-application-scenario-modules-item">
                                <div className="p-x-ai-application-scenario-modules-item-img">
                                    <img alt={showList.title} src={showList.rightImg} />
                                </div>
                                <div className="p-x-ai-application-scenario-modules-item-body">
                                    <div className="p-x-ai-application-scenario-modules-item-title">
                                        {showList.title ?? ''}
                                    </div>
                                    <div className="p-x-ai-application-scenario-modules-item-context">

                                        {
                                            showList?.descContent?.map((item: DescContent,index:number) => {
                                                return (
                                                    <div className='p-x-ai-application-scenario-modules-item-context-descContent' key={index}>
                                                        <div className='p-x-ai-application-scenario-modules-item-context-descContent-title'>{item.title ?? ''}</div>
                                                        <div className='p-x-ai-application-scenario-modules-item-context-descContent-desc'>{item.desc ?? ''}</div>
                                                    </div>
                                                );
                                            })
                                        }


                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationScenario;