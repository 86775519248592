/**
 * @file 手动控制 highlight.js 的引入，控制包大小
 * @author FengGuang(fengguang01@baidu.com)
 */

import highlight from 'highlight.js/lib/core';
import 'highlight.js/styles/github.css';

highlight.registerLanguage('json', require('highlight.js/lib/languages/json'));

export default highlight;
