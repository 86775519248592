/**
 * @file 使用 raf 做的 throttle 函数
 * @author FengGuang(fengguang01@baidu.com)
 */

interface IOptions {
    leading?: boolean;
    trailing?: boolean;
}

const throttleRaf = (callback: (...args: any[]) => void, options: IOptions = {}) => {
    const theOptions = {
        leading: false,
        trailing: true,
        ...options
    };

    let requestId: number | null = null;

    let lastArgs: any[];

    let hasEnd = false;
    const later = (context: any, theArgs: any[] = []) => () => {
        if (!hasEnd) {
            requestId = null;
        }
        callback.apply(context, theArgs);
        if (hasEnd) {
            hasEnd = false;
            // @ts-ignore
            requestId = requestAnimationFrame(later(this, lastArgs));
        }
    };


    function throttled(...args: any[]) {
        lastArgs = args;
        if (requestId === null) {
            // @ts-ignore
            theOptions.leading && callback.apply(this, args);
            // @ts-ignore
            requestId = requestAnimationFrame(later(this, args));
        }
        else {
            if (theOptions.trailing) {
                hasEnd = true;
            }
        }
    }

    throttled.cancel = () => {
        requestId !== null && cancelAnimationFrame(requestId);
        requestId = null;
    };

    return throttled;
};

export default throttleRaf;
