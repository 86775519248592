/**
 * @file 功能演示
 * @author duzhengqiang
 */

import React from 'react';
import { Tabs } from 'antd';
import "./style.less";
import TextToSpeech from "./components/textToSpeech/TextToSpeech";
import { BtnType, Type, ChooseBtnTYpe, FunctionTextValueType, GetRandomChineseWordType } from '../../type';

const { TabPane } = Tabs;
const FunctionalDemo = ({
    btnType,
    functionTextValue,
    chooseBtn,
    getRandomChineseWord,
    textOnchange,
    startSynthesis,
    textLoading,
    textDta,
    hintValue,
    audioData,
    BtnSendUrl,
    audioLoading,
    uploadRequest,
    form,
    TabsOnChange,
    chooseChineBtn,
    btnChinaType
}: {
    btnType: BtnType["BtnType"];
    functionTextValue: FunctionTextValueType["FunctionTextValueType"];
    chooseBtn: ChooseBtnTYpe["ChooseBtnTYpe"];
    getRandomChineseWord: GetRandomChineseWordType["getRandomChineseWordType"];
    textOnchange: Type["textOnchange"];
    startSynthesis: Type["startSynthesis"];
    textLoading: Type["textLoading"];
    textDta: Type["textDta"];
    hintValue: Type["hintValue"];
    audioData: Type["audioData"]
    BtnSendUrl: Type["BtnSendUrl"];
    audioLoading: Type["audioLoading"];
    uploadRequest: Type["uploadRequest"];
    form:Type["form"];
    TabsOnChange:(activeKey:any)=>void;
    chooseChineBtn:Type["chooseChineBtn"];
    btnChinaType:Type["btnChinaType"]
}
) => {
    return (
        <>
            <div className="voice_functionDemo">
                <div className="voice_functionDemo_title">功能演示</div>
                <div className="voice_functionDemo_functionList">
                    <Tabs defaultActiveKey="textToSpeech" centered className="voice_tabs" 
                    // onChange={TabsOnChange}
                    >
                        <TabPane tab="语音合成（TTS）" key="textToSpeech">
                            <TextToSpeech
                                switch={"textToSpeech"}
                                btnType={btnType}
                                btnChinaType={btnChinaType}
                                functionTextValue={functionTextValue}
                                chooseBtn={chooseBtn}
                                getRandomChineseWord={getRandomChineseWord}
                                textOnchange={textOnchange}
                                startSynthesis={startSynthesis}
                                textLoading={textLoading}
                                textDta={textDta}
                                hintValue={hintValue}
                                audioData={audioData}
                                BtnSendUrl={BtnSendUrl}
                                audioLoading={audioLoading}
                                uploadRequest={uploadRequest}
                                form={form}
                                chooseChineBtn={chooseChineBtn}
                            />
                        </TabPane>
                        <TabPane tab="语音识别（ASR)" key="speechRecognition">
                            <TextToSpeech
                                switch={"speechRecognition"}
                                btnType={btnType}
                                functionTextValue={functionTextValue}
                                chooseBtn={chooseBtn}
                                getRandomChineseWord={getRandomChineseWord}
                                textOnchange={textOnchange}
                                startSynthesis={startSynthesis}
                                textLoading={textLoading}
                                textDta={textDta}
                                hintValue={hintValue}
                                audioData={audioData}
                                BtnSendUrl={BtnSendUrl}
                                audioLoading={audioLoading}
                                uploadRequest={uploadRequest}
                                form={form}
                                chooseChineBtn={chooseChineBtn}
                                btnChinaType={btnChinaType}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <div className="voice_hint">{hintValue}</div>
        </>
    )
}

export default FunctionalDemo
