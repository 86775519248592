/**
 * @file 顶部导航栏结合 hooks
 * 方便不需要操纵 header 数据的组件使用
 * @author FengGuang(fengguang01@baidu.com)
 */
import React from 'react';
import Header from './Header';
import {IHeaderWithStoreProps} from './type';
import useHeaderHooks from './headerHooks';

const HeaderWithHooks: React.FC<IHeaderWithStoreProps> = props => {
    const headerHooks = useHeaderHooks();

    return (
        <Header {...props} {...headerHooks} />
    );
};

export default HeaderWithHooks;
