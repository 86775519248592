 // @ts-nocheck

/**
 * @file paddle hub 首页模型列表
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC, useMemo } from 'react';
import { Form, Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import { debounce } from 'lodash';
import classNames from 'classnames';

import MultiCascader, { SHOW_CHILD } from './components/MultiCascader/MultiCascader';


import { IPaddleHubMenuProps } from './types';


const PaddleHubMenu: FC<IPaddleHubMenuProps> = (props) => {
    const {
        className,
        form,
        clearAll,
        menuList,
        onFinish,
    } = props;


    const handleOnChange = useMemo(() => {
        return onFinish
            ? debounce(onFinish, 500)
            : undefined;
    }, [onFinish]);

    return (
        <div
            className={classNames(
                'paddle-hub-menu-wrap',
                className
            )}
        >
            <div className="paddle-hub-menu-title">
                筛选条件
                <div className="paddle-hub-menu-clear-all" onClick={clearAll}>清除所有条件</div>
            </div>
            <Form
                form={form}
                onFinish={onFinish}
                onValuesChange={handleOnChange}
            >
                {menuList.map((group, index) => (() => {
                    if (group.type === 'switch') {
                        return (
                            <Form.Item
                                className="paddle-hub-menu-item"
                                key={group.value}
                            >
                                <div
                                    className="paddle-hub-menu-item-wrap"
                                >
                                    <div className="paddle-hub-menu-item-title">
                                        <div>{group.title}</div>
                                        <div>
                                            <Form.Item
                                                name={group.value}
                                                key={group.value}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                <Switch
                                                    className="paddle-hub-menu-switch"
                                                    checkedChildren="开"
                                                    unCheckedChildren="关"
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>
                        );
                    }

                    return (
                        <Form.Item
                            className="paddle-hub-menu-item"
                            name={group.value}
                            key={group.value}
                        >
                            <MultiCascader
                                showSearch={false}
                                className="paddle-hub-menu-select"
                                bordered={false}
                                options={group.list}
                                expandTrigger="hover"
                                placeholder="点击选择"
                                showCheckedStrategy={SHOW_CHILD}
                                optionLabelProp="title"
                                popupClassName="paddle-hub-menu-select-popup"
                            >
                                {(node) => (
                                    <div className="paddle-hub-menu-item-wrap">
                                        <div className="paddle-hub-menu-item-title">
                                            <div>{group.title}</div>
                                            <div className="paddle-hub-menu-item-title-icon">
                                                <DownOutlined />
                                            </div>
                                        </div>
                                        {node}
                                    </div>
                                )}
                            </MultiCascader>
                        </Form.Item>
                    )
                })())}
            </Form>
        </div >
    );
}

export default PaddleHubMenu;
