/**
 * @file paddlehub demo 新冠肺炎医疗影像 图表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useEffect, useRef} from 'react';
import echarts, {ECharts, EChartOption} from 'echarts';

import {ILungChartProps} from './types';

const LungChart: React.FC<ILungChartProps> = props => {
    const statistics = props.statistics;
    const divRef = useRef<HTMLDivElement>(null);
    const echartObjRef = useRef<ECharts>();

    useEffect(() => {
        if (divRef.current) {
            echartObjRef.current = echarts.init(divRef.current, 'dark');
        }
        return () => {
            if (echartObjRef.current) {
                echartObjRef.current.clear();
            }
        };
    }, []);

    useEffect(() => {
        if (echartObjRef.current) {
            const option: EChartOption = {
                backgroundColor: 'transparent',
                grid: [
                    {
                        show: true,
                        borderColor: 'rgba(255, 255, 255, .3)',
                        bottom: '20px',
                        left: '20px',
                        top: '30px',
                        width: '335px',
                        height: '152px',
                        backgroundColor: '#0e1a39'
                    },
                    {
                        show: true,
                        borderColor: 'rgba(255, 255, 255, .3)',
                        bottom: '20px',
                        right: '20px',
                        top: '30px',
                        width: '335px',
                        height: '152px',
                        backgroundColor: '#0e1a39'
                    }
                ],
                legend: {
                    borderRadius: 0,
                    right: '2%',
                    top: 0
                },
                xAxis: [
                    {
                        name: '',
                        type: 'value',
                        min: -1000,
                        max: 100,
                        gridIndex: 0,
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: '#4C596f'
                            }
                        },
                        axisLabel: {
                            color: '#8a92a3'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        name: '',
                        gridIndex: 1,
                        type: 'value',
                        min: -1000,
                        max: 100,
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: '#4C596f'
                            }
                        },
                        axisLabel: {
                            color: '#8a92a3'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                yAxis: [
                    {
                        name: '  左肺',
                        nameTextStyle: {
                            color: 'rgba(255, 255, 255, .80)',
                            fontSize: 12,
                            align: 'left'
                        },
                        nameGap: -19,
                        gridIndex: 0,
                        type: 'value',
                        min: 0,
                        max: statistics.maxY,
                        splitLine: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            onZero: false
                        },
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        name: '  右肺',
                        nameTextStyle: {
                            color: 'rgba(255, 255, 255, .80)',
                            fontSize: 12,
                            align: 'left'
                        },
                        nameGap: -19,
                        gridIndex: 1,
                        type: 'value',
                        min: 0,
                        max: statistics.maxY,
                        splitLine: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            onZero: false
                        },
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '对照组',
                        data: statistics.lungLeft.normalStatistics,
                        type: 'line',
                        tooltip: {},
                        itemStyle: {
                            color: '#ffaa00'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#fff',
                                borderWidth: 2,
                                borderColor: '#ffaa00'
                            }
                        },
                        smooth: true
                    },
                    {
                        name: '当前',
                        type: 'bar',
                        data: statistics.lungLeft.huStatistics,
                        tooltip: {},
                        itemStyle: {
                            // 这个类型标记错误
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#00A3ff'},
                                    {offset: 1, color: 'rgba(0,163,255,.70)'}
                                ]
                            ) as any
                        },
                        emphasis: {
                            itemStyle: {
                                // 这个类型标记错误
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#7acfff'},
                                        {offset: 1, color: 'rgba(0,163,255,.70)'}
                                    ]
                                ) as any
                            }
                        }
                    },
                    {
                        name: '对照组',
                        data: statistics.lungRight.normalStatistics,
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        tooltip: {},
                        itemStyle: {
                            color: '#ffaa00'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#fff',
                                borderWidth: 2,
                                borderColor: '#ffaa00'
                            }
                        },
                        smooth: true
                    },
                    {
                        name: '当前',
                        type: 'bar',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        data: statistics.lungRight.huStatistics,
                        tooltip: {},
                        itemStyle: {
                            // 这个类型标记错误
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#00A3ff'},
                                    {offset: 1, color: 'rgba(0,163,255,.70)'}
                                ]
                            ) as any
                        },
                        emphasis: {
                            itemStyle: {
                                // 这个类型标记错误
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#7acfff'},
                                        {offset: 1, color: 'rgba(0,163,255,.70)'}
                                    ]
                                ) as any
                            }
                        }
                    }
                ]
            };

            echartObjRef.current.setOption(option);
        }
    }, [statistics]);

    return (
        <div className="lung-chart" ref={divRef}>

        </div>
    );
};

export default LungChart;
