/**
 * @file paddlehub 老照片修复图片 demoHooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {SetStateAction, useCallback, useMemo, useState} from 'react';
import {useLatest, useUpdateEffect} from 'react-use';
import {message} from 'antd';

import {
    IImgGalleryItem,
    IPaddleGanDemoProps
} from './types';
import pictureCompress, {getImgBase64} from '../../../../utils/picture-compressor';
import {fetchGetPictureResult} from './paddleGanDemoApi';
import getImageSize from '../../../../utils/get-image-size';

const usePaddleGanDemoHooks = (props?: IPaddleGanDemoProps) => {
    const [uploadImgErrStr, setUploadImgErrStr] = useState<string>('');
    const [modelSelect, setModelSelect] = useState<string[]>(['Colorization']);
    const modelSelectStr = useMemo(() => {
        return modelSelect.join(',');
    }, [modelSelect]);
    const propsImgGallery = props?.image?.imgGallery;
    const [imgGallerySelected, setImgGallerySelected] = useState<string>(() => {
        return (propsImgGallery ?? [])[0]?.img ?? '';
    });
    const imgGallerySelectedRef = useLatest(imgGallerySelected);

    const [imgGallery, setImgGallery] = useState<IImgGalleryItem[]>(() => {
        // 多加一个 key==='' 的对象保存用户上传图片数据
        return (propsImgGallery ?? []).map<IImgGalleryItem>((item) => ({
            key: item.img,
            img: item.img,
            imgRes: {
                '': '',
                Colorization: item.imgRes?.Colorization ?? '',
                'Colorization,SuperResolution': item.imgRes?.['Colorization,SuperResolution'] ?? '',
                SuperResolution: item.imgRes?.SuperResolution ?? ''
            },
            resultJson: item.resultJson,
            loading: false
        })).concat([{
            key: '',
            img: ''
        }]);
    });
    const setImgGalleryItem = useCallback((key: string, newItem: SetStateAction<IImgGalleryItem>) => {
        setImgGallery((old) => {
            const targetIndex = old.findIndex(i => i.key === key);
            const target = old[targetIndex];
            if (target) {
                return old.map(item => {
                    if (item.key === key) {
                        if (typeof (newItem) === 'function') {
                            return newItem(item);
                        }
                        else {
                            return newItem;
                        }
                    }
                    return item;
                });
            }
            return old;
        });
    }, [setImgGallery]);
    const setImgGalleryLoading = useCallback((key: string, loading: boolean) => {
        setImgGallery((old) => {
            const targetIndex = old.findIndex(i => i.key === key);
            const target = old[targetIndex];
            if (target && target.loading !== loading) {
                return old.map(item => {
                    if (item.key === key) {
                        return {...item, loading};
                    }
                    return item;
                });
            }
            return old;
        });
    }, [setImgGallery]);
    useUpdateEffect(() => {
        // 多加一个 key==='' 的对象保存用户上传图片数据
        const newList = (propsImgGallery ?? []).map<IImgGalleryItem>((item) => ({
            key: item.img,
            img: item.img,
            imgRes: item.imgRes,
            resultJson: item.resultJson,
            loading: false
        })).concat([{
            key: '',
            img: ''
        }]);
        setImgGallerySelected((old) => {
            return old ? old : newList[0]?.key;
        });
        setImgGallery(newList);
    }, [propsImgGallery]);

    // img 可能是文件、url、base64
    const getPictureDemoResult = useCallback(async (imgSelected: string, img: File | string, modelSelect: string[]) => {
        const modelSelectStr = modelSelect.join(',');
        let fileString: string;
        if (typeof (img) === 'string') {
            fileString = img;
        }
        else if (img.size > 2 * 1024 * 1024) {
            const minImgRes = await pictureCompress({
                img,
                height: 800,
                width: 800
            });
            fileString = minImgRes.img;
        }
        else {
            fileString = await getImgBase64(img);
        }
        if (!fileString) {
            return;
        }
        const imgBase64type = (fileString.match(/data:[^;]*;base64,/))?.[0] ?? '';
        const isBase64 = !!fileString.match(/data:[^;]*;base64,/);
        const uploadBase64 = fileString.substr(imgBase64type.length);
        setImgGalleryLoading(imgSelected, true);
        setImgGalleryItem(imgSelected, (old) => {
            return {
                ...old,
                img: old.img || fileString
            };
        });

        const res = await fetchGetPictureResult({
            image: isBase64 ? uploadBase64 : undefined,
            image_url: isBase64 ? undefined : fileString,
            model_select: modelSelect
        }).catch(() => {
            // ignore
        });
        setImgGalleryLoading(imgSelected, false);

        if (!res || parseInt(res?.body?.code) !== 0) {
            message.error('当前人数较多，请稍候再试');
        }
        else if (res) {
            const resImg = res.body.result || '';
            const resImgUrl = resImg
                ? `${imgBase64type || 'data:image/jpeg;base64,'}${resImg}`
                : '';
            let resString: string;
            try {
                resString = JSON.stringify(
                    res.body,
                    (key, value) => {
                        if (key === 'result') {
                            const v = '' + value;
                            return v.length > 50 ? `${v.substr(0, 50)}...` : v;
                        }
                        return value;
                    },
                    2
                );
            }
            catch (err: any) {
                // ignore
            }
            setImgGalleryItem(imgSelected, (old) => {
                const oldImdRes = old.imgRes?.[modelSelectStr];
                return {
                    ...old,
                    imgRes: {
                        ...old.imgRes,
                        [modelSelectStr]: oldImdRes || resImgUrl || ''
                    },
                    resultJson: resString
                };
            });
        }
    }, [setImgGalleryItem, setImgGalleryLoading]);

    const onImgGalleryChange = useCallback(async (key: string) => {
        setImgGallerySelected(key);
        setUploadImgErrStr('');
        const oldKey = imgGallerySelectedRef.current;
        const targetItem = imgGallery.find(i => i.key === key);

        setImgGalleryLoading(oldKey, false);

        if (targetItem?.imgRes?.[modelSelectStr]) {
            setImgGalleryLoading(key, true);
            setTimeout(() => {
                if (imgGallerySelectedRef.current === key) {
                    setImgGalleryLoading(key, false);
                }
            }, 2000);
        }
        else if (targetItem?.img) {
            getPictureDemoResult(key, targetItem?.img, modelSelect);
        }
    }, [
        imgGallery,
        setImgGallerySelected,
        imgGallerySelectedRef,
        setImgGalleryLoading,
        getPictureDemoResult,
        setUploadImgErrStr,
        modelSelect,
        modelSelectStr
    ]);

    const onImgUpload = useCallback(async (file: File) => {
        setImgGallerySelected('');
        setImgGalleryItem('', (old) => {
            return {
                ...old,
                img: '',
                imgRes: undefined
            };
        });
        if (modelSelectStr) {
            const {width, height} = await getImageSize(file);
            if (file.size > 2 * 1024 * 1024) {
                setUploadImgErrStr('上传图片大小大于2M，请更换图片后重新体验');
            }
            else if (width > 800 || height > 800) {
                setUploadImgErrStr('上传图片像素高于800*800，请更换图片后重新体验');
            }
            else {
                setUploadImgErrStr('');
                getPictureDemoResult('', file, modelSelect);
            }
        }
    }, [getPictureDemoResult, setUploadImgErrStr, setImgGalleryItem, modelSelect, modelSelectStr]);

    const onModelSelectChange = useCallback((value: (string | number | boolean)[]) => {
        const theModel = value.map(i => '' + i).sort();
        const theModelStr = theModel.join(',');
        setModelSelect(theModel);
        const imgSelected = imgGallerySelectedRef.current;
        const oldKey = imgGallerySelectedRef.current;
        const targetItem = imgGallery.find(i => i.key === imgSelected);
        setImgGalleryLoading(oldKey, false);
        if (targetItem?.imgRes?.[theModelStr] && targetItem?.resultJson) {
            setImgGalleryLoading(imgSelected, true);
            setTimeout(() => {
                if (imgGallerySelectedRef.current === imgSelected) {
                    setImgGalleryLoading(imgSelected, false);
                }
            }, 2000);
        }
        else if (theModelStr && targetItem?.img) {
            getPictureDemoResult(imgSelected, targetItem?.img, theModel);
        }
    }, [
        imgGallerySelectedRef,
        imgGallery,
        setImgGalleryLoading,
        getPictureDemoResult
    ]);

    const imgSelectedItem = useMemo(() => {
        return imgGallery.find(i => i.key === imgGallerySelected);
    }, [imgGallery, imgGallerySelected]);

    const imgGalleryWithoutEmpty = useMemo(() => {
        return imgGallery.filter(i => !!i.key);
    }, [imgGallery]);

    // 显示
    const [draggableTipShow, setDraggableTipShow] = useState<boolean>(true);
    const hideDraggableTipShow = useCallback(() => {
        setDraggableTipShow(false);
    }, [setDraggableTipShow]);

    return {
        imgGallery: imgGalleryWithoutEmpty,
        imgGallerySelected,
        imgSelectedItem,
        modelSelect,
        modelSelectStr,
        uploadImgErrStr,

        draggableTipShow,
        hideDraggableTipShow,

        onImgGalleryChange,
        onModelSelectChange,
        onImgUpload
    };
};

export default usePaddleGanDemoHooks;
