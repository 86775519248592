/**
 * @file paddlehub 通用图片 demoHooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useMemo, useState} from 'react';
import {useLatest} from 'react-use';
import {fetchGetVideoResult} from './videoDemoApi';
import {IVideoDemoProps, IResultItem, IVideoGalleryItem} from './types';
import {uploadToBos} from '../pneumonia/medicalImageApi';

const useVideoDemoHooks = (props?: IVideoDemoProps) => {
    const [resultCover, setResultCover] = useState<string>();
    const [resultVideo, setResultVideo] = useState<string>('');
    const [resultJson, setResultJson] = useState<string>('');
    const [resultCollapseTable, setResultCollapseTable] = useState<IResultItem[]>([]);
    const [uploadLoading, setUploadLoading] = useState<boolean>();
    const [videoGallerySelected, setVideoGallerySelected] = useState<string>('');


    // 可用模型列表
    const propsModelList = props?.modelList;
    const resultPanel = !!props?.resultPanel;
    const modelList = useMemo(() => {
        return propsModelList || [];
    }, [propsModelList]);

    // video 可能是文件、url
    const getVideoDemoResult = useCallback((video: File | string) => {
        (async () => {
            let videoKey;
            let videoUrl;
            if (typeof (video) === 'string') {
                videoUrl = video;
            }
            else {
                setVideoGallerySelected('');
                setUploadLoading(true);
                setResultCover(undefined);
                setResultVideo(window.URL.createObjectURL(video));
                const client = await uploadToBos({
                    file: video
                });
                if (!client) {
                    throw new Error('create client Error');
                }
                const res = await client.start();
                videoKey = res?.body?.key;
            }
            if (!videoUrl && !videoKey) {
                return;
            }
            setResultJson('');
            const firstModel = modelList[0]?.key || '';
            let res;
            try {
                res = await fetchGetVideoResult({
                    object_key: videoKey,
                    url: videoUrl && encodeURI(videoUrl)
                }, firstModel);
            }
            catch (err: any) {
                // ignore
            }
            setUploadLoading(false);
            if (res) {
                let resString;
                try {
                    resString = (JSON.stringify(res.body, null, 2));
                }
                catch (err: any) {
                    // ignore
                }
                setResultJson(resString || '');

                if (resultPanel) {
                    const theResult: any[] = Array.isArray(res.body?.result) ? res.body?.result : [];
                    const theResultArr: IResultItem[] = [];
                    theResult.forEach(item => {
                        const i: any[] = item.prediction ? Object.entries(item.prediction) : [];
                        i.forEach(([key, value]) => {
                            theResultArr.push({
                                score: (() => {
                                    if (typeof (value) === 'number') {
                                        return parseFloat(value.toFixed(3));
                                    }
                                    if (typeof (value) === 'string') {
                                        return parseFloat(value);
                                    }
                                    return 0;
                                })(),
                                name: key ?? ''
                            });
                        });
                    });
                    setResultCollapseTable(theResultArr);
                }
            }
        })();
    }, [modelList, resultPanel]);

    const propsVideoGallery = props?.videoGallery;
    const videoGallery = useMemo<IVideoGalleryItem[]>(() => {
        const list = (propsVideoGallery || []).map(item => ({
            ...item,
            key: item.key !== undefined ? item.key : `${item.coverUrl}${item.videoUrl}`
        }));
        const firstVideo = list[0];
        setVideoGallerySelected(old => {
            if (!old && firstVideo) {
                setResultCover(firstVideo.coverUrl);
                setResultVideo(firstVideo.videoUrl);
                getVideoDemoResult(firstVideo.videoUrl);
            }
            return !!old ? old : list[0]?.key;
        });
        return list;
    }, [setVideoGallerySelected, propsVideoGallery, getVideoDemoResult]);

    const videoGalleryRes = useLatest(videoGallery);
    const onVideoGalleryChange = useCallback((key: string) => {
        setVideoGallerySelected(old => {
            if (old !== key) {
                const selectedVideo = videoGalleryRes.current.find(v => v.key === key);
                if (selectedVideo) {
                    setResultCover(selectedVideo.coverUrl);
                    setResultVideo(selectedVideo.videoUrl);
                    getVideoDemoResult(selectedVideo.videoUrl);
                }
            }
            return key;
        });
    }, [setVideoGallerySelected, getVideoDemoResult, videoGalleryRes]);

    return {
        resultCover,
        resultVideo,
        resultJson,
        resultCollapseTable,
        videoGallery,
        videoGallerySelected,
        modelList,
        uploadLoading,

        onVideoGalleryChange,
        getVideoDemoResult
    };
};

export default useVideoDemoHooks;
